import {Injectable} from '@angular/core';
import {AuthenticationService} from './api-implementations/authentication.service';
import {serviceFactory} from './config';
import {ClientsService} from './api-implementations/clients.service';
import {TicketsService} from './api-implementations/tickets.service';
import {AgentsService} from './api-implementations/agents.service';
import {ResellerService} from './api-implementations/reseller.service';
import {DocumentsService} from './api-implementations/documents.service';
import {AclApiService} from './api-implementations/acl-api.service';
import {ToolsApiService} from './api-implementations/tools-api.service';
import {CachedUsersApiService} from './api-implementations/users-api.service';
import {CachedUserSettingsApiService} from './api-implementations/user-settings-api.service';
import {TestApiService} from './api-implementations/test-api.service';
import {CachedGroupsApiService} from './api-implementations/usergroups-api.service';
import {CachedPreferencesApiService} from './api-implementations/preferences-api.service';
import {ClientFlagsService} from './api-implementations/client-flags.service';
import {ClientReminderService} from './api-implementations/client-reminder-service';
import {NotificationsService} from './api-implementations/notifications.service';
import {RemoteDevicesService} from './api-implementations/remote-devices.service';
import {InvoicesApiService} from './api-implementations/invoices-api.service';
import {DynamicIpApiService} from './api-implementations/dynamic-ip-api.service';
import {TicketFlagTemplatesService} from './api-implementations/ticket-flag-templates.service';
import {ContractsService} from './api-implementations/contracts/contracts.service';
import {MessageTemplatesService} from './api-implementations/message-templates.service';
import {NetworkService} from './api-implementations/network.service';
import {QueueJobsApiService} from './api-implementations/queue-jobs-api-service';
import {DevicesMapsApiService} from './api-implementations/devices-maps-api.service';
import {FastDialNumbersApiService} from './api-implementations/fast-dial-numbers-api.service';
import {EasyCastApiService} from './api-implementations/easy-cast/easy-cast-api.service';

@Injectable({
    providedIn: 'root'
})
export class RestApiService {
    acl: AclApiService = serviceFactory(AclApiService);
    agents: AgentsService = serviceFactory(AgentsService);
    authentication: AuthenticationService = serviceFactory(AuthenticationService);
    clientFlags: ClientFlagsService = serviceFactory(ClientFlagsService);
    clientReminders: ClientReminderService = serviceFactory(ClientReminderService);
    // clientTicketMessages: ClientMessagesApiService = serviceFactory(ClientMessagesApiService);
    clients: ClientsService = serviceFactory(ClientsService);
    contracts: ContractsService = serviceFactory(ContractsService);
    documents: DocumentsService = serviceFactory(DocumentsService);
    invoices: InvoicesApiService = serviceFactory(InvoicesApiService);
    messageTemplates = serviceFactory(MessageTemplatesService);
    network = serviceFactory(NetworkService);
    notifications = serviceFactory(NotificationsService);
    preferencesCached: CachedPreferencesApiService = new CachedPreferencesApiService();
    remoteDevices = serviceFactory(RemoteDevicesService);
    resellers: ResellerService = serviceFactory(ResellerService);
    userGroupsCached: CachedGroupsApiService = new CachedGroupsApiService();
    usersCached: CachedUsersApiService = new CachedUsersApiService();
    userSettingsCached: CachedUserSettingsApiService = new CachedUserSettingsApiService();
    test: TestApiService = serviceFactory(TestApiService);
    tickets: TicketsService = serviceFactory(TicketsService);
    tools: ToolsApiService = serviceFactory(ToolsApiService);
    dynamicIps: DynamicIpApiService = serviceFactory(DynamicIpApiService);
    ticketFlagTemplates = serviceFactory(TicketFlagTemplatesService);
    queueJobs: QueueJobsApiService = serviceFactory(QueueJobsApiService);
    devicesMap: DevicesMapsApiService = serviceFactory(DevicesMapsApiService);
    fastDialNumbers = serviceFactory(FastDialNumbersApiService);
    easyCast: EasyCastApiService = new EasyCastApiService();
}
