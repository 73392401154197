import {BaseService, Body, GET, Headers, Path, POST, Response} from 'ts-retrofit';
import {ACCEPT_JSON} from '../../config';
import {ToData, ToListedResponse} from '../../rest-api-hydratator-decorators';
import {ListedResponse} from '../../../../models/hydratator/listed-response';
import {EasyCastLicense} from '../../../../models/easy-cast/easy-cast-license';
import {FilterEasyCastLicenseRequest} from '../../../../models/requests/easy-cast/filter-easy-cast-license.request';

const base = 'easycast/licenses';

export class EasyCastLicensesApiService extends BaseService {
    @POST(`/${base}/search`)
    @Headers({...ACCEPT_JSON})
    @ToListedResponse(EasyCastLicense)
    async filter(@Body request: FilterEasyCastLicenseRequest): Promise<Response<ListedResponse<EasyCastLicense>>> {
        return <Response>{};
    }

    @GET(`/${base}/{id}`)
    @Headers({...ACCEPT_JSON})
    @ToData(EasyCastLicense)
    async get(@Path('id') id: number): Promise<Response<EasyCastLicense>> {
        return <Response>{};
    }

    @POST(`/${base}`)
    @Headers({...ACCEPT_JSON})
    @ToData(EasyCastLicense)
    async save(@Body license: EasyCastLicense): Promise<Response<EasyCastLicense>> {
        return <Response>{};
    }
}
