import {serviceFactory} from '../../config';
import {EasyCastUsersApiService} from './easy-cast-users-api.service';
import {EasyCastLicensesApiService} from './easy-cast-licenses-api.service';
import {EasyCastContractsApiService} from './easy-cast-contracts-api.service';
import {EasyCastLocationsApiService} from './easy-cast-locations-api.service';
import {EasyCastPlaybackDevicesApiService} from './easy-cast-playback-devices.api.service';

export class EasyCastApiService {
    users: EasyCastUsersApiService = serviceFactory(EasyCastUsersApiService);
    licenses: EasyCastLicensesApiService = serviceFactory(EasyCastLicensesApiService);
    contracts = serviceFactory(EasyCastContractsApiService);
    locations = serviceFactory(EasyCastLocationsApiService);
    playbackDevices = serviceFactory(EasyCastPlaybackDevicesApiService);
}
