<p-panel header="Scegli il tema">
    <div class="grid">
        <div class="col-3" *ngFor="let theme of themes">
            <button pButton (click)="changeTheme(theme)" [style.background]="theme.representativeColor"
                    [style.border]="theme.representativeColor" pTooltip="{{getTooltip(theme)}}"
                    tooltipPosition="left" [appendTo]="container">
                <i *ngIf="theme.darkTheme" class="pi pi-moon"></i>
                <i *ngIf="!theme.darkTheme" class="pi pi-sun"></i>
            </button>
        </div>
    </div>
    <div #container style="display:inline-block; position: absolute"></div>
</p-panel>
