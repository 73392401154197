import {PartialRouting} from '../partial-routing';
import {RoutingConstants} from '../routing-constants';
const {params} = RoutingConstants;

export class RoutingRemoteDevices extends PartialRouting {
    constructor(base: string = '') {
        super(base);
    }

    list() {
        return this.getBasePath();
    }

    types() {
        return `${this.base}deviceTypes`;
    }
    scheduledCommands(id?: number) {
        let addedParams = '';
        if (id) {
            addedParams = `?${params.remoteDevices.deviceId}=${id}`;
        }
        return `${this.base}scheduledCommands${addedParams}`;
    }
    byDeviceId(id: string | number) {
        return `${this.base}${id}`;
    }

}
