import {Type} from 'class-transformer';

export class ContractCoverage {
    idGis: number;
    login: string;

    @Type(() => Date)
    lastUpdate: Date;

    valid: number;
    lte: number;
    fwa: number;
    adsl: number;
    xdsl: number;
    fttc: number;
    ftth: number;
    fttcdegraded: number;
    status: LoginCoverageStatus;
    /**
     * Json Base64 encoded
     */
    providerMywicCoverageJson: string;
}

export enum LoginCoverageStatus {
    Updated = 0,
    NoChanges = 1,
    Degraded = 2
}
