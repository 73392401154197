<div>
    <div class="field flex align-items-baseline">
        <label style="width: 200px">Colore</label>
        <input pInputText type="text" [(ngModel)]="newTemplate.color" [maxLength]="6"
               [style.color]="'#'+newTemplate.color"
               style="width:75px">
        <input pInputText type="color" [ngModel]="parseColor(newTemplate.color)" required
               (ngModelChange)="formatColor(newTemplate, $event)" class="align-self-center color-selector">
        <p class="ml-2">Fai click sul riquadro colorato per scegliere il colore</p>
    </div>
    <div class="field">
        <label style="width: 200px">Simbolo</label>
        <app-dropdown
            class="inline-block"
            [(model)]="newTemplate.simbolo"
            [dropdownItems]="symbolsList"
            optionLabel="label"
            optionValue="value"
            [showFilter]="false"
            [editable]="true"
            [maxlength]="5"
            inputWidth="100%"
            [style.color]="'#'+newTemplate.color"
        ></app-dropdown>
    </div>
    <div class="field flex align-items-baseline">
        <label style="width: 200px">Descrizione</label>
        <input pInputText type="text" [(ngModel)]="newTemplate.descr" required
               [maxlength]="100" class="flex-1">
    </div>
    <app-action-button type="cancel" label="Annulla" (click)="close()"
                       class="mr-2 ml-2"></app-action-button>
    <app-action-button type="save" label="Salva" [loading]="loading" (click)="createFlagTemplate()"
                       class="mr-2 ml-2"></app-action-button>
</div>
