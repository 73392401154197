import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {SessionStorageService} from '../services/session-storage.service';
import {Role} from '../models/user-data';
import {RouteData} from './route-data';
import {AclPermission} from '../models/acl/acl-group';
import {Routing} from '../models/routing/routing';

export interface PolicyData {
    roles?: Role[];
    acl?: AclPermission[];
    route?: ActivatedRouteSnapshot;
    state?: RouterStateSnapshot;
    fn?: (data: PolicyData) => boolean;
}

export abstract class GuardPolicy implements CanActivate {

    protected constructor(protected storage: SessionStorageService, protected router: Router) {
    }

    /**
     *
     * @param data
     * @return se restituisce una stringa, tale stringa viene utilizzata come parametro di redirect
     * ed il controllo di validita` e` considerato fallito
     */
    abstract checkPolicy(data: PolicyData): boolean | string;

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const data = route.data as RouteData;
        const result: boolean | string = this.checkPolicy({route, state, acl: data.acl});
        if (result === true) {
            return true;
        } else {
            // se il risultato e` banalmente FALSE allora il redirect e` /login altrimenti e` la stringa restituita
            let redirectUrl = '/login';
            if (typeof result === 'string') {
                redirectUrl = result;
            }
            this.router.navigate([redirectUrl], {queryParams: {previous: state.url}});
            return false;
        }
    }
}

/**
 * L'attuale utente deve avere il ruolo specificato per accedere alla risorsa
 */
@Injectable({
    providedIn: 'root'
})
export class AclPolicy extends GuardPolicy {
    constructor(protected storage: SessionStorageService, protected router: Router) {
        super(storage, router);
    }

    checkPolicy(data: PolicyData): boolean | string {
        if (!this.storage.isAuthenticated) {
            return false;
        }
        if (data.acl) {
            // basta che una ACL sia tra i permessi dell'utente che il tale puo' accedere al routing.
            const result = data.acl.some(acl => this.storage.permissions.has(acl));
            if (!result) {
                // autorizzazioni non valide? andiamo alla login
                this.storage.reset();
                return '/login';
                // return Routing.notAuthorized.getBasePath();
            }
            return true;
        }
        return true;
        // return !data.acl || data.acl.includes(this.storage.userData.permissions);
    }
}


/**
 * L'attuale utente deve essere autenticato per accedere alla risorsa
 */
@Injectable({
    providedIn: 'root'
})
export class AuthenticatedPolicy extends GuardPolicy {
    constructor(protected storage: SessionStorageService, protected router: Router) {
        super(storage, router);
    }

    checkPolicy(): boolean {
        return this.storage.isAuthenticated;
    }
}


@Injectable({
    providedIn: 'root'
})
export class CustomPolicy extends GuardPolicy {
    constructor(protected storage: SessionStorageService, protected router: Router) {
        super(storage, router);
    }

    checkPolicy(data): boolean {
        return data.fn(data);
    }
}
