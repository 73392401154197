import {EasyCastUser} from './easy-cast-user';

export class EasyCastUserUpdateRequest extends EasyCastUser {
    /**
     * Popolato solo quando si sta modificando la password.
     * Campo in sola "scrittura": A Database le password sono sempre criptate.
     */
    password: string;

    constructor(hostUser?: EasyCastUser) {
        super();
        if (hostUser) {
            Object.assign(this, hostUser);
        }
    }
}
