<div style="overflow-y: scroll">
        <p-messages *ngFor="let notification of notifications" [severity]="mapSeverity(notification.severity)">
            <ng-template pTemplate>
               <app-notification [notification]="notification" (read)="readNotification($event)"></app-notification>
            </ng-template>
        </p-messages>
</div>
<div class="flex justify-content-end mt-3">
    <button pButton icon="fa fa-check" label="Segna tutte come lette"
            [loading]="isReadingAll"
            [disabled]="notifications.length == 0"
            (click)="readAllNotifications()"></button>
</div>
