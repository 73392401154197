import {Type} from 'class-transformer';
import {EasyCastLicense} from './easy-cast-license';
import {EasyCastLocation} from './easy-cast-location';

export class EasyCastUser {
    userId: number;

    username: string;

    /**
     * In UTC. Pertanto usare la pipe "date" e non "appDate"
     */
    @Type(() => Date)
    createdAt: Date;
    /**
     * In UTC. Pertanto usare la pipe "date" e non "appDate"
     */
    @Type(() => Date)
    updatedAt: Date;

    twoFactorKey: string;

    /**
     * In UTC. Pertanto usare la pipe "date" e non "appDate"
     */
    @Type(() => Date)
    emailValidatedAt: Date;

    active = true;

    userType: UserType;

    // Ragione sociale
    businessName: string;
    fiscalCode: string;
    vatNumber: string;
    address: string;
    city: string;
    province: string;
    zipCode: string;
    country: string;
    note: string;

    @Type(() => EasyCastUser)
    parentUser: EasyCastUser;

    parentUserId: number;

    atlantisClientId?: number;

    @Type(() => EasyCastLocation)
    locations: EasyCastLocation[];
}

export enum UserType {
    Unknown = 0,
    Host = 1,
    Reseller = 2,
    Dealer = 3,
}
