import {BaseService, Body, DELETE, GET, Headers, Path, POST, Query, Response} from 'ts-retrofit';
import {ACCEPT_JSON, goodResponseState, serviceFactory} from '../config';
import {User} from '../../../models/user';
import {ToData} from '../rest-api-hydratator-decorators';
import {Mutex} from 'async-mutex';
import {FastDialNumber} from '../../../models/fast-dial-number';

const base = 'fastDialNumbers';

export class FastDialNumbersApiService extends BaseService {

    @GET(`/${base}`)
    @Headers({...ACCEPT_JSON})
    @ToData(FastDialNumber)
    async getAll(): Promise<Response<FastDialNumber[]>> {
        return <Response>{};
    }

    @POST(`/${base}`)
    @Headers({...ACCEPT_JSON})
    @ToData(FastDialNumber)
    async save(@Body fastDial: FastDialNumber): Promise<Response<FastDialNumber>> {
        return <Response>{};
    }

    @DELETE(`/${base}/{numberId}`)
    @Headers({...ACCEPT_JSON})
    @ToData(FastDialNumber)
    async delete(@Path('numberId') numberId: number): Promise<Response> {
        return <Response>{};
    }
}
