import {Client, IClient} from './client';
import {Type} from 'class-transformer';
import {User} from '../user';

// tslint:disable-next-line:no-empty-interface
export interface IClientReminder extends ClientReminder {
}

export class ClientReminder {
    eventId: number;

    clientId: number;

    @Type(() => User)
    createdByUser: User;

    @Type(() => Client)
    client: IClient;

    @Type(() => Date)
    expireOn: Date;

    @Type(() => Date)
    notifyOn: Date | null;

    message: string;
    title: string;
    icon: string;
}
