import {Component, OnInit} from '@angular/core';
import {Contract} from '../../../../models/contract';
import {RestApiService} from '../../../../services/api';
import {ToastService} from '../../../../services/toast.service';
import {ConfirmDialog} from 'primeng/confirmdialog';
import {ConfirmationService} from 'primeng/api';
import {TicketGroup} from '../../../../models/tickets/ticket-group';
import {RoutingConstants} from '../../../../models/routing/routing-constants';
import {Routing} from '../../../../models/routing/routing';

@Component({
    selector: 'app-massive-ticket-sender',
    templateUrl: './massive-ticket-sender.component.html',
    styleUrls: ['./massive-ticket-sender.component.scss']
})
export class MassiveTicketSenderComponent implements OnInit {

    textualLogins = '';
    title = '';
    message = '';

    constructor(private api: RestApiService,
                private toast: ToastService,
                private confirmDialog: ConfirmationService) {
    }

    ngOnInit(): void {
    }

    createTickets() {
        const logins: string[] = this.textualLogins.split(/\n/g).filter(x => x.trim().length > 0);
        if (logins.length === 0) {
            this.toast.error('Errore', 'Nessuna login immessa');
            return;
        }
        if (this.message.trim().length === 0) {
            this.toast.error('Errore', 'Nessun testo immesso');
            return;
        }
        this.confirmDialog.confirm({
            message: 'Sei sicuro di voler procedere con l\'apertura dei ticket?<br>Azione non reversibile.',
            header: 'Attenzione',
            acceptLabel: 'Conferma',
            rejectLabel: 'Annulla',
            accept: async () => {
                const response = await this.api.tickets.createClientTicketBulk({
                    logins: logins,
                    title: this.title,
                    message: this.message,
                });
                this.toast.success('Ticket aperti', `Sono stati creati ${response.data} tickets.`);
            }
        });
    }

}
