import {Type} from 'class-transformer';

export class TicketFlag {
    id: number;
    idGroup: number;
    simbolo: string;
    descr: string;
    color: string;
    @Type(() => Date)
    created: Date;
    @Type(() => Date)
    ts: Date;

    clone(other: TicketFlag = null) {
        if (other == null) {
            other = this;
        }
        return Object.assign(new TicketFlag(), other);
    }
}
