export class TicketReplyMessageRequest {
    message: string;
    base64attachments?: Base64Attachment[];
    messageTemplateId?: number;
    sendSms: boolean;
    smsPhoneNumber: string;
}

export class Base64Attachment {
    fileName: string;
    attachment: string;

    static async fromFile(file: File): Promise<Base64Attachment> {
        const attachment =  new Base64Attachment();
        attachment.fileName = file.name;
        const buffer = new Uint8Array(await file.arrayBuffer());
        let binary = '';
        for (const byte of buffer) {
            binary += String.fromCharCode( byte );
        }
        attachment.attachment = btoa(binary);
        return attachment;
    }
}
