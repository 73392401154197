import {Injectable} from '@angular/core';
import {SessionStorageService} from './session-storage.service';
import {defaultThemeDescriptions, ThemeDescription} from '../models/theme-description';

@Injectable({
    providedIn: 'root'
})
export class UserSettingsService {

    constructor(private storage: SessionStorageService) {
    }

    public setTheme(themeId: number) {
        const theme = this.getThemeById(themeId);
        this.storage.storeSelectedTheme(theme.id);
        this.applyTheme(theme);
    }

    public getTheme(): ThemeDescription {
        const themeId = this.storage.getSelectedTheme();
        return this.getThemeById(themeId);
    }

    public applyTheme(theme: ThemeDescription) {
        document.getElementById('the-style').setAttribute('href', `${theme.cssFilename}.css`);
        const body = document.querySelector('body');
        body.classList.remove('dark');
        body.classList.remove('light');
        if (theme.darkTheme) {
            body.classList.add('dark');
        } else {
            body.classList.add('light');
        }
    }

    private getThemeById(themeId: number) {
        if (themeId == null) {
            return defaultThemeDescriptions[0];
        }
        return defaultThemeDescriptions.find(theme => theme.id === themeId) ?? defaultThemeDescriptions[0];
    }


}

