import {BaseService, Body, GET, Headers, POST, Query, Response} from 'ts-retrofit';
import {ACCEPT_JSON, goodResponseState, serviceFactory} from '../config';
import {User} from '../../../models/user';
import {ToData} from '../rest-api-hydratator-decorators';
import {Mutex} from 'async-mutex';

const base = 'users';


export class CachedUsersApiService {
    private api: UsersApiService = serviceFactory(UsersApiService);
    private allUsersCached: User[] = [];
    private mutex = new Mutex();

    async getAll(onlyActives: boolean = false): Promise<Response<User[]>> {
        await this.reload();
        if (onlyActives) {
            return goodResponseState(this.allUsersCached.filter(user => user.active));
        }
        return goodResponseState(this.allUsersCached);
    }

    async getById(userId: number): Promise<Response<User>> {
        await this.reload();
        return goodResponseState(this.allUsersCached.find(user => user.userId === userId));
    }

    async getByUserCode(code: string): Promise<Response<User>> {
        await this.reload();
        return goodResponseState(this.allUsersCached.find(user => user.codice === code));
    }

    async saveUser(user: User): Promise<Response<User>> {
        const response = await this.api.saveUser(user);
        this.clearCache();
        this.notifyDirtyCache();
        return response;
    }

    clearCache() {
        this.allUsersCached = [];
    }

    notifyDirtyCache() {
        // TODO: deve svuotare la cache anche di altri browser tramite SignalR
    }

    private async reload(forceReload = false) {
        await this.mutex.runExclusive(async () => {
            if (forceReload || this.allUsersCached.length === 0) {
                const response = await this.api.getAll(false);
                this.allUsersCached = response.data;
            }
        });
    }
}

class UsersApiService extends BaseService {

    @GET(`/${base}`)
    @Headers({...ACCEPT_JSON})
    @ToData(User)
    async getAll(@Query('onlyActives') onlyActives: boolean = false): Promise<Response<User[]>> {
        return <Response>{};
    }

    @POST(`/${base}`)
    @Headers({...ACCEPT_JSON})
    @ToData(User)
    async saveUser(@Body user: User): Promise<Response<User>> {
        return <Response>{};
    }
}
