import {
    BaseService,
    Body,
    DELETE,
    GET,
    Headers,
    PATCH,
    Path,
    POST,
    Query,
    Response,
} from 'ts-retrofit';
import {ACCEPT_JSON, serviceFactory} from '../config';
import {ListedResponse} from '../../../models/hydratator/listed-response';
import {FilterTicketRequest} from '../../../models/requests/filter-ticket-request';
import {ToData, ToListedResponse} from '../rest-api-hydratator-decorators';
import {TicketGroupDto} from '../../../models/responses/ticket-group-dto';
import {BodyRequest} from '../../../models/body-request';
import {TicketGroupForm} from '../../../models/responses/ticket-group-form';
import {TicketFlagTemplate} from '../../../models/tickets/ticket-flag-template';
import {ChangeTicketGroupUserAssigneeRequest} from '../../../models/requests/ticket-form/change-ticket-group-user-assignee-request';
import {TicketAlarm} from '../../../models/tickets/ticket-alarm';
import {TicketClosure} from '../../../models/tickets/ticket-closure';
import {TicketCheckClosure} from '../../../models/tickets/ticket-check-closure';
import {TicketMessagesApiService} from './ticket-form/ticket-messages-api.service';
import {TicketRdpService} from './ticket-form/ticket-rdp.service';

import {ticketsForm, alarms, tickets} from './ticket-form/ticket-form-service-constants';
import {TicketRegister} from '../../../models/tickets/ticket-register';
import {ChatMessagesApiService} from './chat-messages-api.service';
import {CreateTicketRequest, MassiveCreateTicketsRequest} from '../../../models/requests/ticket-form/create-ticket-request';
import {TicketGroup} from '../../../models/tickets/ticket-group';
import {Provider} from '../../../models/enums/provider';
import {ProviderTicketCategory, ProviderTicketType} from '../../../models/tickets/provider-ticket-category';
import {TicketContentFilterRequest} from '../../../models/requests/ticket-content-filter-request';
import {UniformedMessageEntry} from '../../../models/responses/uniformed-message-entry';
import {ProviderTicket} from '../../../models/tickets/provider/provider-ticket';
import {ClientTicket} from 'src/app/models/tickets/client/client-ticket';
import {ProviderTicketSyncState} from '../../../models/enums/ticket-states';

export class TicketsService extends BaseService {

    rdps = serviceFactory(TicketRdpService);
    messages: TicketMessagesApiService = serviceFactory(TicketMessagesApiService);
    chatMessages: ChatMessagesApiService = serviceFactory(ChatMessagesApiService);

    @POST(`/${tickets}/search`)
    @Headers({...ACCEPT_JSON})
    @ToListedResponse(TicketGroupDto)
    async tickets(@Body request: FilterTicketRequest): Promise<Response<ListedResponse<TicketGroupDto>>> { // TODO: rinominiamo in "search" o "filter" o "getAll"?
        return <Response>{};
    }

    @GET(`/${ticketsForm}/{idGroup}`)
    @Headers({...ACCEPT_JSON})
    @ToData(TicketGroupForm)
    async getTicketForm(@Path('idGroup') idGroup: number): Promise<Response<TicketGroupForm>> {
        return <Response>{};
    }

    @GET(`/${ticketsForm}/new`)
    @Headers({...ACCEPT_JSON})
    @ToData(TicketGroupForm)
    async getEmptyTicketFormByClientId(@Query('clientId') clientId: number): Promise<Response<TicketGroupForm>> {
        return <Response>{};
    }

    @GET(`/${ticketsForm}/new`)
    @Headers({...ACCEPT_JSON})
    @ToData(TicketGroupForm)
    async getEmptyTicketFormByLoginName(@Query('loginName') loginName: string): Promise<Response<TicketGroupForm>> {
        return <Response>{};
    }

    @PATCH(`/${ticketsForm}/{idGroup}/archive`)
    @Headers({...ACCEPT_JSON})
    @ToData(TicketGroupForm)
    async archiveTicketForm(@Path('idGroup') idGroup: number, @Body archived: BodyRequest<boolean>): Promise<Response<boolean>> {
        return <Response>{};
    }

    @PATCH(`/${ticketsForm}/{idGroup}/client/{clientTicketId}/title`)
    @Headers({...ACCEPT_JSON})
    @ToData(TicketGroupForm)
    async setClientTicketName(@Path('idGroup') idGroup: number,
                              @Path('clientTicketId') clientTicketId: number,
                              @Body title: BodyRequest<string>): Promise<Response<boolean>> {
        return <Response>{};
    }

    @PATCH(`/${ticketsForm}/{idGroup}/client/{clientTicketId}/unread`)
    @Headers({...ACCEPT_JSON})
    @ToData(TicketGroupForm)
    async setClientTicketUnread(@Path('idGroup') idGroup: number,
                                @Path('clientTicketId') clientTicketId: number,
                                @Body unreadState: BodyRequest<boolean>): Promise<Response<boolean>> {
        return <Response>{};
    }

    @PATCH(`/${ticketsForm}/{idGroup}/client/{clientTicketId}/state`)
    @Headers({...ACCEPT_JSON})
    @ToData(TicketGroupForm)
    async setClientTicketState(@Path('idGroup') idGroup: number,
                               @Path('clientTicketId') clientTicketId: number,
                               @Body ticketStateCode: BodyRequest<string>): Promise<Response<boolean>> {
        return <Response>{};
    }


    @PATCH(`/${ticketsForm}/{idGroup}/provider/{ticketId}/title`)
    @Headers({...ACCEPT_JSON})
    @ToData(TicketGroupForm)
    async setProviderTicketName(@Path('idGroup') idGroup: number,
                                @Path('ticketId') ticketId: number,
                                @Body title: BodyRequest<string>): Promise<Response<boolean>> {
        return <Response>{};
    }


    @PATCH(`/${ticketsForm}/{idGroup}/provider/{providerTicketId}/unread`)
    @Headers({...ACCEPT_JSON})
    @ToData(TicketGroupForm)
    async setProviderTicketUnread(@Path('idGroup') idGroup: number,
                                  @Path('providerTicketId') providerTicketId: number,
                                  @Body unreadState: BodyRequest<boolean>): Promise<Response<boolean>> {
        return <Response>{};
    }

    @PATCH(`/${ticketsForm}/{idGroup}/provider/{providerTicketId}/state`)
    @Headers({...ACCEPT_JSON})
    @ToData(TicketGroupForm)
    async setProviderTicketState(@Path('idGroup') idGroup: number,
                                 @Path('providerTicketId') providerTicketId: number,
                                 @Body ticketStateCode: BodyRequest<string>): Promise<Response<boolean>> {
        return <Response>{};
    }

    @DELETE(`/${ticketsForm}/{idGroup}/client/{clientTicketId}`)
    @Headers({...ACCEPT_JSON})
    @ToData(TicketGroupForm)
    async disassociateClientTicket(@Path('idGroup') idGroup: number,
                                   @Path('clientTicketId') clientTicketId: number,
                                   @Body targetUser: BodyRequest<string>): Promise<Response<boolean>> {
        return <Response>{};
    }

    @POST(`/${ticketsForm}/{idGroup}/client/{clientTicketId}`)
    @Headers({...ACCEPT_JSON})
    @ToData(TicketGroupForm)
    async associateClientTicket(@Path('idGroup') idGroup: number,
                                @Path('clientTicketId') clientTicketId: number): Promise<Response<boolean>> {
        return <Response>{};
    }

    @DELETE(`/${ticketsForm}/{idGroup}/provider/{providerTicketId}`)
    @Headers({...ACCEPT_JSON})
    @ToData(TicketGroupForm)
    async disassociateProviderTicket(@Path('idGroup') idGroup: number,
                                     @Path('providerTicketId') providerTicketId: number,
                                     @Body targetUser: BodyRequest<string>): Promise<Response<boolean>> {
        return <Response>{};
    }

    @POST(`/${ticketsForm}/{idGroup}/provider/{providerTicketCode}`)
    @Headers({...ACCEPT_JSON})
    @ToData(TicketGroupForm)
    async associateProviderTicket(@Path('idGroup') idGroup: number,
                                  @Path('providerTicketCode') providerTicketCode: string): Promise<Response<boolean>> {
        return <Response>{};
    }

    /**
     * Salva il ticketFlag. Salvataggio e aggiornamento usano medesimo endpoint
     * @param idGroup id del ticket group
     * @param saveFlag {boolean} se true: salva il flag come nuovo template
     * @param flag ticketFlag da salvare
     */
    @POST(`/${ticketsForm}/{idGroup}/flags`)
    @Headers({...ACCEPT_JSON})
    async saveFlag(@Path('idGroup') idGroup: number,
                   @Query('saveFlag') saveFlag: boolean,
                   @Body flag: TicketFlagTemplate): Promise<Response<boolean>> {
        return <Response>{};
    }

    @DELETE(`/${ticketsForm}/{idGroup}/flags/{id}`)
    @Headers({...ACCEPT_JSON})
    async deleteFlag(@Path('idGroup') idGroup: number,
                     @Path('id') flagId: number): Promise<Response<boolean>> {
        return <Response>{};
    }

    /**
     * Assegna un ticket form ad un utente
     * @param idGroup
     * @param changeAssigneeRequest
     */
    @PATCH(`/${ticketsForm}/{idGroup}/assignee`)
    @Headers({...ACCEPT_JSON})
    async setUserAssignee(@Path('idGroup') idGroup: number,
                          @Body changeAssigneeRequest: ChangeTicketGroupUserAssigneeRequest): Promise<Response<boolean>> {
        return <Response>{};
    }

    /**
     * Demarca come "letta" la assegnazione
     * @param idGroup
     */
    @PATCH(`/${ticketsForm}/{idGroup}/assignee/read`)
    @Headers({...ACCEPT_JSON})
    async setAssigneeMessageRead(@Path('idGroup') idGroup: number): Promise<Response<boolean>> {
        return <Response>{};
    }

    @POST(`/${ticketsForm}/{idGroup}/${alarms}`)
    @Headers({...ACCEPT_JSON})
    async saveAlarm(@Path('idGroup') idGroup: number, @Body alarm: TicketAlarm): Promise<Response<boolean>> {
        return <Response>{};
    }

    @PATCH(`/${ticketsForm}/{idGroup}/${alarms}/{id}/expiringDate`)
    @Headers({...ACCEPT_JSON})
    async postponeAlarm(@Path('idGroup') idGroup: number, @Path('id') id: number, @Query('expiringDate') date: Date): Promise<Response> {
        return <Response>{};
    }

    @PATCH(`/${ticketsForm}/{idGroup}/${alarms}/{id}/quiet`)
    @Headers({...ACCEPT_JSON})
    async quietAlarm(@Path('idGroup') idGroup: number, @Path('id') id: number, @Query('quiet') quiet: boolean): Promise<Response> {
        return <Response>{};
    }

    @DELETE(`/${ticketsForm}/{idGroup}/${alarms}/{id}`)
    @Headers({...ACCEPT_JSON})
    async deleteAlarm(@Path('idGroup') idGroup: number, @Path('id') id: number): Promise<Response> {
        return <Response>{};
    }


    @POST(`/${ticketsForm}/{idGroup}/client/{idClientTicket}/closure`)
    @Headers({...ACCEPT_JSON})
    async saveClientTicketClosure(@Path('idGroup') idGroup: number,
                                  @Path('idClientTicket') idClientTicket: number,
                                  @Body closure: TicketClosure): Promise<Response<boolean>> {
        return <Response>{};
    }

    @DELETE(`/${ticketsForm}/{idGroup}/client/{idClientTicket}/closure`)
    @Headers({...ACCEPT_JSON})
    async deleteClientTicketClosure(@Path('idGroup') idGroup: number,
                                    @Path('idClientTicket') idClientTicket: number): Promise<Response<boolean>> {
        return <Response>{};
    }

    @POST(`/${ticketsForm}/{idGroup}/closure`)
    @Headers({...ACCEPT_JSON})
    async addTicketGroupClosure(@Path('idGroup') idGroup: number, @Body closure: TicketCheckClosure): Promise<Response<boolean>> {
        return <Response>{};
    }

    @DELETE(`/${ticketsForm}/{idGroup}/closure`)
    @Headers({...ACCEPT_JSON})
    async deleteTicketGroupClosure(@Path('idGroup') idGroup: number): Promise<Response<boolean>> {
        return <Response>{};
    }

    @POST(`/${ticketsForm}/{idGroup}/registers`)
    @Headers({...ACCEPT_JSON})
    @ToData(TicketRegister)
    async registerTicketView(@Path('idGroup') idGroup: number): Promise<Response<TicketRegister>> {
        return <Response>{};
    }

    @POST(`/${tickets}/client/{clientId}`)
    @Headers({...ACCEPT_JSON})
    @ToData(TicketGroup)
    async createClientTicket(@Body createRequest: CreateTicketRequest, @Path('clientId') clientId: number): Promise<Response<TicketGroup>> {
        return <Response>{};
    }

    @POST(`/${tickets}/bulk`)
    @Headers({...ACCEPT_JSON})
    async createClientTicketBulk(@Body createRequest: MassiveCreateTicketsRequest): Promise<Response<number>> {
        return <Response>{};
    }

    @POST(`/${tickets}/provider`)
    @Headers({...ACCEPT_JSON})
    @ToData(TicketGroup)
    async createProviderTicket(@Body createRequest: CreateTicketRequest): Promise<Response<TicketGroup>> {
        return <Response>{};
    }

    @GET(`/${tickets}/categories`)
    @Headers({...ACCEPT_JSON})
    @ToData(ProviderTicketCategory)
    async getTicketCategories(@Query('provider') provider: Provider): Promise<Response<ProviderTicketCategory[]>> {
        return <Response>{};
    }

    @GET(`/${tickets}/categories/types/{id}`)
    @Headers({...ACCEPT_JSON})
    @ToData(ProviderTicketType)
    async getProviderTicketTypeById(@Path('id') id: number): Promise<Response<ProviderTicketType>> {
        return <Response>{};
    }

    @POST(`/${tickets}/sync/{ticketCode}`)
    @Headers({...ACCEPT_JSON})
    async enqueue(@Path('ticketCode') ticketCode: string): Promise<Response<boolean>> {
        return <Response>{};
    }

    @POST(`/${tickets}/contentSearch`)
    @Headers({...ACCEPT_JSON})
    @ToListedResponse(UniformedMessageEntry)
    async contentSearch(@Body filter: TicketContentFilterRequest): Promise<Response<ListedResponse<UniformedMessageEntry>>> {
        return <Response>{};
    }

    @PATCH(`/${ticketsForm}/{groupId}/provider/{providerTicketId}/contract/{contractId}`)
    @Headers({...ACCEPT_JSON})
    @ToData(ProviderTicket)
    async changeProviderContractTicketAssignee(@Path('groupId') groupId: number,
                                               @Path('providerTicketId') providerTicketId: number,
                                               @Path('contractId') contractId: number): Promise<Response<ProviderTicket>> {
        return <Response>{};
    }

    @PATCH(`/${ticketsForm}/{groupId}/client/{clientTicketId}/contract/{contractId}`)
    @Headers({...ACCEPT_JSON})
    @ToData(ClientTicket)
    async changeClientContractTicketAssignee(
        @Path('groupId') groupId: number,
        @Path('clientTicketId') clientTicketId: number,
        @Path('contractId') contractId: number,
    ): Promise<Response<ClientTicket>> {
        return <Response>{};
    }

    @PATCH(`/${tickets}/provider/{providerTicketId}/sync-state`)
    @Headers({...ACCEPT_JSON})
    async updateProviderSyncState(@Path('providerTicketId') providerTicketId: number, @Body state: BodyRequest<ProviderTicketSyncState>)
        : Promise<Response<boolean>>  {
        return <Response>{};
    }

    @PATCH(`/${tickets}/provider/{providerTicketId}/ko-sync`)
    @Headers({...ACCEPT_JSON})
    async setProviderSyncKo(
        @Path('providerTicketId') providerTicketId: number
    ): Promise<Response<boolean>> {
        return <Response>{};
    }
}
