    <label [for]="inputElementId" *ngIf="hasLabel" [style.width]="labelWidth">{{label}}</label>

    <div [style.width]="inputWidth" [style.max-width]="inputWidth" style="display: inline-block">
        <p-dropdown *ngIf="!multiple"
                    [dropdownIcon]="optionIcon"
                    [options]="dropdownItems" [optionLabel]="optionLabel" [optionValue]="optionValue"
                    [group]="group" [optionGroupLabel]="optionGroupLabel" [optionGroupChildren]="optionGroupChildren"
                    [showClear]="showClear" [style.width]="inputWidth"
                    appendTo="body" [placeholder]="placeholder"
                    [disabled]="disabled" [filter]="showFilter"
                    [editable]="editable" [maxlength]="maxlength"
                    [class]="valueClass"
                    [ngModel]="model" (ngModelChange)="modelChange.emit($event)">
        </p-dropdown>

        <p-multiSelect *ngIf="multiple" [selectedItemsLabel]="ellipsesText"
                       [options]="dropdownItems" [optionLabel]="optionLabel"
                       [panelStyle]="{'width': inputWidth}"
                       appendTo="body" [placeholder]="placeholder"
                       [ngModel]="model" (ngModelChange)="modelChange.emit($event)"
                       [tooltipDisabled]="isTooltipEnabled"
                       [maxSelectedLabels]="maxSelectableElements"
                       [disabled]="disabled" [maxlength]="maxlength"
                       [pTooltip]="selectedLabels" tooltipPosition="right"
                       [class]="valueClass"
        >
        </p-multiSelect>
    </div>


