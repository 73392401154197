import {ResponseTransformer} from 'ts-retrofit';
import {instanceToInstance, plainToClass, plainToClassFromExist, plainToInstance} from 'class-transformer';
import {ListedResponse} from '../../models/hydratator/listed-response';
import {IGenericType} from '../../models/hydratator/generic-type';

function toOne(data, type) {
    if (!data) { return null; }
    return plainToInstance(type, JSON.parse(data));
}

export const ToData = (type = null) => {
    return ResponseTransformer((data) => toOne(data, type));
};

export const ToDataFromInstance = (instance = null) => {
    return ResponseTransformer((data) => {
        if (!data) { return null; }
        const clone = instanceToInstance(instance);
        return plainToClassFromExist(clone as {}, JSON.parse(data));
    });
};

export const ToListedResponse = (type = null) => {
    return ResponseTransformer((data) => {
        if (!data) { return null; }
        const listedResponse = new ListedResponse();
        listedResponse.genericType = type;
        return plainToClassFromExist(listedResponse, JSON.parse(data));
    });
};

export const ToDataWithGeneric = (mainType = null, genericParameterType = null) => {
    return ResponseTransformer((data) => {
        if (!data) { return null; }
        const instance = new mainType();
        (instance as IGenericType).genericType = genericParameterType;
        return plainToClassFromExist(instance as {}, JSON.parse(data));
    });
};
