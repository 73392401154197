import {Component, OnInit} from '@angular/core';
import {RestApiService} from '../../../../services/api';
import {QueueJobStatus} from '../../../../models/queue-jobs/queue-job-status';
import {ToastService} from '../../../../services/toast.service';
import {DialogService} from 'primeng/dynamicdialog';
import {ConfirmationService} from 'primeng/api';

@Component({
    selector: 'app-queue-jobs-list',
    templateUrl: './queue-jobs-list.component.html',
    styleUrls: ['./queue-jobs-list.component.scss']
})
export class QueueJobsListComponent implements OnInit {

    loading = false;
    queues: QueueJobStatus[] = [];
    categories: string[] = [];

    constructor(private api: RestApiService,
                private toast: ToastService,
                private confirmation: ConfirmationService) {
    }

    ngOnInit(): void {
        this.loadData();
    }

    async loadData() {
        try {
            this.loading = true;
            const response = await this.api.queueJobs.getAll();
            this.queues = response.data;
            this.categories = [...new Set(this.queues.map(x => x.category))];
        } finally {
            this.loading = false;
        }
    }

    getRows(category: string): QueueJobStatus[] {
        return this.queues.filter(x => x.category === category);
    }

    obj(item): QueueJobStatus {
        return item;
    }

    async runTestJob() {
        await this.api.test.runTestQueueJob();
        this.toast.info('Test Queue job', 'Accodati i test queue jobs');
        setTimeout(async () => {
            await this.loadData();
        }, 500);
    }

    showStackTrace(item: QueueJobStatus) {
        this.confirmation.confirm({
            header: 'Stack trace',
            acceptLabel: 'Ok',
            rejectVisible: false,
            message: item.errorStackTrace.replace('\n', '<br>')
        });
    }
}
