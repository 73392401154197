import {BaseFilterRequest} from '../base-filter-request';
import {FEnum} from '../../../services/api/filters/enum-filter';
import {FInt} from '../../../services/api/filters/int-filter';
import {FDate} from '../../../services/api/filters/date-filter';

export class FilterEasyCastLicenseRequest extends BaseFilterRequest {
    easyCastLicenseId: FInt;
    licenseType: FEnum;
    createdAt: FDate;
    numberPlaybackDevices: FInt;
    credits: FInt;
    hostUserId: FInt;
}
