<p-card>
    <ng-template pTemplate="header">
        <p>Sezione di Playground</p>
    </ng-template>
    <ng-template pTemplate="body">
        <ul>
            <li>{{contractStrigifier.loginMaster}}</li>
            <li>{{contractStrigifier.servizioNavigation.id}}</li>
        </ul>

<!--        <button (click)="getAb()">Get AB (guarda la console)</button>-->

        <label appCopyable>Ciao, sono Daniele</label>
        <p appCopyable>Paragrafo</p>
        <button appCopyable (click)="showAlert()">Pulsante</button>

        <p-button (click)="loadTypedTest()">Prova Typed test</p-button>
        <div *ngIf="typedTest != null">
            {{typedTest.name}} at {{typedTest.timestamp}}
            <div *ngFor="let message of typedTest.messages">
                TEXT: {{message.text}}<br>
                Date: {{message.timestamp}}
            </div>
        </div>


        <p-button (click)="loadTypedTestList()">Prova Typed test LIST</p-button>
        <div *ngIf="typedTestList!= null">
            <div *ngFor="let item of typedTestList.dataList">
                {{item.name}} at {{item.timestamp}}
                <div *ngFor="let message of item.messages">
                    TEXT: {{message.text}}<br>
                    Date: {{message.timestamp}}
                </div>
            </div>
        </div>

        <p-button (click)="testSignalRSendMessage()">Prova Send SignalR message</p-button>

    </ng-template>
</p-card>

<p-card>
    <ng-template pTemplate="header">
        <h3>Apertura ticket massiva</h3>
    </ng-template>
    <ng-template pTemplate="body">
        <app-massive-ticket-sender></app-massive-ticket-sender>
    </ng-template>
</p-card>
