import {BaseService, Body, DELETE, GET, Headers, Path, POST, Response} from 'ts-retrofit';
import {ACCEPT_JSON} from '../config';
import {ToData, ToListedResponse} from '../rest-api-hydratator-decorators';
import {ClientReminder} from '../../../models/clients/client-reminder';

const clientsPath = '/clients';

export class ClientReminderService extends BaseService {

    @DELETE(`${clientsPath}/{clientId}/reminders/{reminderId}`)
    @Headers({...ACCEPT_JSON})
    @ToData(ClientReminder)
    async delete(@Path('clientId') clientId: number, @Path('reminderId') reminderId: number): Promise<Response<ClientReminder>> {
        return <Response>{};
    }

    @POST(`${clientsPath}/{clientId}/reminders`)
    @Headers({...ACCEPT_JSON})
    @ToData(ClientReminder)
    async save(@Path('clientId') clientId: number, @Body request: ClientReminder): Promise<Response<ClientReminder>> {
        return <Response>{};
    }

    @GET(`${clientsPath}/{clientId}/reminders`)
    @Headers({...ACCEPT_JSON})
    @ToData(ClientReminder)
    async getAllByClientId(@Path('clientId') clientId: number): Promise<Response<ClientReminder[]>> {
        return <Response>{};
    }

    @GET(`${clientsPath}/{clientId}/reminders/{reminderId}`)
    @Headers({...ACCEPT_JSON})
    @ToData(ClientReminder)
    async getAll(@Path('clientId') clientId: number, @Path('reminderId') reminderId: number): Promise<Response<ClientReminder>> {
        return <Response>{};
    }

}
