import {BaseService, GET, Headers, Response} from 'ts-retrofit';
import {Settings, UserSettings} from '../../../models/user-settings';
import {ACCEPT_JSON, goodResponseState, serviceFactory} from '../config';
import {User} from '../../../models/user';
import {ToData} from '../rest-api-hydratator-decorators';
import {Mutex} from 'async-mutex';

const base = 'userSettings';

export class CachedUserSettingsApiService {
    private api: UserSettingsApiService = serviceFactory(UserSettingsApiService);
    private settingsCached: UserSettings | null = null;
    private mutex = new Mutex();

    async getSettings() {
        await this.reload();
        return goodResponseState(this.settingsCached);
    }

    async getSetting<K extends keyof Settings>(key: K) {
        await this.reload();
        return goodResponseState(this.settingsCached[key]);
    }

    clearCache() {
        this.settingsCached = null;
    }

    notifyDirtyCache() {
        // TODO: deve svuotare la cache anche di altri browser tramite SignalR
    }

    private async reload(forceReload = false) {
        await this.mutex.runExclusive(async () => {
            if (forceReload || this.settingsCached === null) {
                const response = await this.api.getUserSettings();
                this.settingsCached = response.data;
            }
        });
    }
}

class UserSettingsApiService extends BaseService {
    @GET(`/${base}/self`)
    @Headers({...ACCEPT_JSON})
    @ToData(User)
    async getUserSettings(): Promise<Response<UserSettings>> {
        return <Response>{};
    }
}
