import {BaseService, GET, Headers, Query, Response} from 'ts-retrofit';
import {ACCEPT_JSON} from '../config';
import {ToData} from '../rest-api-hydratator-decorators';
import {AddressData} from '../../../models/address-data';

const base = 'tools';

export class ToolsApiService extends BaseService {

    @GET(`/${base}/randomText`)
    @Headers({...ACCEPT_JSON})
    async getRandomText(@Query('length') length: number = null): Promise<Response<string>> {
        return <Response>{};
    }

    @GET(`/${base}/cities/search`)
    @Headers({...ACCEPT_JSON})
    @ToData(AddressData)
    async searchCities(@Query('cityName') city: string): Promise<Response<AddressData[]>> {
        return <Response>{};
    }
}
