import {Type} from 'class-transformer';
import {Stringifier} from '../../services/stringifier.service';
import {CustomFieldType, ICustomFieldType} from './CustomFieldType';
import {INetworkInterface, NetworkInterface} from './NetworkInterface';

// tslint:disable-next-line:no-empty-interface
export interface INetworkInterfaceCustomFieldType extends NetworkInterfaceCustomFieldType {
}

@Stringifier
export class NetworkInterfaceCustomFieldType {
    networkInterfaceCustomFieldTypeId: number;
    value: string;

    networkInterfaceId: number;
    @Type(() => NetworkInterface)
    networkInterface?: INetworkInterface;

    customFieldTypeId: number;
    @Type(() => CustomFieldType)
    customFieldType?: ICustomFieldType;

    static getStringifier(level: number) {
        if (level < 0) {
            return null;
        }
        return {
            networkInterfaceCustomFieldTypeId: null,
            value: null,
            networkInterfaceId: null,
            networkInterface: NetworkInterface.getStringifier(level - 1),
            customFieldTypeId: null,
            customFieldType: CustomFieldType.getStringifier(level - 1),
        } as NetworkInterfaceCustomFieldType;
    }
}
