import {Component, ComponentFactoryResolver, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {filter, takeUntil} from 'rxjs/operators';
import {NavigationStart, Router} from '@angular/router';
import {Subject} from 'rxjs';
import {MenuService} from '../../../../services/menu.service';
import {MenuDirective} from '../menu.directive';
import {MenuInterface} from '../../../../components/menu/menu-interface';

@Component({
    selector: 'app-toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit, OnDestroy {

    destroySubscription = new Subject<boolean>();

    mftWebOldPage?: string;

    @ViewChild(MenuDirective, {static: true}) addMenu!: MenuDirective;

    constructor(private menuService: MenuService,
                private router: Router,
                private resolver: ComponentFactoryResolver
    ) {
    }

    ngOnInit(): void {

        this.resetTabMenuOnRoute();

        this.menuService.getMenu()
            .pipe(
                takeUntil(this.destroySubscription),
                filter(m => !!m)
            ).subscribe(menu => {
            const viewContainerRef = this.addMenu.viewContainerRef;
            viewContainerRef.clear();
            const factory = this.resolver.resolveComponentFactory(menu.menu);
            const componentRef = viewContainerRef.createComponent<MenuInterface>(
                factory
            );
            if (menu.data) {
                componentRef.instance.data = menu.data;
            }
        });
    }

    ngOnDestroy() {
        this.destroySubscription.next(true);
        this.destroySubscription.unsubscribe();
    }

    private resetTabMenuOnRoute() {
        this.router.events
            .pipe(
                takeUntil(this.destroySubscription),
                filter(event => event instanceof NavigationStart)
            ).subscribe(_ => this.addMenu.viewContainerRef.clear());
    }
}
