import {BaseService, Body, GET, Headers, Path, POST, Response} from 'ts-retrofit';
import {ACCEPT_JSON} from '../../config';
import {ToData, ToListedResponse} from '../../rest-api-hydratator-decorators';
import {Client} from '../../../../models/clients/client';
import {ListedResponse} from '../../../../models/hydratator/listed-response';
import {EasyCastUser} from '../../../../models/easy-cast/easy-cast-user';
import {EasyCastUserUpdateRequest} from '../../../../models/easy-cast/easy-cast-user-update-request';
import {FilterEasyCastUsersRequest} from '../../../../models/requests/easy-cast/filter-easy-cast-users.request';

const base = 'easycast/users';

export class EasyCastUsersApiService extends BaseService {
    @POST(`/${base}/search`)
    @Headers({...ACCEPT_JSON})
    @ToListedResponse(Client)
    async filter(@Body request: FilterEasyCastUsersRequest): Promise<Response<ListedResponse<EasyCastUser>>> {
        return <Response>{};
    }

    @GET(`/${base}/{id}`)
    @Headers({...ACCEPT_JSON})
    @ToData(EasyCastUser)
    async get(@Path('id') id: number): Promise<Response<EasyCastUser>> {
        return <Response>{};
    }

    @GET(`/${base}/{id}/token`)
    @Headers({...ACCEPT_JSON})
    async getAuthenticationToken(@Path('id') id: number): Promise<Response<string>> {
        return <Response>{};
    }

    @POST(`/${base}`)
    @Headers({...ACCEPT_JSON})
    @ToData(EasyCastUser)
    async save(@Body user: EasyCastUserUpdateRequest): Promise<Response<EasyCastUser>> {
        return <Response>{};
    }

}
