import { Type } from 'class-transformer';
import {Stringifier} from '../../services/stringifier.service';

@Stringifier
export class TicketGroupRdp {
    idGroup?: number;
    @Type(() => Date)
    scadenza: Date;
    user: string;
    aprireValutazioneComm = false;
    concluso = false;
    annullato = false;
    @Type(() => Date)
    ts: Date;

    /**
     * Imposta la scadenza dell'RDP aggiungendo `daysToDeadline` giorni alla data corrente
     * @param daysToDeadline
     */
    setDeadline(daysToDeadline: number) {
        const today = new Date();
        this.scadenza = new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate()));
        this.scadenza.setDate(this.scadenza.getDate() + daysToDeadline);
    }

    static getStringifier(level: number) {
        if (level < 0) {
            return null;
        }
        return {
            idGroup: null,
            scadenza: null,
            user: null,
            aprireValutazioneComm: null,
            concluso: null,
            annullato: null,
            ts: null,
        } as TicketGroupRdp;
    }
}
