import {Component, OnInit} from '@angular/core';
import { Routing } from 'src/app/models/routing/routing';
import {FastDialNumber} from '../../../models/fast-dial-number';
import {RestApiService} from '../../../services/api';
import {AclPermission} from '../../../models/acl/acl-group';

@Component({
    selector: 'app-fast-dial-numbers',
    templateUrl: './fast-dial-numbers.component.html',
    styleUrls: ['./fast-dial-numbers.component.scss']
})
export class FastDialNumbersComponent implements OnInit {

    numbers: FastDialNumber[] = [];
    loading = false;
    loadingError = false;

    Routing = Routing;
    AclPermission = AclPermission;
    obj(item: FastDialNumber) {
        return item;
    }

    constructor(private api: RestApiService) {
    }

    ngOnInit(): void {
        this.loadFastDialNumbers();
    }

    async loadFastDialNumbers() {
        this.loading = true;
        try {
            this.numbers = (await this.api.fastDialNumbers.getAll()).data;
        } catch (e) {
            this.loadingError = true;
        } finally {
            this.loading = false;
        }
    }
}
