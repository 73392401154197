import {Stringifier} from '../../services/stringifier.service';
import {Type} from 'class-transformer';

@Stringifier
export class EasyCastCreditPack {
    creditPackId: number;
    credits: number;
    usagesCount: number;
    @Type(() => Date)
    expiresAt: Date;
    @Type(() => Date)
    createdAt: Date;
    @Type(() => Date)
    updatedAt?: Date;
    productPackId: number;
    userId: number;
    /**
     * read only field
     */
    isActive: boolean;

    static getStringifier(level: number): EasyCastCreditPack | null {
        if (level < 0) {
            return null;
        }
        // @ts-ignore
        return {
            creditPackId: null,
            credits: null,
            usagesCount: null,
            expiresAt: null,
            createdAt: null,
            updatedAt: null,
            productPackId: null,
            userId: null,
            isActive: null,
        } as EasyCastCreditPack;
    }
}
