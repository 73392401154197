<div class="container flex fluid">
    <div class="background col hidden md:flex md:col-8 xl:col-10">
        <img src="assets/images/logo-negative.png" alt="MFT Italia" class="logo">
    </div>
    <div class="login col content-section">
        <form #form='ngForm' class="login-form mb-3 fluid flex-column text-center" (ngSubmit)="authenticateCode()">
            <h3>AUTENTICAZIONE</h3>
            <p>&Egrave; richiesta l'immissione del codice a due fattori</p>
            <div class="field">
                <input pInputText name="code" type="text" placeholder="Codice" required
                       class="inputfield w-full"
                       [(ngModel)]="code">
            </div>
            <p-button type="submit" class=""
                      [loading]="submitting"
                      label="Conferma"
            ></p-button>
        </form>
        <app-theme-chooser></app-theme-chooser>
    </div>
</div>
