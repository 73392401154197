import {BaseService, GET, Headers, POST, Response, ResponseTransformer} from 'ts-retrofit';
import {ACCEPT_JSON} from '../config';
import {ToDataFromInstance, ToData, ToListedResponse, ToDataWithGeneric} from '../rest-api-hydratator-decorators';
import {TypedTest} from '../../../models/test/typed-test';
import {ListedResponse} from '../../../models/hydratator/listed-response';
import {A} from '../../../models/playground/a';

const base = 'test';

export class TestApiService extends BaseService {

    @GET(`/${base}/typed`)
    @Headers({...ACCEPT_JSON})
    @ToData(TypedTest)
    async getTypedTest(): Promise<Response<TypedTest>> {
        return <Response>{};
    }

    @GET(`/${base}/typedList`)
    @Headers({...ACCEPT_JSON})
    // @ToDataFromInstance(new ListedResponse<TypedTest>(TypedTest))
    // @ToDataWithGeneric(ListedResponse, TypedTest)
    @ToListedResponse(TypedTest)
    async getTypedTestList(): Promise<Response<ListedResponse<TypedTest>>> {
        return <Response>{};
    }

    @GET(`/${base}/ab`)
    @Headers({...ACCEPT_JSON})
    @ToData(A)
    async getAb(): Promise<Response<A>> {
        return <Response>{};
    }

    @POST(`/${base}/testQueueJob`)
    @Headers({...ACCEPT_JSON})
    async runTestQueueJob(): Promise<Response> {
        return <Response>{};
    }
}
