import {Type} from 'class-transformer';
import {Stringifier} from '../services/stringifier.service';
import {Contract} from './contract';

@Stringifier
export class ContractTree {
    @Type(() => Contract)
    contract: Contract;
    @Type(() => ContractTree)
    children: ContractTree[];

    * [Symbol.iterator](): Iterator<ContractTree> {
        yield this;
        for (const child of this.children) {
            yield* child;
        }
    }

    static getStringifier(level: number) {
        if (level < 0) {
            return null;
        }
        return {
            contract: Contract.getStringifier(level - 1),
            children: null,
        } as ContractTree;
    }
}

