import {LineColor} from './line-color';
import {Type} from 'class-transformer';
import {Stringifier} from '../services/stringifier.service';
// import {createMock} from 'ts-auto-mock';

@Stringifier
export class ContractService {
    id: number;
    name: string;
    nameOld: string;
    @Type(() => LineColor)
    lineColor?: LineColor;

    static getStringifier(level: number) {
        if (level < 0) {
            return null;
        }
        return {
            id: null,
            name: null,
            nameOld: null,
            lineColor: LineColor.getStringifier(level - 1),
        } as ContractService;
    }
}
