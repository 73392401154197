import {Type} from 'class-transformer';
import {TicketGroupSide} from './ticket-group-side';

export class TicketAssignment {
    id: number;
    idGroup: number;
    ticketGroupSide: TicketGroupSide;
    user: string;
    toUser: string;
    message: string;
    checked: number;

    @Type(() => Date)
    ts: Date;

    get isClientAssignment() {
        return this.ticketGroupSide === TicketGroupSide.Client;
    }

    get isProviderAssignment() {
        return this.ticketGroupSide === TicketGroupSide.Provider;
    }
}
