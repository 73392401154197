import {Injectable, Type} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {MenuInterface} from '../components/menu/menu-interface';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

    private _menu = new BehaviorSubject<ToolbarMenu>(undefined);

    getMenu() {
        return this._menu.asObservable();
    }

    setMenu(menu: ToolbarMenu) {
        this._menu.next(menu);
    }
}

export interface ToolbarMenu {
    menu: Type<MenuInterface>;
    data?: any;
}


