import {Component, OnInit} from '@angular/core';
import {SessionStorageService, setToken} from '../../../../services/session-storage.service';
import {RestApiService} from '../../../../services/api';
import {User} from '../../../../models/user';
import {AclPermission} from '../../../../models/acl/acl-group';

@Component({
    selector: 'app-switch-user',
    templateUrl: './switch-user.component.html',
    styleUrls: ['./switch-user.component.scss']
})
export class SwitchUserComponent implements OnInit {

    permissions = AclPermission;

    users: User[] = [];
    selectedUser?: User;

    constructor(private storageService: SessionStorageService, private api: RestApiService) {
    }

    ngOnInit(): void {
        if (this.storageService.hasPermission(AclPermission.ListAllUsers)) {
            this.api.usersCached.getAll()
                .then(response => this.users = response.data);
        }
    }

    get canRestore() {
        return this.storageService.canRestoreUser();
    }

    async switch() {
        if (this.selectedUser) {
            const response = await this.api.authentication.getUserData(this.selectedUser.username);
            this.storageService.switchUser(response.data);
            location.reload();
        }
    }

    restore() {
        if (this.canRestore) {
            this.storageService.restoreRealUser();
            location.reload();
        }
    }

    async refresh() {
        const response = await this.api.authentication.getUserData(this.storageService.userData.username);
        this.storageService.userData = response.data;
        setToken(response.data.token);
        location.reload();
    }

}
