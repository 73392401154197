import {Pipe} from '@angular/core';
import {DatePipe} from '@angular/common';

// tslint:disable-next-line:use-pipe-transform-interface
@Pipe({name: 'appDate'})
export class AppDatePipe extends DatePipe {
    transform(value: Date | string | number, format?: string, timezone?: string, locale?: string): string | null;
    transform(value: null | undefined, format?: string, timezone?: string, locale?: string): null;
    transform(value: Date | string | number | null | undefined, format?: string, timezone?: string, locale?: string): string | null {
        return super.transform(value, format, 'UTC', locale);
    }
}
