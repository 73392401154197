import {ITalkingCode} from './interfaces/italking-code';
import {ICloneable} from './interfaces/icloneable';

export class UserGroup implements ITalkingCode, ICloneable {
    id: number;
    groupCodice: string;
    userCodice: string;
    groupLabel: string;
    userId: number;

    getTalkingCode(): string {
        return this.groupCodice;
    }

    clone(other: UserGroup = null) {
        if (other == null) {
            other = this;
        }
        return Object.assign(new UserGroup(), other);
    }

}
