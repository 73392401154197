import {Injectable} from '@angular/core';
import {UserData} from '../models/user-data';
import {AclPermission} from '../models/acl/acl-group';
import jwtDecode, {JwtPayload} from 'jwt-decode';

const UserDataKey = 'user';
const SelectedThemeKey = 'selected-theme';
const RealUserDataKey = 'realUser';

export function getUserData() {
    return JSON.parse(localStorage.getItem(UserDataKey) ?? '{}') as UserData;
}

export function setUserData(data: UserData) {
    localStorage.setItem(UserDataKey, JSON.stringify(data));
}

export function getToken() {
    return getUserData().token;
}

export function setToken(token: string) {
    const userData = getUserData();
    userData.token = token;
    setUserData(userData);
}

export function reset() {
    localStorage.clear();
}

enum JWTClaims {
    AclGroups = 'AclGroups'
}

@Injectable({
    providedIn: 'root'
})
export class SessionStorageService {

    _cachedToken: string = null;
    _cachedPermissions: Set<AclPermission> = null;

    reset() {
        reset();
    }

    getToken() {
        return getToken();
    }

    set userData(data: UserData) {
        setUserData(data);
    }

    get userData() {
        return getUserData();
    }

    get isAuthenticated() {
        return getUserData() !== null;
    }

    get role() {
        return this.userData.role;
    }

    get permissions(): Set<AclPermission> {

        const token = getToken();
        if (this._cachedPermissions === null || this._cachedToken !== token) {
            this._cachedPermissions  = new Set();
            this._cachedToken = token;
            if (token) {
                const tokenPayload = jwtDecode<JwtPayload>(token);
                let permissions = tokenPayload[JWTClaims.AclGroups];
                if (permissions) {
                    if (!Array.isArray(permissions)) {
                        permissions = [permissions];
                    }
                    this._cachedPermissions = new Set(permissions.map(v => Number(v)));
                }
            }
        }

        return this._cachedPermissions;
    }

    storeSelectedTheme(selectedTheme: number) {
        localStorage.setItem(SelectedThemeKey, selectedTheme.toString(10));
    }

    getSelectedTheme(): number | null {
        const themeIdStr = localStorage.getItem(SelectedThemeKey);
        if (themeIdStr != null) {
            return parseInt(themeIdStr, 10);
        }
        return null;
    }

    /**
     * Verifica l'utente collegato presenta almeno uno dei permessi specificati in input.
     * @param aclPermissionTest
     */
    hasPermission(aclPermissionTest: AclPermission | AclPermission[]): boolean {
        if (Array.isArray(aclPermissionTest)) {
            return aclPermissionTest.some(requiredAcl => this.permissions.has(requiredAcl));
        }
        return this.permissions.has(aclPermissionTest);
    }

    /**
     * Copia i dati dell'utente attuale e li sostituisce con quelli di un utente dato
     * @param user
     */
    switchUser(user: UserData) {
        localStorage.setItem(RealUserDataKey, localStorage.getItem(UserDataKey));
        this.userData = user;
        setToken(this.userData.token);
    }

    /**
     * Ripristina i dati dell'utente originale
     */
    restoreRealUser() {
        const originalUserData = localStorage.getItem(RealUserDataKey);
        if (originalUserData) {
            localStorage.setItem(UserDataKey, originalUserData);
            localStorage.removeItem(RealUserDataKey);
        }
    }

    /**
     * Restituisce un valore booleano che indica se sono stati memorizzati i dati
     * dell'utente originariamente autenticato
     */
    canRestoreUser() {
        return !!localStorage.getItem(RealUserDataKey);
    }
}
