import {Provider} from '../enums/provider';

export class ProviderTicketCategory {
    id: number;
    descr: string;
    commerciale?: number;
    provider: Provider;
    servizio: string;
    types: ProviderTicketType[];
}

export class ProviderTicketType {
    idKey: number;
    id: number;
    descr: string;
    tipoMsg: string;
}
