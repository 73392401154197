import {ErrorHandler, Injectable, NgZone} from '@angular/core';
import {ToastService} from '../services/toast.service';
import {RestApiError} from '../services/api/rest-api-error';
import {HttpStatusCode} from '@angular/common/http';

@Injectable()
export class ExceptionHandler implements ErrorHandler {

    constructor(private toastService: ToastService, private zone: NgZone) {
    }

    handleError(error: any): void {

        console.log('Handler degli errori');
        console.log(error);

        if (error.rejection) {

            const errorRejection = error.rejection;

            if (errorRejection instanceof RestApiError) {

                const code = errorRejection.code;
                const message = errorRejection.message ?? '';

                if (code === 0) {
                    this.zone.run(() => this.toastService.error('Errore di connessione', message));
                    return;
                }

                if (code === HttpStatusCode.Unauthorized || code === HttpStatusCode.Forbidden) {
                    this.zone.run(() => this.toastService.error('Permessi insufficienti', message));
                    return;
                }

                if (code === HttpStatusCode.InternalServerError) {
                    this.zone.run(() => this.toastService.error('Errore sul server', message));
                    return;
                }
            }


        }
    }

}
