import {Response, ResponseInterceptor, ServiceBuilder} from 'ts-retrofit';
import {environment} from '../../../environments/environment';
import {RequestInterceptorFunction} from 'ts-retrofit/lib/types/baseService';
import {RestApiError} from './rest-api-error';
import {getToken, reset, setToken} from '../session-storage.service';
import {MftHttpStatusCode} from './mft-http-status-code';
import {HttpStatusCode} from '@angular/common/http';

export const ACCEPT_JSON = {Accept: 'application/json'};
export const ACCEPT_PDF = {Accept: 'application/pdf'};
export const AUTHORIZATION = 'Authorization';

const requestInterceptor: RequestInterceptorFunction = (request) => {
    const token = getToken();
    if (token) {
        request.headers = {...request.headers, AUTHORIZATION: `Bearer ${token}`};
    }

    return request;
};

class MftResponseInterceptor extends ResponseInterceptor {
    onFulfilled(value) {

        const authHeader = value.headers[AUTHORIZATION] ?? value.headers[AUTHORIZATION.toLowerCase()];
        if (authHeader) {
            setToken(authHeader);
        }

        return value;
    }

    onRejected(error: any) {
        if (error.response == null) {
            throw new RestApiError(
                'Problema di connessione internet. Se sei da una rete mobile verifica di avere campo.',
                MftHttpStatusCode.NoConnection);
        }

        if (error.response?.status === MftHttpStatusCode.InsecureIp || error.response?.status === HttpStatusCode.Unauthorized) {
            reset();
            location.reload();
        }

        throw new RestApiError(
            error.response.data?.errorMessage ??
            error.response.data?.title ??
            error.response.data?.message ??
            '', error.response.status, error.response.data);
    }
}

const responseInterceptor = new MftResponseInterceptor();

export const serviceFactory = <T>(service: new (builder: ServiceBuilder, ...args: any[]) => T) => {
    return new ServiceBuilder()
        .setEndpoint(environment.baseApi)
        .setStandalone(true)
        .setRequestInterceptors(requestInterceptor)
        .setResponseInterceptors(responseInterceptor)
        .build(service);
};


export function goodResponseState<T>(data: T): Response<T> {
    return <Response<T>>{
        data: data,
        config: {},
        headers: {},
        status: 200,
        statusText: 'Cached response',
        request: 'Cached response'
    };
}
