import {Type} from 'class-transformer';

export class TypedTest {
    name: string;

    @Type(() => Date)
    timestamp: Date;

    @Type(() => TestMessage)
    messages: TestMessage[];
}

export class TestMessage {
    text: string;

    @Type(() => Date)
    timestamp: Date;
}
