import {BehaviorSubject, distinctUntilChanged, Observable} from 'rxjs';
import {Injectable} from '@angular/core';

export enum ResizeType {
    Small,
    Medium,
    Large,
    ExtraLarge
}

export interface ScreenSize {
    width: number;
    height: number;
    type: ResizeType;
}

const sm_breakpoint = 768;
const md_breakpoint = 992;
const lg_breakpoint = 1200;

@Injectable({
    providedIn: 'root'
})
export class ResizeService {

    private size: BehaviorSubject<ScreenSize>;

    get screenSize$(): Observable<ScreenSize> {
        return this.size.asObservable().pipe(
            distinctUntilChanged()
        );
    }

    constructor() {
        this.size = new BehaviorSubject<ScreenSize>(this.currentSize);
    }

    get currentSize(): ScreenSize {
        return {
            width: window.innerWidth,
            height: window.innerHeight,
            type: mapSizeToType(window.innerWidth)
        };
    }

    resize() {
        this.size.next(this.currentSize);
    }

}

function mapSizeToType(width: number): ResizeType {
    if (width < sm_breakpoint) {
        return ResizeType.Small;
    } else if (width < md_breakpoint) {
        return ResizeType.Medium;
    } else if (width < lg_breakpoint) {
        return ResizeType.Large;
    } else {
        return ResizeType.ExtraLarge;
    }
}
