import {Component, Input, OnInit} from '@angular/core';
import {NotificationMessage} from '../../../models/notification-message';

@Component({
  selector: 'app-base-notification',
  templateUrl: './base-notification.component.html',
  styleUrls: ['./base-notification.component.scss']
})
export class BaseNotificationComponent implements OnInit {

    @Input() notification: NotificationMessage;
  constructor() { }

  ngOnInit(): void {
  }

}
