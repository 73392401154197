<p-panel>

    <ng-template pTemplate="header">
        <h3>Impostazioni utente</h3>
    </ng-template>

    <ng-template pTemplate="content">
        <div class="grid">
            <div class="sm:col-12 md:col-6 lg:col-4 xl:col-3">
                <app-theme-chooser></app-theme-chooser>
            </div>
            <div class="sm:col-12 md:col-6 lg:col-4 xl:col-3" *appAcl="aclPermission.TwoFactorsAuthentication">

                <p-panel header="Autenticazione a due fattori">
                    <div class="grid" *ngIf="twoFactorQrCodeUrl">
                        <p>Scansiona il seguente QRCode tramite la tua app (<i>es. Authenticator</i>)</p>
                        <button pButton class="p-button p-button-outlined p-button-info"
                                (click)="showQrCode = true" *ngIf="!showQrCode">Mostra QRCode
                        </button>
                        <qr-code [value]="twoFactorQrCodeUrl" size="200" style="width: 100%"
                                 *ngIf="showQrCode"></qr-code>
                        <br>
                        <p>Prova che il codice generato dalla tua app sia valido trascrivendolo qui sotto</p>
                        <app-input label="Test" [showLength]="false"
                                   labelWidth="auto"
                                   inputWidth="100%"
                                   actionButtonLabel="Controlla"
                                   (action)="testTwoFactor($event.toString())"
                                   style="width: 100%"></app-input>

                    </div>
                </p-panel>


            </div>
        </div>
    </ng-template>

</p-panel>
