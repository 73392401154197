import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {RoutingConstants} from '../../../../models/routing/routing-constants';

@Component({
    selector: 'app-unauth',
    templateUrl: 'unauth-view.component.html'
})
export class UnauthViewComponent {

    constructor(private router: Router) {
    }


    goToHome() {
        this.router.navigateByUrl(RoutingConstants.dashboard);
    }
}
