import {BaseService, Body, GET, Headers, PATCH, Path, POST, Response} from 'ts-retrofit';
import {ACCEPT_JSON} from '../../config';
import {ToListedResponse} from '../../rest-api-hydratator-decorators';
import {ListedResponse} from '../../../../models/hydratator/listed-response';
import {EasyCastLocation} from '../../../../models/easy-cast/easy-cast-location';
import {FilterEasyCastLocationsRequest} from '../../../../models/requests/easy-cast/filter-easy-cast-locations.request';

const base = 'easycast/easycastLocations';

export class EasyCastLocationsApiService extends BaseService {
    @POST(`/${base}/search`)
    @Headers({...ACCEPT_JSON})
    @ToListedResponse(EasyCastLocation)
    async filter(@Body request: FilterEasyCastLocationsRequest): Promise<Response<ListedResponse<EasyCastLocation>>> {
        return <Response>{};
    }
}
