import {Transform, Type} from 'class-transformer';
import {Vector2d} from 'konva/lib/types';
import {Stringifier} from 'src/app/services/stringifier.service';
import {Client, IClient} from '../clients/client';
import {ISprite, Sprite} from './Sprite';

// tslint:disable-next-line:no-empty-interface
export interface IDeviceMap extends DeviceMap {
}

interface Data {
    position: Vector2d;
    scale: number;
}

@Stringifier
export class DeviceMap {
    deviceMapId: number;
    name: string;
    description: string;

    data: Data;

    clientId: number;
    @Type(() => Client)
    client?: IClient;

    @Type(() => Sprite)
    sprites: ISprite[] = [];

    isDeleted = false;

    static getStringifier(level: number) {
        if (level < 0) {
            return null;
        }
        return {
            deviceMapId: null,
            name: null,
            description: null,
            data: null,
            clientId: null,
            client: Client.getStringifier(level - 1),
            sprites: null,
            isDeleted: null,
        } as DeviceMap;
    }
}



