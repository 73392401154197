import {BaseService, Body, DELETE, GET, Headers, PATCH, Path, POST, PUT, Response} from 'ts-retrofit';
import {PlaybackDevice} from '../../../../models/easy-cast/easy-cast-playback-device';
import {ACCEPT_JSON} from '../../config';
import {ToData, ToListedResponse} from '../../rest-api-hydratator-decorators';
import {ListedResponse} from '../../../../models/hydratator/listed-response';
import {EasyCastContract, EasyCastGmailAccountConfiguration} from '../../../../models/easy-cast/easy-cast-contract';
import {FilterEasyCastContractsRequest} from '../../../../models/requests/easy-cast/filter-easy-cast-contracts.request';
import {PayEasyCastContractRequest} from '../../../../models/requests/easy-cast/pay-easy-cast-contract-request';
import {ReflectorClientIdsResponse} from '../../../../models/responses/reflector-client-ids-response';
import {SaveEasyCastContractRequest} from '../../../../models/requests/easy-cast/save-easy-cast-contract-request';
import {ConfigureEasyCastContractRequest} from '../../../../models/requests/easy-cast/configure-easy-cast-contract-request';
import {BodyRequest} from '../../../../models/body-request';
import {SaveEasyCastShippingDetailsRequest} from '../../../../models/requests/easy-cast/save-easy-cast-shipping-details-request';

const base = 'easycast/easycastContracts';

export class EasyCastContractsApiService extends BaseService {
    @POST(`/${base}/search`)
    @Headers({...ACCEPT_JSON})
    @ToListedResponse(EasyCastContract)
    async filter(@Body request: FilterEasyCastContractsRequest): Promise<Response<ListedResponse<EasyCastContract>>> {
        return <Response>{};
    }

    @POST(`/${base}`)
    @Headers({...ACCEPT_JSON})
    @ToData(EasyCastContract)
    async newContract(@Body request: SaveEasyCastContractRequest): Promise<Response<EasyCastContract>> {
        return <Response>{};
    }

    @PUT(`/${base}/{contractId}`)
    @Headers({...ACCEPT_JSON})
    @ToData(EasyCastContract)
    async updateContract(@Path('contractId') contractId: number,
                         @Body request: SaveEasyCastContractRequest): Promise<Response<EasyCastContract>> {
        return <Response>{};
    }

    @GET(`/${base}/{contractId}/clientIds`)
    @Headers({...ACCEPT_JSON})
    async getContractClientIds(@Path('contractId') contractId: number): Promise<Response<ReflectorClientIdsResponse>> {
        return <Response>{};
    }

    @PATCH(`/${base}/{contractId}/paid`)
    @Headers({...ACCEPT_JSON})
    @ToData(EasyCastContract)
    async setContractPaid(@Path('contractId') contractId: number,
                          @Body request: PayEasyCastContractRequest): Promise<Response<EasyCastContract>> {
        return <Response>{};
    }

    @GET(`/${base}/{contractId}`)
    @Headers({...ACCEPT_JSON})
    @ToData(EasyCastContract)
    async getContract(@Path('contractId') contractId: number): Promise<Response<EasyCastContract>> {
        return <Response>{};
    }

    @PATCH(`/${base}/{contractId}/sent`)
    @Headers({...ACCEPT_JSON})
    @ToData(EasyCastContract)
    async sendContract(@Path('contractId') contractId: number): Promise<Response<EasyCastContract>> {
        return <Response>{};
    }

    @PATCH(`/${base}/{contractId}/confirmed`)
    @Headers({...ACCEPT_JSON})
    @ToData(EasyCastContract)
    async confirmContract(@Path('contractId') contractId: number): Promise<Response<EasyCastContract>> {
        return <Response>{};
    }

    @PATCH(`/${base}/{contractId}/configured`)
    @Headers({...ACCEPT_JSON})
    @ToData(EasyCastContract)
    async configureContract(@Path('contractId') contractId: number): Promise<Response<EasyCastContract>> {
        return <Response>{};
    }

    @POST(`/${base}/{contractId}/configureSeasonalDates`)
    @Headers({...ACCEPT_JSON})
    @ToData(EasyCastContract)
    async setupSeasonalDates(@Path('contractId') contractId: number,
                             @Body request: ConfigureEasyCastContractRequest): Promise<Response<EasyCastContract>> {
        return <Response>{};
    }

    @DELETE(`/${base}/{contractId}`)
    @Headers({...ACCEPT_JSON})
    @ToData(EasyCastContract)
    async deleteContract(@Path('contractId') contractId: number): Promise<Response<EasyCastContract>> {
        return <Response>{};
    }

    @POST(`/${base}/{contractId}/configurePlaybackDevices`)
    @Headers({...ACCEPT_JSON})
    async configurePlaybackDevices(@Path('contractId') contractId: number, @Body request: PlaybackDevice[]): Promise<Response> {
        return <Response>{};
    }

    @GET(`/${base}/{contractId}/playbackDevices`)
    @Headers({...ACCEPT_JSON})
    @ToData(PlaybackDevice)
    async getPlaybackDevices(@Path('contractId') contractId: number):  Promise<Response<PlaybackDevice[]>> {
        return <Response>{};
    }

    @POST(`/${base}/{contractId}/configureGmailAccount`)
    @Headers({...ACCEPT_JSON})
    async addGmailAccount(@Path('contractId') contractId: number, @Body request: EasyCastGmailAccountConfiguration): Promise<Response> {
        return <Response>{};
    }

    @POST(`/${base}/{contractId}/shipped`)
    @Headers({...ACCEPT_JSON})
    @ToData(EasyCastContract)
    async saveShippingDetails(@Path('contractId') contractId: number,
                              @Body request: SaveEasyCastShippingDetailsRequest): Promise<Response<EasyCastContract>> {
        return <Response>{};
    }

    @POST(`/${base}/{contractId}/delivered`)
    @Headers({...ACCEPT_JSON})
    @ToData(EasyCastContract)
    async saveDeliveringDetails(@Path('contractId') contractId: number,
                                @Body request: BodyRequest<Date>): Promise<Response<EasyCastContract>> {
        return <Response>{};
    }

}
