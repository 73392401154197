import {PlaybackDevice} from 'src/app/models/easy-cast/easy-cast-playback-device';
import {BaseService, Body, GET, Headers, Path, POST, Response} from 'ts-retrofit';
import {ACCEPT_JSON} from '../../config';
import {ToData} from '../../rest-api-hydratator-decorators';

export const base = '/easycast/easycastPlaybackDevices';

export class EasyCastPlaybackDevicesApiService extends BaseService {
    @GET(`${base}/mac/{mac}`)
    @Headers({...ACCEPT_JSON})
    @ToData(PlaybackDevice)
    async getByMac(@Path('mac') mac: string): Promise<Response<PlaybackDevice>> {
        return <Response>{};
    }

    @GET(`${base}/byReflectorId/{reflectorId}`)
    @Headers({...ACCEPT_JSON})
    @ToData(PlaybackDevice)
    async getByReflectorId(@Path('reflectorId') reflectorId: number): Promise<Response<PlaybackDevice[]>> {
        return <Response>{};
    }

    @POST(`${base}/addPlaybackDevices`)
    @Headers({...ACCEPT_JSON})
    @ToData(PlaybackDevice)
    async addPlaybackDevices(@Body playbackDevices: PlaybackDevice[]): Promise<Response<PlaybackDevice[]>> {
        return <Response>{};
    }

    @POST(`${base}/{playbackDeviceId}/creditPack`)
    @Headers({...ACCEPT_JSON})
    @ToData(PlaybackDevice)
    async addCreditPack(
        @Path('playbackDeviceId') playbackDeviceId: number,
        @Body data: {creditAmount: number; licenseId: number},
    ): Promise<Response<PlaybackDevice>> {
        return <Response>{};
    }
}
