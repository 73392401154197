export class AddressData {
    city: string;
    istatCode: string;
    province: string;
    zipCode: string;
    country: string;
    street: string;
    civicNumber: string;
    stairs: string;
    topographicParticle: string;
}
