import {TicketType} from '../../responses/ticket-type';
import {Type} from 'class-transformer';
import {TicketClosure} from '../ticket-closure';
import {ClientTicketMessage} from './client-ticket-message';
import {ClientTicketState, TicketState} from '../../enums/ticket-states';
import {Contract} from '../../contract';

export class ClientTicket {
    id: number;
    idGroup?: number;
    titolo: string;
    stato: ClientTicketState;
    created: Date;
    apertoDa: string;
    login: string;
    clientUsername: string;
    type: TicketType;
    chiave: string;
    unread: boolean;
    assegnato: string;
    isAdministrative: boolean;

    @Type(() => Date)
    ts?: Date;
    isRo: number;

    @Type(() => TicketClosure)
    ticketClosure: TicketClosure;

    @Type(() => Contract)
    contract: Contract;

    @Type(() => ClientTicketMessage)
    messages: ClientTicketMessage[];
    get isNew() {
     return this.id === 0;
    }

    getStateItem(): TicketState {
        return TicketState.parse(this.stato);
    }
}
