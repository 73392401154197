import {Type} from 'class-transformer';
import {Stringifier} from '../services/stringifier.service';

@Stringifier
export class Agent {

    idAgenteZucchetti: string;
    bossAgentId?: string;

    @Type(() => Agent)
    bossAgent?: Agent;

    descrizione: string;

    indirizzo: string;

    cap: string;

    citta: string;

    provincia: string;

    cf: string;

    telefax: string;

    telefono: string;

    email: string;

    codiceEnasarco: string;

    @Type(() => Date)
    InizioMandato?: Date;

    @Type(() => Date)
    FineMandato?: Date;

    @Type(() => Date)
    DataObsoleto?: Date;

    static getStringifier(level: number) {
        if (level < 0) {
            return null;
        }
        return {
            idAgenteZucchetti: null,
            bossAgentId: null,
            bossAgent: Agent.getStringifier(level - 1),
            descrizione: null,
            indirizzo: null,
            cap: null,
            citta: null,
            provincia: null,
            cf: null,
            telefax: null,
            telefono: null,
            email: null,
            codiceEnasarco: null,
            InizioMandato: null,
            FineMandato: null,
            DataObsoleto: null,
        } as Agent;
    }
}
