import {Type} from 'class-transformer';
import {A, IA} from './a';

// tslint:disable-next-line:no-empty-interface
export interface IB extends B {}
export class B {
    @Type(() => A)
    a: IA;

    name: string;

    @Type(() => Date)
    data: Date;

    getName() {
        return 'Nome of B: ' + this.name;
    }
}
