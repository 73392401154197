import {Type} from 'class-transformer';
import {ICloneable} from '../interfaces/icloneable';
import {TicketGroup} from './ticket-group';

export class TicketAlarm implements ICloneable {
    id?: number;
    idGroup: number;

    @Type(() => Date)
    expiringDate: Date;
    message: string;
    userCode: string;
    /**
     * Silenziato
     */
    quiet: boolean;
    /**
     * Allarme generato dal sistema; readonly per gli utenti
     */
    final: boolean;

    get expired(): boolean {
        return this.expiringDate.getTime() < Date.now();
    }

    clone(other?: TicketAlarm): TicketAlarm {
        if (other == null) {
            other = this;
        }
        return Object.assign(new TicketAlarm(), other);
    }
}
