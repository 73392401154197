import {LicenseFeature, LicenseType} from '../../easy-cast/easy-cast-license';

export class SaveEasyCastContractRequest {

    clientBusinessName: string;
    fiscalCode: string;
    vatNumber: string;
    zipCode: string;
    address: string;
    city: string;
    province: string;
    country: string;
    clientEmail: string;

    expiresAt: Date;
    licenseFeature: LicenseFeature;
    licenseType: LicenseType;
    licenseEnterprise: boolean;
    credits: number;
    quantityOfPlaybackDevices: number;
    contractType: EasyCastContractType;
    seasonDays: number;
}


export enum EasyCastContractType {
    Unknown = 'Unknown',
    Rental = 'Rental',
    Purchase = 'Purchase'
}
