import {Type} from 'class-transformer';

export interface IGenericType {
    genericType?: Function;
}

export class GenericType implements IGenericType {
    genericType?: Function;
}


export const TypeGeneric = () => {
    return Type(options => {
        return (options.newObject as IGenericType).genericType;
    });
};

// @ts-ignore
// tslint:disable-next-line:no-empty-interface
// export interface IWrapper<T extends {}> extends T {}
