import {PartialRouting} from '../partial-routing';

export class RoutingEasyCastPlaybackDevices extends PartialRouting {
    constructor(base: string = '') {
        super(base);
    }

    addDevices() {
        return `${this.base}add-devices`;
    }

    configDevices() {
        return `${this.base}config-devices`;
    }
}
