import {Component, OnInit} from '@angular/core';
import {RestApiService} from '../../../services/api';
import {DialogService, DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {ClientReminder} from '../../../models/clients/client-reminder';
import {ConfirmationService} from 'primeng/api';
import {AclPermission} from '../../../models/acl/acl-group';
import {SessionStorageService} from '../../../services/session-storage.service';

@Component({
    selector: 'app-user-settings',
    templateUrl: './user-settings.component.html',
    styleUrls: ['./user-settings.component.scss']
})
export class UserSettingsComponent implements OnInit {
    loading = false;
    twoFactorQrCodeUrl = null;
    twoFactorTestCode = '';
    showQrCode = false;
    aclPermission = AclPermission;

    constructor(private api: RestApiService,
                private confirmationService: ConfirmationService,
                private sessionStorageService: SessionStorageService,
                private dynamicDialogRef: DynamicDialogRef, private config: DynamicDialogConfig) {
    }

    static openDialog(dialogService: DialogService): DynamicDialogRef {
        return dialogService.open(UserSettingsComponent, {
            width: '80%',
            closable: true,
            closeOnEscape: true,
            header: 'Impostazioni utente',
            modal: true,
            showHeader: true,
        });
    }

    ngOnInit(): void {
        this.loadData();
    }

    async loadData() {
        try {
            this.loading = true;
            await this.loadTwoFactorQrCode();
        } finally {
            this.loading = false;
        }
    }

    private async loadTwoFactorQrCode() {
        if (this.sessionStorageService.hasPermission(AclPermission.TwoFactorsAuthentication)) {
            const response = await this.api.authentication.getTwoFactorQrCodeUrl();
            this.twoFactorQrCodeUrl = response.data;
        }
    }

    async testTwoFactor(code: string) {
        let result = false;
        try {
            await this.api.authentication.authenticateTwoFactor({data: code});
            result = true;
        } catch (exception) {
            result = false;
        }
        this.confirmationService.confirm({
            header: 'Risultato',
            message: result ? 'Codice corretto' : 'Codice ERRATO',
            acceptLabel: 'Ok',
            rejectVisible: false,
            icon: result ? 'fas fa-circle-check' : 'fas fa-circle-xmark'
        });
    }


}
