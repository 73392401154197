import {Contract} from '../contract';
import {Type} from 'class-transformer';

export class DynamicIp {
    id: number;
    contractId?: number;
    @Type(() => Contract)
    contract: Contract;
    hostname: string;
    ip: string;
    @Type(() => Date)
    timestamp: Date;
}
