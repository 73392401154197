import {Type} from 'class-transformer';

export class InvoiceXmlContentResponse {
    invoiceId: number;
    number: number;
    year: number;
    series: string;
    @Type(() => Date)
    date: Date;
    xmlContent: string;
    filePath: string;
}
