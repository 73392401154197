import {Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';
import {AclPermission} from '../models/acl/acl-group';
import {SessionStorageService} from '../services/session-storage.service';

@Directive({
    selector: '[appAcl]'
})
export class AclRequiredDirective {
    private hasView = false;

    @Input() set appAcl(acl: AclPermission | AclPermission[]) {
        if (!this.hasView && this.testAcl(acl)) {
            this.viewContainer.createEmbeddedView(this.templateRef);
            this.hasView = true;
        } else if (this.hasView && !this.testAcl(acl)) {
            this.viewContainer.clear();
            this.hasView = false;
        }
    }

    constructor(private templateRef: TemplateRef<any>,
                private viewContainer: ViewContainerRef,
                private sessionStorageService: SessionStorageService) {
    }

    private testAcl(acl: AclPermission | AclPermission[]): boolean {
        return this.sessionStorageService.hasPermission(acl);
    }

}
