<div class="p-card m-3 p-3">
    <div class="p-card-header">
        <h3>Errore</h3>
    </div>
    <div class="p-card-body">
        <p-message severity="error" text="Non disponi dei permessi sufficenti per accedere alla risorsa"></p-message>
        <br><br>
        <p-button (click)="goToHome()">
            <i class="fas fa-home pr-1"></i> Vai alla home
        </p-button>
    </div>
</div>
