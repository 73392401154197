import {TicketGroup} from '../tickets/ticket-group';
import {Type} from 'class-transformer';
import {TicketLog} from '../tickets/ticket-log';
import {ClientTelephoneDescriptor} from '../clients/client-telephone-descriptor';
import {TicketRegister} from '../tickets/ticket-register';
import {UniformedTicketMessage, UniformedTicketMessageType} from '../tickets/view-models/uniformed-ticket-message';

export class TicketGroupForm {
    private _uniformedMessages: UniformedTicketMessage[] = null;

    @Type(() => TicketGroup)
    ticketGroup: TicketGroup;

    @Type(() => TicketLog)
    logs: TicketLog[];

    @Type(() => ClientTelephoneDescriptor)
    telephones: ClientTelephoneDescriptor[];

    /**
     * Registro delle visualizzazioni del ticket group
     */
    @Type(() => TicketRegister)
    ticketRegisters: TicketRegister[];

    private _cachedUniqueTelephones = new Map<string, ClientTelephoneDescriptor[]>();

    /**
     * calcola i numeri di telefono unici
     * @param loginName
     */
    getUniqueTelephonesByLoginName(loginName: string): ClientTelephoneDescriptor[] {
        const list: ClientTelephoneDescriptor[] = [];

        if (!!this._cachedUniqueTelephones[loginName]) {
            // se presente nella cache restituisco quello
            // garantisce che la lista originale (in cache) non venga mai modificata
            return [...this._cachedUniqueTelephones[loginName]];
        } else {

            // non l'ho trovato nella cache: calcolo la lista
            for (const phone of this.telephones
                // 1. filtra per login (Associata al client ticket selezionato)
                .filter(x => x.loginNameUsedByTechnician == null || x.loginNameUsedByTechnician === loginName)) {

                // 2. effettua il merge dei numeri di telefono doppi unendo le descrizioni
                const found = list.find(x => x.phoneNumber === phone.phoneNumber);
                if (!found) {
                    const cloned = phone.clone();
                    cloned.description = cloned.phoneNumber + ' ' + cloned.description;
                    list.push(cloned);
                } else {
                    found.description += ', ' + phone.description;
                }
                this._cachedUniqueTelephones[loginName] = list;
            }
        }

        // garantisce che la lista originale (in cache) non venga mai modificata
        return [...list];
    }


    getUniformedMessages(force: boolean = false): UniformedTicketMessage[] {
        if (this._uniformedMessages && !force) {
            return this._uniformedMessages;
        }

        this._uniformedMessages = [];

        if (this.ticketGroup.providerTickets) {
            for (const providerTicket of this.ticketGroup.providerTickets) {
                for (const message of providerTicket.messages) {
                    this._uniformedMessages.push(new UniformedTicketMessage(message, providerTicket));
                }
            }
        }

        if (this.ticketGroup.clientTickets) {
            for (const clientTicket of this.ticketGroup.clientTickets) {
                for (const message of clientTicket.messages) {
                    this._uniformedMessages.push(new UniformedTicketMessage(message));
                }
            }
        }

        if (this.logs) {
            for (const log of this.logs) {
                this._uniformedMessages.push(new UniformedTicketMessage(log));
            }
        }

        if (this.ticketRegisters) {
            for (const viewRegister of this.ticketRegisters) {
                this._uniformedMessages.push(new UniformedTicketMessage(viewRegister));
            }
        }

        if (this.ticketGroup.chats) {
            for (const chat of this.ticketGroup.chats) {
                this._uniformedMessages.push(new UniformedTicketMessage(chat));
            }
        }

        if (this.ticketGroup.assignments) {
            for (const assignment of this.ticketGroup.assignments) {
                this._uniformedMessages.push(new UniformedTicketMessage(assignment));
            }
        }

        this._uniformedMessages = this._uniformedMessages.sort((a, b) => b.sentOn.getTime() - a.sentOn.getTime());

        this.populateTicketCodeHeaderForMessages(this._uniformedMessages.filter(x => x.type === UniformedTicketMessageType.Client));
        this.populateTicketCodeHeaderForMessages(this._uniformedMessages.filter(x => x.type === UniformedTicketMessageType.Provider));
        return this._uniformedMessages;
    }

    /**
     * @param preFilteredMessages lista dei messaggi. Devono venire passati gia` prefiltrati per tipologia (es. client o provider)
     */
    private populateTicketCodeHeaderForMessages(preFilteredMessages: UniformedTicketMessage[]) {
        let lastTicketCode = null;
        for (const message of preFilteredMessages) {
            if (lastTicketCode !== message.ticketCode) {
                message.showTicketCodeHeader = true;
                lastTicketCode = message.ticketCode;
            }
        }
    }
}
