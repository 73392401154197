import {BaseService, Body, GET, Headers, PATCH, Path, Response} from 'ts-retrofit';
import {ACCEPT_JSON} from '../config';
import {NotificationMessage} from '../../../models/notification-message';
import {BodyRequest} from '../../../models/body-request';


export class NotificationsService extends BaseService {

    @GET('/notifications')
    @Headers({...ACCEPT_JSON})
    async getNotifications(): Promise<Response<NotificationMessage[]>> {
        return <Response> {};
    }

    @PATCH('/notifications/{id}/read')
    @Headers({...ACCEPT_JSON})
    async readNotification(@Path('id') notificationId: number, @Body read: BodyRequest<boolean>): Promise<Response> {
        return <Response> {};
    }

    @PATCH('/notifications/read')
    @Headers({...ACCEPT_JSON})
    async readAllNotification(): Promise<Response> {
        return <Response> {};
    }

    @PATCH('/notifications/{id}/scheduledAt')
    @Headers({...ACCEPT_JSON})
    async postponeNotification(@Path('id') notificationId: number, @Body read: BodyRequest<Date>): Promise<Response> {
        return <Response> {};
    }
}
