export const RoutingConstants = {
    dashboard: 'dashboard',
    playground: 'playground',
    flagTemplates: 'flag-templates',
    userSettings: 'user-settings',
    queueJobs: 'queue-jobs',
    dynamicIpHistory: 'dynamicIps',
    resellerAgents: 'resellerAgents',
    tickets: 'tickets',
    invoices: 'invoices',
    acl: 'acl',
    notAuthorized: 'unauth',
    remoteDevices: 'remoteDevices',
    mftweb: 'mftweb',
    coverageDetails: 'coverage-details',
    clients: 'clients',
    contracts: 'contracts',
    contractParameters: 'contract-parameters',
    devicesMap: 'devices-map',
    easyCast: 'easycast',
    easyCastUsers: 'users',
    easyCastLicenses: 'licenses',
    easyCastContracts: 'contracts',
    easyCastPlaybackDevices: 'playback-devices',
    params: {
        id: ':id',
        idGroup: ':idGroup',
        clientId: ':clientId',
        deviceId: ':deviceId',
        contractId: ':contractId',
        loginName: 'loginName',
        newValue: 'new',
        parentId: 'parentId',
        tickets: {
            clientRagioneSociale: 'client.ragioneSociale',
            contractLoginName: 'contract.loginName',
            clientId: 'client.id',
            searchText: 'searchText',
        },
        remoteDevices: {
            deviceId: 'deviceId',
            clientRagioneSociale: 'client.ragioneSociale',
            openDetails: 'openDeviceDetails' // forza l'apertura dei dettagli di un device se il parametro deviceId è anch'esso passato
        },
        easycast: {
            userId: 'userId',
        },
        mftwebUrl: 'url',
        deviceMapId: ':deviceMapId',
        clientMapId: ':clientMapId',
    },
    twoFactorsAuthentication: 'twoFactorsAuthentication',
    fastDialNumbers: 'fastDialNumbers'
};
