import {Type} from 'class-transformer';
import {ClientTicketMessageReadStatus} from './client-ticket-message-read-status';
import {ClientTicketAttachment} from './clientTicketAttachment';

export class ClientTicketMessage {
    id: number;
    idTicket?: number;

    // @Type(() => ClientTicket)
    // ticket?: ClientTicket;

    interno?: number;
    mittente: string;

    @Type(() => Date)
    sent?: Date;

    isSms?: number;
    dest: string;
    testo: string;

    @Type(() => ClientTicketMessageReadStatus)
    readStatus: ClientTicketMessageReadStatus;

    @Type(() => ClientTicketAttachment)
    attachments: ClientTicketAttachment[];
}
