<div class="inline-block"  #notificationButton>
    <button pButton pRipple class="text-center p-button-text mr-3"
            [loading]="isLoading"
            pTooltip="Notifiche" tooltipPosition="bottom" (click)="notificationsPanel.toggle($event)">
        <i class="fa fa-bell" pBadge severity="danger" [value]="unreadNotifications.length.toString()"></i>
    </button>
</div>
<p-overlayPanel #notificationsPanel styleClass="notifications-list" [appendTo]="notificationButton">
    <ng-template pTemplate>
            <app-notifications-list
                [notifications]="unreadNotifications"
                (readAll)="loadNotifications()"
            ></app-notifications-list>
    </ng-template>
</p-overlayPanel>

