import {Injectable} from '@angular/core';
import {MessageService} from 'primeng/api';

export type ToastPosition = 'top-center' | 'top-right' | 'top-left' | 'bottom-right' | 'bottom-left' | 'bottom-center' | 'center';
export type ToastSeverity = 'success' | 'info' | 'error' | 'warn';
@Injectable({
    providedIn: 'root'
})
export class ToastService {

    public static readonly time = 5000;

    constructor(private messageService: MessageService) {
    }

    info(title: string, message: string, position?: ToastPosition) {
        this.message(title, message, 'info', position);
    }

    success(title: string, message: string, position?: ToastPosition) {
        this.message(title, message, 'success', position);
    }

    warning(title: string, message: string, position?: ToastPosition) {
        this.message(title, message, 'warn', position);
    }

    error(title: string, message: string, position?: ToastPosition) {
        this.message(title, message, 'error', position);
    }

    message(title: string, message: string, type: ToastSeverity,
                    position: ToastPosition = 'top-center', time: number = ToastService.time) {
        this.messageService.add({
            key: position,
            severity: type,
            summary: title,
            detail: message,
            life: time
        });
    }

    clear() {
        this.messageService.clear();
    }
}
