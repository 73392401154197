import {ClientTicketMessage} from '../client/client-ticket-message';
import {ProviderTicket, ProviderTicketAttachment, ProviderTicketMessage} from '../provider/provider-ticket';
import {TicketLog} from '../ticket-log';
import {TicketAssignment} from '../ticket-assignment';
import {TicketGroupSide} from '../ticket-group-side';
import {TicketChat} from '../ticket-chat';
import {TicketRegister} from '../ticket-register';
import {ClientTicketAttachment} from '../client/clientTicketAttachment';

export class UniformedTicketMessage {
    /** id del messaggio */
    id: number;
    /** codice del messaggio. Può essere eguale ad id oppure no. */
    messageCode: string;
    /** Specifica in quale delle 4 colonne il messaggio deve venire visualizzato */
    type: UniformedTicketMessageType;
    text: string;
    /** data di creazione del messaggio */
    createdOn: Date;
    /** data di invio del messaggio */
    sentOn: Date;

    /** Usato solo nella GUI. Allineamento del messaggio a livello visivo */
    align: UniformedTicketMessageAlign;
    /** Usato solo nella GUI. Indica se il messaggio attuale deve mostrare l'intestazione del Ticket */
    showTicketCodeHeader = false;

    isSms = false;
    isEmail = false;
    isDeletedMessageLog = false;

    // TODO: fare una enum al posto di booleani
    /** Usato solo per identificare i log di tipo "assegnamento": quando un ticket viene assegnato da un utente ad un altro */
    isAssignment = false;
    /** Usato solo per identificare i log di tipo "chat": un messaggio interno */
    isChat = false;
    /** Usato solo per identificare i log di tipo "flag": creazione o modifica di flag del ticketgroup */
    isFlag = false;
    /** Il messaggio richiede una notifica. Usato dai tipi "chat" */
    isRequiringNotification = false;
    /** mittente, chi ha scritto il messaggio */
    writer: string;
    /** Indirizzo email del destinatario che ha ricevuto la notifica del messaggio */
    sentMailTo: string;
    /** nome dell'utente che ha letto la notifica */
    readBy: string;
    /** utente destinatario della notifica */
    targetUserCode: string;
    /** data di lettura della notifica */
    readOnDate: Date;
    /** Indirizzo ip di chi ha letto la notifica */
    readIp: string;
    /** Codice del ticket. Inero per il lato cliente, stringa per il lato provider */
    ticketCode: string | number;
    provider: string;
    /**
     * indica se il ticket è interno (MFT) oppure si necessita di vedere il provider associato al Contratto (login) associato
     */
    internalProvider: boolean;
    side: TicketGroupSide;

    /** Lista degli allegati di un messaggio (solo per i messaggi dei ticket lato cliente) **/
    attachments: UniformedTicketAttachment[] = [];

    constructor(ticketOrLog: ClientTicketMessage | ProviderTicketMessage | TicketLog | TicketAssignment | TicketChat | TicketRegister,
                providerTicket: ProviderTicket | null = null) {
        if (ticketOrLog instanceof ClientTicketMessage) {
            this.fromClientTicketMessage(<ClientTicketMessage>ticketOrLog);
        } else if (ticketOrLog instanceof ProviderTicketMessage) {
            this.fromProviderTicketMessage(ticketOrLog, providerTicket);
        } else if (ticketOrLog instanceof TicketLog) {
            this.fromTicketLog(ticketOrLog);
        } else if (ticketOrLog instanceof TicketAssignment) {
            this.fromTicketAssignment(ticketOrLog);
        } else if (ticketOrLog instanceof TicketChat) {
            this.fromTicketChat(ticketOrLog);
        } else if (ticketOrLog instanceof TicketRegister) {
            this.fromTicketRegisterViewLog(ticketOrLog);
        }
    }

    private fromClientTicketMessage(message: ClientTicketMessage) {
        this.id = message.id;
        this.messageCode = message.id.toString();
        this.sentOn = this.createdOn = message.sent;
        this.text = message.testo;
        this.type = UniformedTicketMessageType.Client;
        this.align = !!message.interno ? UniformedTicketMessageAlign.Right : UniformedTicketMessageAlign.Left;
        this.writer = message.mittente;
        this.isSms = !!message.isSms;
        this.isAssignment = false;
        this.sentMailTo = message.dest;
        this.provider = null;
        this.ticketCode = message.idTicket;
        this.side = TicketGroupSide.Client;
        this.attachments = message.attachments?.map(x => new UniformedTicketAttachment(x)) ?? [];

        // informazioni su chi ha letto il messaggio
        this.readIp = message.readStatus?.ip;
        this.readOnDate = message.readStatus?.ts;
        this.readBy = message.readStatus?.nome;
        this.isChat = false;
    }

    private fromProviderTicketMessage(message: ProviderTicketMessage, providerTicket: ProviderTicket) {
        this.id = message.uid;
        this.messageCode = message.codice;
        this.createdOn = message.ts;
        this.sentOn = message.sent ?? message.ts;
        this.text = message.testo;
        this.type = UniformedTicketMessageType.Provider;
        this.align = UniformedTicketMessageAlign.Unknown; // demanda il calolo dell'allineamento al componente che renderizza il messaggio
        this.writer = message.mittente;

        this.ticketCode = message.idNgi;
        this.provider = providerTicket.contract?.provider ?? 'Unknown';
        this.internalProvider = providerTicket.internalProvider;
        this.side = TicketGroupSide.Provider;

        this.isSms = false; // non vengono mai inviati sms verso provider
        this.isAssignment = false;
        this.isChat = false;
        this.sentMailTo = null; // non vengono inviate email da parte di MFT
        // nessuna info sul lettore
        this.readBy = this.readIp = this.readOnDate = null;
        this.attachments = message.attachments?.map(x => new UniformedTicketAttachment(x)) ?? [];
    }

    private fromTicketLog(log: TicketLog) {
        this.id = log.ticketLogId;
        this.messageCode = log.ticketLogId.toString();
        this.text = log.description;
        this.sentOn = log.timeStamp;
        this.type = UniformedTicketMessageType.InternalLog;
        this.align = UniformedTicketMessageAlign.Unknown;
        this.writer = log.userCode;
        this.align = UniformedTicketMessageAlign.FullWidth;
        this.side = TicketGroupSide.Unknown;

        // TODO: modificare quando i log avranno una enum che distingua le categorie
        this.isFlag = log.description.includes('Flag');
        this.isSms = log.description.includes('Inviato SMS a numero');
        this.isDeletedMessageLog = /Messaggio eliminato \[\d+\]\. Motivazione: /.test(log.description);
        this.isEmail = log.description.includes('Inviata Email ad indirizzo');

        this.isChat = false;
        this.isAssignment = false;
        this.sentMailTo = null;
        this.readBy = null;
        this.attachments = [];
    }

    private fromTicketRegisterViewLog(log: TicketRegister) {
        this.id = log.id;
        this.messageCode = log.id.toString();
        this.text = '';
        this.sentOn = log.ts;
        this.type = UniformedTicketMessageType.ViewRegisterLog;
        this.align = UniformedTicketMessageAlign.FullWidth;
        this.writer = log.user;
        this.align = UniformedTicketMessageAlign.FullWidth;
        this.side = TicketGroupSide.Unknown;

        // TODO: modificare quando i log avranno una enum che distingua le categorie
        this.isFlag = false;
        this.isSms = false;
        this.isEmail = false;
        this.isChat = false;
        this.isAssignment = false;
        this.sentMailTo = null;
        this.readBy = null;
        this.attachments = [];
    }

    private fromTicketAssignment(log: TicketAssignment) {
        this.id = log.id;
        this.messageCode = log.id.toString();
        this.text = log.message;
        this.sentOn = log.ts;
        this.type = UniformedTicketMessageType.InternalLog;
        this.align = UniformedTicketMessageAlign.Unknown;
        this.writer = log.user;
        this.align = UniformedTicketMessageAlign.FullWidth;
        this.side = log.ticketGroupSide;
        this.targetUserCode = log.toUser;

        this.isSms = false;
        this.isChat = false;
        this.isAssignment = true;
        this.sentMailTo = null;
        this.readBy = null;
        this.attachments = [];
    }

    private fromTicketChat(log: TicketChat) {
        this.id = log.id;
        this.messageCode = log.id.toString();
        this.text = log.msg;
        this.sentOn = log.ts;
        this.type = UniformedTicketMessageType.InternalLog;
        this.writer = log.user;
        this.align = UniformedTicketMessageAlign.FullWidth;
        this.side = TicketGroupSide.Unknown;
        this.targetUserCode = log.user;
        this.isRequiringNotification = log.notif === 1;

        this.isSms = false;
        this.isChat = true;
        this.isAssignment = false;
        this.sentMailTo = null;
        this.readBy = null;
        this.attachments = [];
    }

}

export class UniformedTicketAttachment {
    id: number;
    messageId: number;
    fileName: string;
    side: TicketGroupSide;

    constructor(attachment: ProviderTicketAttachment | ClientTicketAttachment) {
        if (attachment instanceof ProviderTicketAttachment) {
            this.id = attachment.providerTicketAttachmentId;
            this.messageId = attachment.messageId;
            this.fileName = attachment.originalFilename;
            this.side = TicketGroupSide.Provider;
            return;
        }
        if (attachment instanceof ClientTicketAttachment) {
            this.id = attachment.id;
            this.messageId = attachment.idMsg;
            this.fileName = attachment.fileName;
            this.side = TicketGroupSide.Client;
            return;
        }
    }
}

export enum UniformedTicketMessageType {
    Client = 1,
    InternalLog = 2,
    ViewRegisterLog = 3,
    Provider = 4,
}

export enum UniformedTicketMessageAlign {
    Unknown = 1,
    Left = 2,
    Right = 3,
    FullWidth = 4,
}
