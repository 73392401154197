import {GenericType, TypeGeneric} from './generic-type';

export class ListedResponse<T> extends GenericType {
    @TypeGeneric()
    dataList: T[];

    count: number;

    /**
     * Opzionale, utilizzato per la gestione di richieste/risposte asincrone
     */
    sequentialRequestId?: number;
}
