import {PartialRouting} from '../partial-routing';

export class RoutingAcl extends PartialRouting {
    constructor(base: string = '') {
        super(base);
    }

    list() {
        return this.base;
    }

}
