<div class="w-8 m-auto p-5">
    <p-panel>
        <ng-template pTemplate="header">
            <div class="flex justify-content-between" style="width: 100%">
                <h3>Numeri telefonici fornitori</h3>
                <div class="inline-block flex align-items-center">
                    <span class="mr-3"><p-badge [value]="numbers.length.toString() + ' numeri fornitori'"></p-badge></span>
                    <app-action-button type="add" (click)="add()" label="Aggiungi numero fornitore"></app-action-button>
                </div>
            </div>
        </ng-template>

        <p-table #numbersTable [value]="numbers" >
            <ng-template pTemplate="header">
                <tr>
                    <th>Nome fornitore</th>
                    <th>Numero telefonico</th>
                    <th>Azioni</th>
                </tr>
            </ng-template>

            <ng-template pTemplate="body" let-item let-rowIndex="rowIndex">
                <tr [pEditableRow]="item">
                    <td>
                        <app-input [(model)]="obj(item).name"
                                   [showLength]="true"
                                   maxLength="100"
                        ></app-input>
                    </td>
                    <td>
                        <app-input [(model)]="obj(item).telephone"
                                   [showLength]="true"
                                   maxLength="20"
                        ></app-input>
                    </td>
                    <td>
                        <app-action-button type="save" class="mr-3" pSaveEditableRow
                                           [disabled]="!validateNumber(item)"
                                           (click)="save(item, rowIndex)"
                                           pTooltip="Salva il numero rapido"
                        ></app-action-button>
                        <app-action-button type="delete" (click)="delete(item, rowIndex)"
                                           pTooltip="Elimina il numero rapido"
                        ></app-action-button>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </p-panel>
</div>
