import {BaseService, GET, Headers, Response, ResponseTransformer} from 'ts-retrofit';
import {ACCEPT_JSON, goodResponseState, serviceFactory} from '../config';
import {User} from '../../../models/user';
import {UserGroup} from '../../../models/user-group';
import {ToData} from '../rest-api-hydratator-decorators';

const base = 'groups';


export class CachedGroupsApiService {
    private api: GroupsApiService = serviceFactory(GroupsApiService);
    private allGroupsCached: UserGroup[] = [];

    async getAll(): Promise<Response<UserGroup[]>> {
        await this.reload();
        return goodResponseState(this.allGroupsCached);
    }

    async getByGroupCode(code: string): Promise<Response<UserGroup>> {
        await this.reload();
        return goodResponseState(this.allGroupsCached.find(group => group.groupCodice === code));
    }

    clearCache() {
        this.allGroupsCached = [];
    }

    notifyDirtyCache() {
        // TODO: deve svuotare la cache anche di altri browser tramite SignalR
    }

    private async reload(forceReload = false) {
        if (forceReload || this.allGroupsCached.length === 0) {
            const response = await this.api.getAll();
            this.allGroupsCached = response.data;
        }
    }
}

class GroupsApiService extends BaseService {

    @GET(`/${base}`)
    @Headers({...ACCEPT_JSON})
    @ToData(UserGroup)
    async getAll(): Promise<Response<UserGroup[]>> {
        return <Response>{};
    }

}

function toList(data: string): UserGroup[] {
    const result = [];
    const plainUsers = JSON.parse(data) as UserGroup[];
    for (const plainUser of plainUsers) {
        result.push(instantiate(plainUser));
    }
    return result;
}

function instantiate(data: UserGroup): UserGroup {
    return Object.assign(new UserGroup, data);
}
