export enum Provider {
    EOLO = 'EOLO',
    EOLOWH = 'EOLOWH',
    AN = 'AN',
    MYWIC = 'WIC',
    UANIA = 'UANIA',
    MFT = 'MFT',
    OPNET = 'OPNET',
    EFAX = 'EFAX',
    INTRED = 'INTRED',
    STARLINK = 'STARLINK',
    NEXIS = 'NEXIS',
    EasyCast = 'EasyCast'
}
