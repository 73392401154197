import {Directive, ElementRef, EventEmitter, HostListener, Input, Optional, Output} from '@angular/core';
import {ToastService} from '../services/toast.service';

@Directive({
    selector: '[appCopyable]'
})
export class CopyableDirective {
    // tslint:disable-next-line:no-input-rename
    @Input('appCopyableValue')
    copyToClipboard?: string;

    // tslint:disable-next-line:no-input-rename
    @Input('appCopyableShowToast')
    showToast = true;

    get payload(): string {
        if (this.copyToClipboard && this.copyToClipboard.trim().length > 0) {
            return this.copyToClipboard;
        }
        return this.elementRef.nativeElement.innerText;
    }

    @Output()
    readonly copied: EventEmitter<string>
        = new EventEmitter<string>();

    constructor(private elementRef: ElementRef, private toastService: ToastService) {
    }

    @HostListener('click', ['$event'])
    onClick(event: MouseEvent): void {
        event.preventDefault();
        copyToClipboard(
            this.payload,
            this.showToast ? this.toastService : null,
            this.copied ? (value) => {
                this.copied.emit(value);
            } : null
        );
    }
}


export function copyToClipboard(text: string, toastService: ToastService = null, copied: (text: string) => void = null) {
    const listener = (e: ClipboardEvent) => {
        e.clipboardData.setData('text', text);
        e.preventDefault();
        if (toastService) {
            toastService.info('Testo copiato negli appunti:', `"${text}"`, 'bottom-right');
        }
        if (copied) {
            copied(text);
        }
    };

    document.addEventListener('copy', listener, false);
    document.execCommand('copy');
    document.removeEventListener('copy', listener, false);
}
