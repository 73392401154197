import {PartialRouting} from '../partial-routing';

export class RoutingEasyCastContracts extends PartialRouting {
    constructor(base: string = '') {
        super(base);
    }

    list() {
        return this.getBasePath();
    }

    byContractId(id: string | number) {
        return `${this.base}${id}`;
    }

    newContract() {
        return `${this.base}new`;
    }

}
