<button pButton
        icon="fa-solid fa-phone"
        class="p-button-text"
        [class.p-button-danger]="loadingError"
        (click)="fastDialPanel.show($event)"
        [disabled]="loadingError"
        [loading]="loading"
        [pTooltip]="loadingError ? 'Errore durante il caricamento dei numeri fornitore' : null"
></button>

<p-overlayPanel #fastDialPanel
>
    <div style="min-width: 300px">
        <h3>Numeri fornitori</h3>
        <p-table [value]="numbers" [autoLayout]="true" >
            <ng-template pTemplate="body" let-item>
                <tr>
                    <td class="text-left">{{obj(item).name}}</td>
                    <td class="text-right">{{obj(item).telephone}}</td>
                </tr>
            </ng-template>
        </p-table>
        <div *appAcl="AclPermission.EditFastDialNumbers" class="flex justify-content-end mt-5">
            <a [routerLink]="[Routing.fastDialNumbers.getBasePath()]">Modifica numeri fornitori</a>
        </div>
    </div>
</p-overlayPanel>
