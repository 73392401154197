import {Component} from '@angular/core';
import {defaultThemeDescriptions, ThemeDescription} from '../../models/theme-description';
import {UserSettingsService} from '../../services/user-settings.service';

@Component({
    selector: 'app-theme-chooser',
    styleUrls: ['./theme-chooser.component.scss'],
    templateUrl: './theme-chooser.component.html',
})
export class ThemeChooserComponent {

    themes: ThemeDescription[] = defaultThemeDescriptions;

    constructor(private userSettings: UserSettingsService) {
    }

    changeTheme(theme: ThemeDescription) {
        this.userSettings.setTheme(theme.id);
    }

    getTooltip(theme: ThemeDescription) {
        const luminosity = theme.darkTheme ? 'SCURO' : 'CHIARO';
        return `Tema ${luminosity}. Colore ${theme.name}`;
    }

}

