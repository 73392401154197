export class PartialRouting {
    protected base: string;

    constructor(base: string) {
        this.base = base ?? '';
        if (this.base && this.base.trim().length > 0 && !this.base.trim().endsWith('/')) {
            this.base = this.base.trim() + '/';
        }
    }

    getBasePath() {
        return this.base.endsWith('/') ? this.base.substr(0, this.base.length - 1) : '';
    }

}
