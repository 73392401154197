<div class="flex flex-column" #content>

    <h2>
        <i [class]="notification.calendarEvent.icon"></i>
        <span class="ml-2">{{notification.calendarEvent.title}}</span>
    </h2>
    <h4 class="ml-2">
        <i class="fa fa-clock"></i>
        <span class="ml-2">
            {{notification.calendarEvent.expireOn | appDate:'fullDate'}}
            alle
            {{notification.calendarEvent.expireOn | appDate:'shortTime'}}
        </span>
    </h4>
    <h4 class="ml-2">
        Descrizione:
    </h4>
    <textarea pInputTextarea
              [autoResize]="true"
              [readonly]="true"
              [value]="notification.calendarEvent.message"
              style="width: 100%; min-height: 60px"
    ></textarea>

    <div class="flex justify-content-end mt-5">
<!--        <app-dropdown-->
<!--            label="Posponi notifica"-->
<!--            labelWidth="auto"-->

<!--            [(model)]="postponeDate"-->
<!--            [dropdownItems]="[-->
<!--            {'offset': '00:30:00', 'label': '30 minuti'},-->
<!--            {'offset': '01:00:00', 'label': '1 ora'},-->
<!--            {'offset': '24:00:00', 'label': '1 giorno'}-->
<!--        ]"-->
<!--            [showClear]="true"-->
<!--            inputWidth="200px"-->
<!--            optionValue="offset"-->
<!--            optionLabel="label"-->
<!--        ></app-dropdown>-->

        <app-datetime
            label="Posticipa notifica"
            labelWidth="auto"
            class="mr-3"
            [(model)]="postponeDate"
        ></app-datetime>

        <button *ngIf="postponeDate"
                pButton class="p-button-raised" icon="pi pi-save" label="Posticipa notifica" (click)="postpone()"
                style="float: right"></button>
        <button *ngIf="!postponeDate"
            pButton class="p-button-raised" icon="pi pi-save" label="Segna come letta" (click)="setRead()"
                style="float: right"></button>
    </div>
</div>
