import {BaseService, Body, Field, GET, Headers, Path, POST, Query, Response} from 'ts-retrofit';
import {ACCEPT_JSON} from '../config';
import {UserData} from '../../../models/user-data';
import {AclPermission} from '../../../models/acl/acl-group';
import {BodyRequest} from '../../../models/body-request';

const base = 'auth';
export class AuthenticationService extends BaseService {

    @POST(`/${base}/login`)
    @Headers({
        ...ACCEPT_JSON
    })
    async login(
        @Field('username') username: string,
        @Field('password') password: string,
    ) {
        return <Response<UserData>>{};
    }

    @GET(`/${base}/apitoken`)
    @Headers({
        ...ACCEPT_JSON
    })
    async getBotToken(): Promise<Response<string>> {
        return <Response>{};
    }

    @GET(`/${base}/token`)
    @Headers({
        ...ACCEPT_JSON
    })
    async getUserData(@Query('username') username: string): Promise<Response<UserData>> {
        return <Response>{};
    }

    @POST(`/${base}/apitoken`)
    @Headers({
        ...ACCEPT_JSON
    })
    async generateBotToken(@Body permissionIds: AclPermission[]): Promise<Response<string>> {
        return <Response>{};
    }

    @GET(`/${base}/client/{clientId}/ClientAreaHashCode`)
    @Headers({
        ...ACCEPT_JSON
    })
    async generateClientAreaHashCode(@Path('clientId') clientId: number): Promise<Response<string>> {
        return <Response>{};
    }

    @GET(`/${base}/twoFactor/qrCode`)
    @Headers({
        ...ACCEPT_JSON
    })
    async getTwoFactorQrCodeUrl(): Promise<Response<string>> {
        return <Response>{};
    }

    @POST(`/${base}/twoFactor`)
    @Headers({
        ...ACCEPT_JSON
    })
    async authenticateTwoFactor(@Body code: BodyRequest<string>): Promise<Response<UserData>> {
        return <Response>{};
    }

}
