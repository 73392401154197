import {Type} from 'class-transformer';

export class ClientPremiumAssistance {
    clientId: number;
    password: string;
    assPremium: number;
    pag: string;
    @Type(() => Date)
    activationDate: Date;
    temp: number;
}
