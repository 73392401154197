import {ICloneable} from '../interfaces/icloneable';
import {Type} from 'class-transformer';

export class ClientTelephoneDescriptor implements ICloneable {
    phoneNumber: string;

    description: string;

    loginNameUsedByTechnician: string;

    maybeCanReceiveSms: boolean;

    @Type(() => Date)
    lastTimeUsed: Date;

    isNewPhone(): boolean {
        return this.description === 'Nuovo numero';
    }

    clone(other: ClientTelephoneDescriptor = null) {
        if (other == null) {
            other = this;
        }
        return Object.assign(new ClientTelephoneDescriptor(), other);
    }
}
