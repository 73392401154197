import {BaseService, Body, DELETE, GET, Headers, PATCH, Path, POST, Query, Response} from 'ts-retrofit';
import {ACCEPT_JSON} from '../config';
import {BodyRequest} from '../../../models/body-request';
import {InvoiceXmlContentResponse} from '../../../models/invoices/invoice-xml-content-response';
import {ToData} from '../rest-api-hydratator-decorators';
import {MessageTemplate, MessageTemplateType} from '../../../models/tickets/message-template';
import {TicketGroupSide} from '../../../models/tickets/ticket-group-side';

const base = 'messageTemplates';

export class MessageTemplatesService extends BaseService {

    @GET(`/${base}`)
    @Headers({...ACCEPT_JSON})
    async getTemplates(@Query('type') type: MessageTemplateType): Promise<Response<MessageTemplate[]>> {
        return <Response>{};
    }

    @DELETE(`/${base}/{side}/{id}`)
    @Headers({...ACCEPT_JSON})
    async delete(@Path('side') side: 'client' | 'provider', @Path('id') id: number): Promise<Response<boolean>> {
        return <Response>{};
    }

    @POST(`/${base}`)
    @Headers({...ACCEPT_JSON})
    async save(@Body messageTemplate: MessageTemplate): Promise<Response<MessageTemplate>> {
        return <Response>{};
    }

}
