import {BaseService, Body, GET, Headers, POST, Response} from 'ts-retrofit';
import {ACCEPT_JSON} from '../config';
import {ToData, ToListedResponse} from '../rest-api-hydratator-decorators';
import {QueueJobStatus} from '../../../models/queue-jobs/queue-job-status';

const base = 'queueJobs';

export class QueueJobsApiService extends BaseService {

    @GET(`/${base}`)
    @Headers({...ACCEPT_JSON})
    @ToData(QueueJobStatus)
    async getAll(): Promise<Response<QueueJobStatus[]>> {
        return <Response>{};
    }
}
