import {Component, OnInit} from '@angular/core';
import {ConfirmationService, MessageService} from 'primeng/api';
import {DialogService} from 'primeng/dynamicdialog';
import {TicketFlagTemplate} from '../../../../models/tickets/ticket-flag-template';
import {RestApiService} from '../../../../services/api';
import {FlagTemplatesFormComponent} from '../flag-templates-form/flag-templates-form.component';
import {StringifierService} from '../../../../services/stringifier.service';
import {ToastService} from '../../../../services/toast.service';

@Component({
    selector: 'app-flag-templates-list',
    templateUrl: './flag-templates-list.component.html',
    styleUrls: ['./flag-templates-list.component.scss']
})
export class FlagTemplatesListComponent implements OnInit {
    loading = false;

    flagTemplatesList: TicketFlagTemplate[] = [];
    ticketFlagTemplateStringifier: TicketFlagTemplate;
    symbolsList: {value, label}[] = [];
    clonedTemplate: {[s: number]: TicketFlagTemplate} = {};

    constructor(
        private api: RestApiService,
        private confirmationService: ConfirmationService,
        private toastService: ToastService,
        private dialogService: DialogService,
        private stringifierService: StringifierService
    ) {
        this.ticketFlagTemplateStringifier = stringifierService.getStringifier(TicketFlagTemplate);
    }

    ngOnInit(): void {
        this.loadFlagTemplatesList();
    }

    async loadFlagTemplatesList() {
        try {
            this.loading = true;
            this.flagTemplatesList = (await this.api.ticketFlagTemplates.getAll()).data;

            const distinctSymbols = [...new Set(this.flagTemplatesList.map(x => x.simbolo))];
            this.symbolsList = distinctSymbols.map(symbol => ({label: symbol, value: symbol}));
        } finally {
            this.loading = false;
        }
    }

    onRowEditInit(template: TicketFlagTemplate) {
        this.clonedTemplate[template.id] = {...template};
    }

    async onRowEditSave(template: TicketFlagTemplate) {
        delete this.clonedTemplate[template.id];
        await this.api.ticketFlagTemplates.editFlag(template.id, template);
        this.toastService.success('Template aggiornato', `"${template.descr}" è stato aggiornato`);
    }

    onRowEditCancel(template: TicketFlagTemplate, index: number) {
        this.flagTemplatesList[index] = this.clonedTemplate[template.id];
        delete this.clonedTemplate[template.id];
    }

    deleteFlagTemplate(id: number, descr: string) {
        this.confirmationService.confirm({
            message: 'Eliminare il template?',
            header: 'Conferma eliminazione',
            icon: 'pi pi-exclamation-triangle',
            accept: async () => {
                await this.api.ticketFlagTemplates.deleteFlag(id);
                this.flagTemplatesList.splice(this.flagTemplatesList.findIndex(x => x.id === id), 1);

                this.toastService.info('Template eliminato',  `"${descr}" è stato eliminato`);
            },
        });
    }

    // TODO [PHX-404] - unificare codice
    parseColor(color: string) {
        color = color.replace('#', '');
        if (!color) {
            color = 'ffffff';
        }
        if (color.length <= 3) {
            color = color.split('').map(char => char + char).join('');
        }
        return '#' + color;
    }

    // TODO [PHX-404] - unificare codice
    formatColor(template: TicketFlagTemplate, $event: string) {
        template.color = $event.replace('#', '');
    }

    openCreateDialog() {
        const dialog = FlagTemplatesFormComponent.openDialog(this.dialogService, this.symbolsList);
        dialog.onClose.subscribe((newTemplate) => {
            if (newTemplate) {
                this.loadFlagTemplatesList();
            }
        });
    }
}
