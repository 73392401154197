import {FString} from '../../services/api/filters/string-filter';
import {BaseFilterRequest} from './base-filter-request';
import {FEnum} from '../../services/api/filters/enum-filter';
import {Stringifier} from '../../services/stringifier.service';
import {FilterLoginClientRequest} from './filter-login-client.request';

@Stringifier
export class FilterContractRequest extends BaseFilterRequest {
    loginName?: FString;
    loginNameWithEasyName?: FString;
    easyName?: FString;
    provider?: FEnum;
    loginClients?: FilterLoginClientRequest;
    hlan?: FString;
    citta?: FString;
    provincia?: FString;
    servizio?: FString;
    stato?: FEnum;
    presso?: FString;
    globalSearch?: string;
    sequentialRequestId?: number;

    static getStringifier(level: number) {
        if (level < 0) {
            return null;
        }
        return {
            loginName: null,
            loginNameWithEasyName: null,
            easyName: null,
            provider: null,
            loginClients: FilterLoginClientRequest.getStringifier(level - 1),
            hlan: null,
            citta: null,
            provincia: null,
            servizio: null,
            stato: null,
            presso: null,
            globalSearch: null,
            sequentialRequestId: null,
        } as FilterContractRequest;
    }
}
