<ng-template [ngIf]="type == 'label'">
    <div class="p-inputgroup align-items-center" style="height: 32px">
        <i *ngIf="icon" [class]="icon + ' mr-1'"></i>
        <label *ngIf="hasLabel" [style.width]="labelWidth">{{label}}</label>
        <span [style.width]="inputWidth" [class]="valueClass">
            <a *ngIf="action; else noLinkOnActionLabel" (click)="action.emit()" href="javascript:;">
                {{model}}
            </a>
            <ng-template #noLinkOnActionLabel></ng-template>
        </span>
    </div>
</ng-template>

<ng-template [ngIf]="type == 'text' || type == 'number'">
    <div class="p-inputgroup align-items-center">
        <i *ngIf="icon" [class]="icon + ' mr-1'"></i>
        <label [for]="inputElementId" *ngIf="hasLabel" [style.width]="labelWidth">{{label}}</label>
        <input [class]="valueClass"
               [class.ng-invalid]="internalModelLength < minLength"
               [disabled]="disabled" [readonly]="readOnly"
               pInputText [type]="type" [(ngModel)]="internalModel"
               [maxlength]="ignoreMaxLength ? null : maxLength" [placeholder]="placeholder"
               [style.width]="inputWidth"
               [required]="required"
               (keyup.enter)="hasActionButton && this.action.emit(inputElement.value)"
               #inputElement [id]="inputElementId"
               [autofocus]="autofocus"
        >
        <button pButton class="p-inputgroup-addon" *ngIf="canBeRandomized"
                pTooltip="Genera testo casuale" tooltipPosition="top" appendTo="body"
                (click)="randomizeContent()">
            <span class="fas fa-dice"></span>
        </button>
        <span class="p-inputgroup-addon" *ngIf="showAddons">
            <ng-container *ngTemplateOutlet="customButtons"></ng-container>
            <span *ngIf="showLength && internalModel" pTooltip="N. di caratteri">
                {{internalModelLength}}
                <ng-template [ngIf]="!ignoreMaxLength"> / {{maxLength}}</ng-template>
            </span>
        </span>
        <app-action-button *ngIf="hasActionButton"
                           [disabled]="disabled || disabledActionButton"
                           [label]="actionButtonLabel"
                           [pTooltip]="actionButtonTooltip"
                           [type]="actionButtonType"
                           (click)="this.action.emit(inputElement.value)"
        ></app-action-button>
    </div>
</ng-template>

<ng-template [ngIf]="type == 'textarea'">
    <div class="p-inputgroup">
        <i *ngIf="icon" [class]="icon + ' mr-1'"></i>
        <label [for]="inputElementId" *ngIf="hasLabel" [style.width]="labelWidth"
               style="margin-top: 0.2rem">{{label}}</label>
        <textarea #textarea pInputTextarea [autoResize]="autoresize"
                  style="resize: none; overflow-y: auto"
                  [disabled]="disabled" [readOnly]="readOnly"
                  [(ngModel)]="internalModel"
                  [maxlength]="ignoreMaxLength ? null : maxLength"
                  [style.width]="inputWidth" [rows]="4"
                  [required]="required" [class]="valueClass"
                  [placeholder]="placeholder"
                  #inputElement [id]="inputElementId"></textarea>
        <span class="p-inputgroup-addon" *ngIf="showAddons">
            <div class="flex flex-column justify-content-around align-content-center align-items-center">
                <button *ngIf="showMaximizeTextareaButton" pButton pRipple class="p-button-text p-button-sm"
                        pTooltip="Mostra a pieno schermo" (click)="maximize()">
                    <i class="pi pi-window-maximize"></i>
                </button>
                <ng-container *ngTemplateOutlet="customButtons"></ng-container>
                <span *ngIf="showLength && internalModel" pTooltip="N. di caratteri">
                    {{internalModelLength}}
                    <ng-template [ngIf]="!ignoreMaxLength"> / {{maxLength}}</ng-template>
                </span>
            </div>
        </span>
    </div>
</ng-template>

<p-dialog [header]="label.length > 0 ? label : 'Modifica testo'" [(visible)]="maximizedTextArea"
          *ngIf="showMaximizeTextareaButton"
          appendTo="body"
          [style]="{width: '80vw', height: '80hw'}">
    <textarea #textarea pInputTextarea [autoResize]="autoresize"
              [disabled]="disabled" [readOnly]="readOnly"
              [(ngModel)]="internalModel"
              [maxlength]="ignoreMaxLength ? null : maxLength"
              [readonly]="readOnly"
              [rows]="30"
              style="width: 100%"
              [placeholder]="placeholder"
              #inputElement [id]="inputElementId"></textarea>
</p-dialog>

<ng-template [ngIf]="type == 'wysiwyg'">
    <div class="p-inputgroup">
        <i *ngIf="icon" [class]="icon + ' mr-1'"></i>
        <label [for]="inputElementId" *ngIf="hasLabel" [style.width]="labelWidth"
               style="margin-top: 0.2rem">{{label}}</label>
        <p-editor
            [disabled]="disabled" [readonly]="readOnly"
            [(ngModel)]="internalModel"
            [maxlength]="ignoreMaxLength ? null : maxLength"
            [style.width]="inputWidth"
            [required]="required" [class]="valueClass"
            [placeholder]="placeholder"
        ></p-editor>
        <span class="p-inputgroup-addon" *ngIf="showLength && internalModel" pTooltip="N. di caratteri">
            {{internalModelLength}}
            <ng-template [ngIf]="!ignoreMaxLength"> / {{maxLength}}</ng-template>
        </span>
    </div>
</ng-template>

