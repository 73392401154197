import {
    BaseService,
    Body,
    DELETE,
    GET,
    Headers,
    Path,
    POST,
    PUT,
    Response,
} from 'ts-retrofit';
import {ACCEPT_JSON} from '../config';
import {TicketFlagTemplate} from '../../../models/tickets/ticket-flag-template';

const flags = 'ticketFlags';

export class TicketFlagTemplatesService extends BaseService {

    @GET(`/${flags}`)
    @Headers({...ACCEPT_JSON})
    async getAll(): Promise<Response<TicketFlagTemplate[]>> {
        return <Response>{};
    }

    @POST(`/${flags}`)
    @Headers({...ACCEPT_JSON})
    async createFlag(@Body flag: TicketFlagTemplate): Promise<Response> {
        return <Response>{};
    }

    @PUT(`/${flags}/{id}`)
    @Headers({...ACCEPT_JSON})
    async editFlag(@Path('id') id: number, @Body flag: TicketFlagTemplate): Promise<Response> {
        return <Response>{};
    }

    @DELETE(`/${flags}/{id}`)
    @Headers({...ACCEPT_JSON})
    async deleteFlag(@Path('id') id: number): Promise<Response> {
        return <Response>{};
    }
}

