import {PartialRouting} from '../partial-routing';

export class RoutingResellerAgents extends PartialRouting {
    constructor(base: string = '') {
        super(base);
    }

    list() {
        return this.getBasePath();
    }

    agentById(id: string | number) {
        return `${this.base}agents/${id}`;
    }

    documentsList() {
        return `${this.base}documents`;
    }

    uploadDocumentView() {
        return `${this.base}documents/upload`;
    }

    agentClients() {
        return `${this.base}clients`;
    }

    clientFlagsList() {
        return `${this.base}flags`;
    }

    clientFlag(id: number | string) {
        return `${this.base}flags/${id}`;
    }
}
