import {Type} from 'class-transformer';

export class TicketLog {
    ticketLogId: number;
    userCode: string;
    clientTicketId?: number;
    providerTicketCode: string;
    ticketGroupId?: number;
    description: string;

    @Type(() => Date)
    timeStamp?: Date;

    idRef?: number;

    parStr: string;
}
