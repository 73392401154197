import {BaseService, Body, GET, Headers, PATCH, Path, Query, Response} from 'ts-retrofit';
import {ACCEPT_JSON} from '../config';
import {BodyRequest} from '../../../models/body-request';
import {LineIpDetailsResponse, LineStatus} from '../../../models/responses/line-ip-details-response';
import {AveragePingResponse} from '../../../models/responses/average-ping-response';

const network = 'crawler/network';

export class NetworkService extends BaseService {

    @GET(`/${network}/ping`)
    @Headers({...ACCEPT_JSON})
    async ping(@Query('host') host: string): Promise<Response<LineStatus>> {
        return <Response>{};
    }

    @GET(`/${network}/ping/average`)
    @Headers({...ACCEPT_JSON})
    async pingAverage(@Query('host') host: string): Promise<Response<AveragePingResponse>> {
        return <Response>{};
    }

}
