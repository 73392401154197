import {Component, OnInit} from '@angular/core';
import {MessageService} from 'primeng/api';
import {DialogService, DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {TicketFlagTemplate} from '../../../../models/tickets/ticket-flag-template';
import {RestApiService} from '../../../../services/api';
import {ToastService} from '../../../../services/toast.service';

interface FlagTemplatesFormComponentDialogConfiguration {
    symbolsList: { value, label }[];
}

@Component({
    selector: 'app-flag-templates-form',
    templateUrl: './flag-templates-form.component.html',
    styleUrls: ['./flag-templates-form.component.scss']
})
export class FlagTemplatesFormComponent {
    loading = false;

    symbolsList: { value, label }[] = [];

    newTemplate: TicketFlagTemplate = {color: 'ca77c3', descr: 'Descrizione', simbolo: '●'};

    constructor(
        private api: RestApiService,
        private config: DynamicDialogConfig,
        private dynamicDialogRef: DynamicDialogRef,
        private toastService: ToastService
    ) {
        this.symbolsList = (config.data as FlagTemplatesFormComponentDialogConfiguration).symbolsList ?? [];
    }

    // TODO [PHX-404] - unificare codice
    parseColor(color: string) {
        color = color.replace('#', '');
        if (!color) {
            color = 'ffffff';
        }
        if (color.length <= 3) {
            color = color.split('').map(char => char + char).join('');
        }
        return '#' + color;
    }

    // TODO [PHX-404] - unificare codice
    formatColor(template: TicketFlagTemplate, $event: string) {
        template.color = $event.replace('#', '');
    }

    async createFlagTemplate() {
        try {
            this.loading = true;
            await this.api.ticketFlagTemplates.createFlag(this.newTemplate);
            this.toastService.success('Template creato', `"${this.newTemplate.descr}" è stato creato`);
            this.dynamicDialogRef.close(this.newTemplate);
        } finally {
            this.loading = false;
        }
    }

    close() {
        this.dynamicDialogRef.close();
    }

    static openDialog(dialogService: DialogService, symbolsList: { value, label }[]): DynamicDialogRef {
        return dialogService.open(FlagTemplatesFormComponent, {
            width: '60%',
            closable: true,
            closeOnEscape: true,
            header: 'Creazione nuovo template',
            modal: true,
            showHeader: true,
            data: {symbolsList}
        });
    }
}
