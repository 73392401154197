import {Type} from 'class-transformer';
import {ClientReminder} from './clients/client-reminder';

export class NotificationMessage {
    notificationId: number;

    senderUserId?: number;
    title: string;
    message: string;

    @Type(() => Date)
    createdAt?: Date;

    notificationType: NotificationType;
    severity: NotificationSeverity;

    calendarEvent?: ClientReminder;
    copyableData?: string;
}


export enum NotificationType {
    Unknown = 0,
    BeactiveSms = 1,
    PhpJobsWarning = 2,
    CalendarEvent = 3,
    GenericYeastarSms = 4,
    SendMassiveInvoiceEmails = 5,
    MissingClientLoginAssociation = 7,
    EasyCastUpdate = 8,
    Update = 10000
}

export enum NotificationSeverity {
    Info,
    Warning,
    Error
}
