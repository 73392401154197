import {PartialRouting} from '../partial-routing';

export class RoutingDynamicDns extends PartialRouting {
    constructor(base: string = '') {
        super(base);
    }

    list() {
        return this.history();
    }

    currentDnsTable() {
        return `${this.base}dnsTable`;
    }

    history() {
        return `${this.base}history`;
    }
}
