<div class="flex  justify-content-between">
    <div>
        <span class="block font-bold">{{notification.title}} il {{notification.calendarEvent?.expireOn | appDate}}</span>
        <span class="block font-bold" *ngIf="notification.calendarEvent.clientId">
            Cliente: {{notification.calendarEvent.client.ragioneSociale}}
        </span>
    </div>
    <div>
        <app-action-button
                label="Dettagli"
                type="info"
                (click)="openDetails()"
        ></app-action-button>
    </div>
</div>
