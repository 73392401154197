import {Component, OnInit} from '@angular/core';
import {MftWebApiService} from '../../../services/mft-web-api.service';
import {ActivatedRoute} from '@angular/router';
import {RoutingConstants} from '../../../models/routing/routing-constants';
import {Routing} from '../../../models/routing/routing';
import {getToken} from '../../../services/session-storage.service';
const {params} = RoutingConstants;

@Component({
    selector: 'app-mftweb',
    templateUrl: './mftweb.component.html',
    styleUrls: ['./mftweb.component.scss']
})
export class MftwebComponent implements OnInit {

    constructor(private mftwebApi: MftWebApiService, private route: ActivatedRoute) {
    }

    ngOnInit(): void {
        this.route.queryParams.subscribe(queryParams => {
            const mftwebUrl = queryParams[params.mftwebUrl];
            if (mftwebUrl) {
                this.mftwebApi.mftwebPost(
                    mftwebUrl,
                    {
                        __phoenix_auth_token: getToken()
                    }
                );
            }
        });
    }

}

export function getMftwebUrl(url: string) {
    return '/' + Routing.mftweb.getBasePath() + `?${params.mftwebUrl}=${url}`;
}

export function openMftwebUrl(url: string) {
    window.open(getMftwebUrl(url), '_blank');
}
