import {Type} from 'class-transformer';
import {Stringifier} from '../../services/stringifier.service';
import {CustomFieldType, ICustomFieldType} from './CustomFieldType';
import {DeviceTemplate, IDeviceTemplate} from './DeviceTemplate';

// tslint:disable-next-line:no-empty-interface
export interface IDeviceTemplateCustomFieldType extends DeviceTemplateCustomFieldType {
}

@Stringifier
export class DeviceTemplateCustomFieldType {
    deviceTemplateCustomFieldTypeId: number;
    deviceTemplateId: number;
    customFieldTypeId: number;
    isRequired: boolean;
    order: number;

    @Type(() => CustomFieldType)
    customFieldType?: ICustomFieldType;
    @Type(() => DeviceTemplate)
    deviceTemplate?: IDeviceTemplate;

    static getStringifier(level: number) {
        if (level < 0) {
            return null;
        }
        return {
            deviceTemplateCustomFieldTypeId: null,
            deviceTemplateId: null,
            customFieldTypeId: null,
            isRequired: null,
            order: null,
            customFieldType: CustomFieldType.getStringifier(level - 1),
            deviceTemplate: DeviceTemplate.getStringifier(level - 1),
        } as DeviceTemplateCustomFieldType;
    }
}



