<p-sidebar styleClass="sidebar" [(visible)]="displaySidenavMenu" position="right"
           [closeOnEscape]="true">
    <ng-template pTemplate="header">
        Impostazioni utente
    </ng-template>

    <ng-template pTemplate="content">
        <div>
            <p-menu [model]="mainMenu" styleClass="menu"></p-menu>
        </div>

        <app-switch-user></app-switch-user>

        <div class="theme-chooser">
            <p-menu [model]="bottomMenu" styleClass="menu"></p-menu>
            <app-version></app-version>
        </div>
    </ng-template>

</p-sidebar>


<div class="container" [class.dark]="">
    <p-toolbar styleClass="toolbar p-2">
        <div class="toolbar-group-left flex justify-content-start align-items-center">
            <app-toolbar></app-toolbar>
        </div>
        <div class="toolbar-group-right">

            <div class="mr-3 inline-block">
                <app-fast-dial-numbers></app-fast-dial-numbers>
            </div>
            <button pButton pRipple (click)="openMftWebOldPage()" class="text-center p-button-text mr-3 pr-4 pl-4"
                    icon="fas fa-history"
                    pTooltip="Vai alla vecchia versione" tooltipPosition="left">
            </button>

            <app-notifications-button *appAcl="AclPermission.SignalRNotificationsEnabled"></app-notifications-button>

            <p-button (click)="displaySidenavMenu = true"
                      icon="pi pi-bars"
                      label="Menu"
                      class="menu-button p-button-outlined"></p-button>
        </div>
    </p-toolbar>
    <div class="content grid-nogutter">
        <router-outlet></router-outlet>
    </div>
</div>
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle" [style]="{width: '80ch'}"></p-confirmDialog>

