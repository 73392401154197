// import {createMock} from 'ts-auto-mock';
import {Stringifier} from '../services/stringifier.service';

@Stringifier
export class LineColor {

    codice: string;
    nome: string;
    colTesto: string;
    colSfondo: string;
    hdLevel: string;

    static getStringifier(level: number) {
        if (level < 0) {
            return null;
        }
        return {
            codice: null,
            nome: null,
            colTesto: null,
            colSfondo: null,
            hdLevel: null,
        } as LineColor;
    }
}
