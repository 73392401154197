import {Device} from 'src/app/models/DevicesMap/Device';
import {FilterDeviceRequest} from 'src/app/models/requests/filter-device-request';
import {BaseService, Body, DELETE, GET, Headers, Path, POST, PUT, Query, Response} from 'ts-retrofit';
import {ConnectedNetworkInterface} from '../../../models/DevicesMap/ConnectedNetworkInterface';
import {CustomFieldType} from '../../../models/DevicesMap/CustomFieldType';
import {DeviceMap} from '../../../models/DevicesMap/DeviceMap';
import {DeviceTemplate} from '../../../models/DevicesMap/DeviceTemplate';
import {NetworkInterface} from '../../../models/DevicesMap/NetworkInterface';
import {NetworkInterfaceTemplate} from '../../../models/DevicesMap/NetworkInterfaceTemplate';
import {ListedResponse} from '../../../models/hydratator/listed-response';
import {FilterNetworkInterfaceTemplateRequest} from '../../../models/requests/filter-network-interface-template-request';
import {ACCEPT_JSON} from '../config';
import {ToData, ToListedResponse} from '../rest-api-hydratator-decorators';

const deviceMapBase = 'devicesMaps';
const deviceBase = 'devices';
const deviceTemplatesBase = 'deviceTemplates';
const networkInterfaceTemplatesBase = 'networkInterfaceTemplates';
const customFieldTypesBase = 'customFieldTypes';
const networkInterfacesBase = 'networkInterfaces';
const connectedNetworkInterfacesBase = 'connectedNetworkInterfaces';

export class DevicesMapsApiService extends BaseService {
    @GET(`/${deviceMapBase}/{deviceMapId}`)
    @Headers({...ACCEPT_JSON})
    @ToData(DeviceMap)
    async getDeviceMap(@Path('deviceMapId') deviceMapId: number): Promise<Response<DeviceMap>> {
        return <Response>{};
    }

    @GET(`/${deviceMapBase}/by-client/{clientId}`)
    @Headers({...ACCEPT_JSON})
    @ToData(DeviceMap)
    async getDeviceMapByClient(@Path('clientId') clientId: number): Promise<Response<DeviceMap>> {
        return <Response>{};
    }

    @POST(`/${deviceMapBase}`)
    @Headers({...ACCEPT_JSON})
    @ToData(DeviceMap)
    async createDeviceMap(@Body deviceMap: Omit<DeviceMap, 'deviceMapId'>): Promise<Response<DeviceMap>> {
        return <Response>{};
    }

    @PUT(`/${deviceMapBase}/{deviceMapId}`)
    @Headers({...ACCEPT_JSON})
    @ToData(DeviceMap)
    async editDeviceMap(
        @Path('deviceMapId') deviceMapId: number,
        @Body deviceMap: Omit<DeviceMap, 'deviceMapId'>,
    ): Promise<Response<DeviceMap>> {
        return <Response>{};
    }

    @GET(`/${deviceTemplatesBase}`)
    @Headers({...ACCEPT_JSON})
    @ToListedResponse(DeviceTemplate)
    async getDeviceTemplates(): Promise<Response<ListedResponse<DeviceTemplate>>> {
        return <Response>{};
    }

    @GET(`/${deviceTemplatesBase}/{deviceTemplateId}`)
    @Headers({...ACCEPT_JSON})
    @ToData(DeviceTemplate)
    async getDeviceTemplate(@Path('deviceTemplateId') deviceTemplateId: number): Promise<Response<DeviceTemplate>> {
        return <Response>{};
    }

    @POST(`/${deviceTemplatesBase}`)
    @Headers({...ACCEPT_JSON})
    @ToData(DeviceTemplate)
    async createDeviceTemplate(@Body deviceTemplate: Omit<DeviceTemplate, 'deviceTemplateId'>): Promise<Response<DeviceTemplate>> {
        return <Response>{};
    }

    @PUT(`/${deviceTemplatesBase}/{deviceTemplateId}`)
    @Headers({...ACCEPT_JSON})
    @ToData(DeviceTemplate)
    async editDeviceTemplate(
        @Path('deviceTemplateId') deviceTemplateId: number,
        @Body deviceTemplate: Omit<DeviceTemplate, 'deviceTemplateId'>,
    ): Promise<Response<DeviceTemplate>> {
        return <Response>{};
    }

    @DELETE(`/${deviceTemplatesBase}/{deviceTemplateId}`)
    @Headers({...ACCEPT_JSON})
    async deleteDeviceTemplate(@Path('deviceTemplateId') deviceTemplateId: number): Promise<Response<void>> {
        return <Response>{};
    }

    @GET(`/${deviceTemplatesBase}/getDeviceTypes`)
    @Headers({...ACCEPT_JSON})
    async getDeviceTypes(): Promise<Response<string[]>> {
        return <Response>{};
    }

    @GET(`/${networkInterfaceTemplatesBase}`)
    @Headers({...ACCEPT_JSON})
    @ToData(NetworkInterfaceTemplate)
    async getNetworkInterfaceTemplates(): Promise<Response<NetworkInterfaceTemplate[]>> {
        return <Response>{};
    }

    @POST(`/${networkInterfaceTemplatesBase}/search`)
    @Headers({...ACCEPT_JSON})
    @ToListedResponse(Device)
    async searchNetworkInterfaceTemplates(
        @Body request: FilterNetworkInterfaceTemplateRequest
    ): Promise<Response<ListedResponse<NetworkInterfaceTemplate>>> {
        return <Response>{};
    }

    @GET(`/${networkInterfaceTemplatesBase}/{deviceTemplateId}/customFieldTypes`)
    @Headers({...ACCEPT_JSON})
    @ToData(NetworkInterfaceTemplate)
    async getNetworkInterfaceTemplateCustomFieldTypes(
        @Path('deviceTemplateId') deviceTemplateId: number,
    ): Promise<Response<NetworkInterfaceTemplate[]>> {
        return <Response>{};
    }

    @POST(`/${networkInterfaceTemplatesBase}`)
    @Headers({...ACCEPT_JSON})
    @ToData(NetworkInterfaceTemplate)
    async createNetworkInterfaceTemplate(
        @Body networkInterfaceTemplate: Omit<NetworkInterfaceTemplate, 'networkInterfaceTemplateId' | 'internalType'>
    ): Promise<Response<NetworkInterfaceTemplate>> {
        return <Response>{};
    }

    @PUT(`/${networkInterfaceTemplatesBase}/{networkInterfaceTemplateId}`)
    @Headers({...ACCEPT_JSON})
    @ToData(NetworkInterfaceTemplate)
    async editNetworkInterfaceTemplate(
        @Path('networkInterfaceTemplateId') networkInterfaceTemplateId: number,
        @Body networkInterfaceTemplate: Omit<NetworkInterfaceTemplate, 'networkInterfaceTemplateId'>,
    ): Promise<Response<NetworkInterfaceTemplate>> {
        return <Response>{};
    }

    @DELETE(`/${networkInterfaceTemplatesBase}/{networkInterfaceTemplateId}`)
    @Headers({...ACCEPT_JSON})
    async deleteNetworkInterfaceTemplate(
        @Path('networkInterfaceTemplateId') networkInterfaceTemplateId: number
    ): Promise<Response<void>> {
        return <Response>{};
    }

    @GET(`/${customFieldTypesBase}`)
    @Headers({...ACCEPT_JSON})
    @ToListedResponse(CustomFieldType)
    async getCustomFieldTypes(): Promise<Response<ListedResponse<CustomFieldType>>> {
        return <Response>{};
    }

    @POST(`/${customFieldTypesBase}`)
    @Headers({...ACCEPT_JSON})
    @ToData(CustomFieldType)
    async createCustomFieldType(@Body customFieldType: Omit<CustomFieldType, 'customFieldTypeId'>): Promise<Response<CustomFieldType>> {
        return <Response>{};
    }

    @GET(`/${deviceBase}`)
    @Headers({...ACCEPT_JSON})
    @ToListedResponse(Device)
    async getDevices(): Promise<Response<ListedResponse<Device>>> {
        return <Response>{};
    }

    @GET(`/${deviceBase}/{deviceId}`)
    @Headers({...ACCEPT_JSON})
    @ToData(Device)
    async getDevice(@Path('deviceId') deviceId: number): Promise<Response<Device>> {
        return <Response>{};
    }

    @POST(`/${deviceBase}/search`)
    @Headers({...ACCEPT_JSON})
    @ToListedResponse(Device)
    async searchDevices(@Body request: FilterDeviceRequest): Promise<Response<ListedResponse<Device>>> {
        return <Response>{};
    }

    @POST(`/${deviceBase}/from-template`)
    @Headers({...ACCEPT_JSON})
    @ToData(Device)
    async createDevice(@Body device: Omit<Device, 'deviceId'>): Promise<Response<Device>> {
        return <Response>{};
    }

    @PUT(`/${deviceBase}/{deviceId}`)
    @Headers({...ACCEPT_JSON})
    @ToData(Device)
    async editDevice(
        @Path('deviceId') deviceId: number,
        @Body device: Omit<Device, 'deviceId'>,
    ): Promise<Response<Device>> {
        return <Response>{};
    }

    @PUT(`/${deviceBase}/{deviceId}/connections`)
    @Headers({...ACCEPT_JSON})
    @ToData(Device)
    async updateDeviceConnections(
        @Path('deviceId') deviceId: number,
        @Body connectedNetworkInterfaces: ConnectedNetworkInterface[]
    ): Promise<Response<Device>> {
        return <Response>{};
    }

    @DELETE(`/${deviceBase}/{deviceId}`)
    @Headers({...ACCEPT_JSON})
    async deleteDevice(@Path('deviceId') deviceId: number): Promise<Response<void>> {
        return <Response>{};
    }

    // undelete device
    @PUT(`/${deviceBase}/{deviceId}/undelete`)
    @Headers({...ACCEPT_JSON})
    async undeleteDevice(
        @Path('deviceId') deviceId: number,
    ): Promise<Response<void>> {
        return <Response>{};
    }

    @GET(`/${networkInterfacesBase}/by-client/{clientId}`)
    @Headers({...ACCEPT_JSON})
    @ToListedResponse(NetworkInterface)
    async getNetworkInterfacesByClient(
        @Path('clientId') clientId: number,
        @Query('searchString') searchString?: string,
    ): Promise<Response<ListedResponse<NetworkInterface>>> {
        return <Response>{};
    }

    @POST(`/${connectedNetworkInterfacesBase}`)
    @Headers({...ACCEPT_JSON})
    @ToData(ConnectedNetworkInterface)
    async createConnectedNetworkInterface(
        @Body connectedNetworkInterface: Omit<ConnectedNetworkInterface, 'connectedNetworkInterfaceId'>
    ): Promise<Response<ConnectedNetworkInterface>> {
        return <Response>{};
    }

    @DELETE(`/${connectedNetworkInterfacesBase}/{connectedNetworkInterfaceId}`)
    @Headers({...ACCEPT_JSON})
    async deleteConnectedNetworkInterface(
        @Path('connectedNetworkInterfaceId') connectedNetworkInterfaceId: number
    ): Promise<Response<void>> {
        return <Response>{};
    }
}

