import {Transform, Type} from 'class-transformer';
import {Client, IClient} from './client';
import {User} from '../user';
import {ClientFlag} from './client-flag';

export class ClientFlagAssociation {
    @Type( () => Client)
    client: IClient;
    clientFlagAssociationId: number;
    clientFlagId: number;
    @Type( () => ClientFlag)
    flag: ClientFlag;
    clientId: number;
    @Type( () => Date)
    createdOn: Date;
    createdByUserId: number;
    @Type( () => User)
    createdByUser: User;
    annotation: string;
}
