import {Role} from './user-data';
import {ITalkingCode} from './interfaces/italking-code';
import {ICloneable} from './interfaces/icloneable';
import {Stringifier} from '../services/stringifier.service';

@Stringifier
export class User implements ITalkingCode, ICloneable {

    get fullName(): string {
        return `${this.cognome ?? ''} ${this.nome ?? ''}`.trim();
    }
    userId: number;
    codice: string;
    nome: string;
    cognome: string;
    password: string;
    mail: string;
    active: boolean;

    // TODO: lo sposteremo nelle ACL... assieme ad altre cose, vedere il modello lato WS
    allowExtern: number; // TODO: il tipo deve diventare un boolean

    // TODO: A cosa serve quanto segue?? saranno da rimuovere...
    username: string;
    nickname: string;
    publicNick: string;

    // TODO: dato che non c'è un singolo ruolo per utente sarebbe meglio gestirlo in altro modo? (tramite ACL? campo
    //  booleano in tabella? Teoricamente c'è già un campo ticketLock che però non si capisce come venga usato)
    // questo ruolo viene utilizzato attualmente per distinguere gli utenti che possono avere assegnati dei ticket (tutti)
    // dagli utenti a cui non può essere assegnato un ticket (gli agenti)
    role: Role;

    getTalkingCode(): string {
        return this.codice;
    }

    clone(other: User = null) {
        if (other == null) {
            other = this;
        }
        return Object.assign(new User(), other);
    }

    static getStringifier(level: number) {
        if (level < 0) {
            return null;
        }
        return {
            userId: null,
            codice: null,
            nome: null,
            cognome: null,
            password: null,
            mail: null,
            active: null,
            allowExtern: null,
            username: null,
            nickname: null,
            publicNick: null,
            role: null,
        } as User;
    }
}
