import {BaseService, Body, GET, Headers, Path, POST, PUT, Response} from 'ts-retrofit';
import {ACCEPT_JSON} from '../../config';
import {ToData} from '../../rest-api-hydratator-decorators';
import {ContractNote} from '../../../../models/contract';
import {contractNotes, contracts} from './contracts-service-constants';
import {BodyRequest} from '../../../../models/body-request';

export class ContractNotesService extends BaseService {

    @GET(`/${contracts}/{contractId}/${contractNotes}`)
    @Headers({...ACCEPT_JSON})
    @ToData(ContractNote)
    async getContractNotes(@Path('contractId') contractId: number): Promise<Response<ContractNote[]>> {
        return <Response>{};
    }

    @POST(`/${contracts}/{contractId}/${contractNotes}`)
    @Headers({...ACCEPT_JSON})
    @ToData(ContractNote)
    async createContractNote(@Path('contractId') contractId: number,
                             @Body message: BodyRequest<string>): Promise<Response<ContractNote>> {
        return <Response>{};
    }

    @PUT(`/${contracts}/{contractId}/${contractNotes}/{noteId}`)
    @Headers({...ACCEPT_JSON})
    @ToData(ContractNote)
    async updateContractNotes(@Path('contractId') contractId: number, @Path('noteId') noteId: number,
                              @Body message: BodyRequest<string>): Promise<Response<ContractNote>> {
        return <Response>{};
    }

}
