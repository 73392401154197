import {Component, OnInit} from '@angular/core';
import {RestApiService} from '../../services/api';
import {RestApiError} from '../../services/api/rest-api-error';
import {HttpStatusCode} from '@angular/common/http';
import {ToastService} from '../../services/toast.service';
import {SessionStorageService} from '../../services/session-storage.service';
import {Router} from '@angular/router';
import {Routing} from '../../models/routing/routing';

@Component({
    selector: 'app-two-factors-authentication',
    templateUrl: './two-factors-authentication.component.html',
    styleUrls: ['./two-factors-authentication.component.scss']
})
export class TwoFactorsAuthenticationComponent implements OnInit {

    code: string;

    submitting = false;

    constructor(
        private api: RestApiService,
        private toastService: ToastService,
        private storage: SessionStorageService,
        private router: Router) {
    }

    ngOnInit(): void {
    }

    authenticateCode() {
        this.submitting = true;
        this.api.authentication.authenticateTwoFactor({data: this.code})
            .then(response => {
                this.storage.userData = response.data;
                this.router.navigate([Routing.dashboard.getBasePath()]);
            })
            .catch(error => {
                if (error instanceof RestApiError && error.code === HttpStatusCode.Unauthorized) {
                    this.toastService.error('Errore autenticazione', 'Il codice fornito non è valido');
                } else {
                    throw error;
                }
            })
            .finally(() => this.submitting = false);
    }

}
