import {Pipe, PipeTransform} from '@angular/core';

type DurationFormat = 'hhmmss' | 'ddhhmmss' | 'ddhhmmssLong';

const labels = {
    short: {
        days: 'g',
        hours: 'h',
        minutes: 'm',
        seconds: 's'
    },
    long: {
        days: ['giorno', 'giorni'],
        hours: ['ora', 'ore'],
        minutes: ['minuto', 'minuti'],
        seconds: ['secondo', 'secondi']
    }
};


@Pipe({
    name: 'appDuration',
})
export class AppDurationPipe implements PipeTransform {

    transform(valueInSecondsOrString: number | string, format: DurationFormat): any {
        let valueInSeconds: number;
        if (typeof valueInSecondsOrString === 'string') {
            valueInSeconds = this.durationToSeconds(valueInSecondsOrString);
        } else {
            valueInSeconds = valueInSecondsOrString;
        }

        let days: number;
        let seconds: number;
        let minutes: number;
        let hours: number;

        if (format === 'hhmmss') {
            seconds = Math.floor((valueInSeconds % 60));
            minutes = Math.floor(((valueInSeconds / 60) % 60));
            hours = Math.floor(((valueInSeconds / 60) / 60));
            return this.format(format, seconds, minutes, hours, days);
        } else if (format === 'ddhhmmss' || format === 'ddhhmmssLong') {
            seconds = Math.floor(valueInSeconds % 60);
            minutes = Math.floor(((valueInSeconds / 60) % 60));
            hours = Math.floor(((valueInSeconds / 60) / 60) % 24);
            days = Math.floor((((valueInSeconds / 60) / 60) / 24));
            return this.format(format, seconds, minutes, hours, days);
        } else {
            return valueInSeconds;
        }
    }

    private format(format: DurationFormat, seconds: number, minutes: number, hours: number, days: number) {
        const strDays = days.toString().padStart(2, '0');
        const strHours = hours.toString().padStart(2, '0');
        const strMinutes = minutes.toString().padStart(2, '0');
        const strSeconds = seconds.toString().padStart(2, '0');

        const labelDays = format === 'ddhhmmssLong'
            ? (days === 1 ? labels.long.days[0] : labels.long.days[1])
            : labels.short.days;
        const labelHours = format === 'ddhhmmssLong'
            ? (hours === 1 ? labels.long.hours[0] : labels.long.hours[1])
            : labels.short.hours;
        const labelMinutes = format === 'ddhhmmssLong'
            ? (minutes === 1 ? labels.long.minutes[0] : labels.long.minutes[1])
            : labels.short.minutes;
        const labelSeconds = format === 'ddhhmmssLong'
            ? (seconds === 1 ? labels.long.seconds[0] : labels.long.seconds[1])
            : labels.short.seconds;

        switch (format) {
            case 'hhmmss':
                return `${strHours}:${strMinutes}:${strSeconds}`;

            case 'ddhhmmss':
            case 'ddhhmmssLong':
                return `${strDays}${labelDays}, ${strHours}${labelHours}, ${strMinutes}${labelMinutes}, ${strSeconds}${labelSeconds}`;
        }

    }

    // duration arriva dal server nel formato [gg?].[hh]:[mm]:[ss]
    durationToSeconds(duration: string) {
        const dateAndTime = duration.split('.');
        const days = (dateAndTime.length === 2) ? dateAndTime[0] : '0';
        const time = (dateAndTime.length === 2) ? dateAndTime[1] : dateAndTime[0];

        const [hours, minutes, seconds] = time.split(':');

        return (parseInt(days ?? '0', 10) * 24 * 60 * 60) +
            (parseInt(hours ?? '0', 10) * 60 * 60) +
            (parseInt(minutes ?? '0', 10) * 60) +
            (parseInt(seconds ?? '0', 10));
    }

}
