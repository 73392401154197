export const environment = {
    production: true,
    mftItaliaWebsite: 'https://www.mftitalia.it',
    baseApi: 'https://api.mftitalia.it/api',
    hubUrl: 'https://api.mftitalia.it/hubs/notifications',
    baseMftweb: 'https://mftweb.mftitalia.it/MFTWEB',
    baseClientArea: 'https://areacliente.mftitalia.it',
    internalMftweb: 'http://192.168.1.203/MFTWEB',
    baseEasyCast: 'https://dashboard.easycast.fun'
};
