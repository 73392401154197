import {Stringifier} from '../../services/stringifier.service';
import {Type} from 'class-transformer';
import {User} from '../user';
import {LicenseFeature, LicenseType} from './easy-cast-license';
import {EasyCastContractType} from '../requests/easy-cast/save-easy-cast-contract-request';

@Stringifier
export class EasyCastContract {
    contractId: number;
    contractSecret: string;
    contractStatus: EasyCastContractState;
    @Type(() => Date)
    expiresAt: Date;
    clientBusinessName: string;
    fiscalCode: string;
    vatNumber: string;
    zipCode: string;
    address: string;
    city: string;
    province: string;
    state: string;
    country: string;
    clientEmail: string;
    gmailAccount: string;
    gmailPassword: string;
    commercialAgentId: number;
    @Type(() => User)
    commercialAgent: User;
    licenseType: LicenseType;
    licenseFeature: LicenseFeature;
    licenseEnterprise: boolean;
    quantityOfPlaybackDevices: number;
    credits: number;

    @Type(() => Date)
    shippingDate: Date = new Date();
    @Type(() => Date)
    expectedDeliveringDate: Date | null = null;
    shippingCode = '';
    shippingCourier = '';
    shippingLink = '';
    @Type(() => Date)
    deliveringDate: Date | null = null;

    contractType: EasyCastContractType;

    @Type(() => Date)
    createdAt: Date;
    @Type(() => Date)
    updatedAt: Date;

    easyCastReflectorId?: number;
    @Type(() => SeasonDates)
    seasonDates: SeasonDates[];
    seasonDays: number;

    static getStringifier(level: number) {
        if (level < 0) {
            return null;
        }
        return {
            contractId: null,
            contractSecret: null,
            contractStatus: null,
            expiresAt: null,
            clientBusinessName: null,
            fiscalCode: null,
            vatNumber: null,
            zipCode: null,
            address: null,
            city: null,
            province: null,
            state: null,
            country: null,
            clientEmail: null,
            gmailAccount: null,
            commercialAgentId: null,
            commercialAgent: User.getStringifier(level - 1),
            licenseType: null,
            licenseFeature: null,
            licenseEnterprise: null,
            quantityOfPlaybackDevices: null,
            credits: null,
            shippingDate: null,
            expectedDeliveringDate: null,
            shippingCode: null,
            shippingCourier: null,
            shippingLink: null,
            deliveringDate: null,
            contractType: null,
            createdAt: null,
            updatedAt: null,
            easyCastReflectorId: null,
            seasonDates: null,
            seasonDays: null,
        } as EasyCastContract;
    }
}


export class SeasonDates {
    @Type(() => Date)
    from: Date;
    @Type(() => Date)
    to: Date;
    days: number;
}

export enum EasyCastContractState {
    Created = 'Created',
    // SentToClient = 'SentToClient',
    Confirmed = 'Confirmed',
    Paid = 'Paid',
    Configured = 'Configured',
    Shipped = 'Shipped',
    Delivered = 'Delivered',
    Activated = 'Activated',
    Canceled = 'Canceled',
}

const contractStatesOrder = [
    EasyCastContractState.Created,
    // EasyCastContractState.SentToClient,
    EasyCastContractState.Confirmed,
    EasyCastContractState.Paid,
    EasyCastContractState.Configured,
    EasyCastContractState.Shipped,
    EasyCastContractState.Delivered,
    EasyCastContractState.Activated
];

export enum EasyCastContractSeasonDays {
    Days180 = 180,
    Days240 = 240
}

/**
 * Restituisce true se uno stato è precedente ad un altro.
 * Se uno dei due è EasyCastContractState.Canceled, non c'è precedenza
 *
 * @param status1
 * @param status2
 * @return true se status1 precede status2
 */
export function statusIsBefore(status1: EasyCastContractState, status2: EasyCastContractState) {
    if (status2 === EasyCastContractState.Canceled) {
        return false;
    }
    if (status1 === status2) {
        return false;
    }
    const index1 = contractStatesOrder.indexOf(status1);
    const index2 = contractStatesOrder.indexOf(status2);
    return index1 < index2;
}

/**
 * Restituisce true se uno stato è precedente o uguale ad un altro
 * Se uno dei due è EasyCastContractState.Canceled, non c'è precedenza
 *
 * @param status1
 * @param status2
 * @return true se status1 precede o è uguale a status2
 */
export function statusIsBeforeOrEqual(status1: EasyCastContractState, status2: EasyCastContractState) {
    return statusIsBefore(status1, status2) || status1 === status2;
}

/**
 * Restituisce true se uno stato è successivo ad un altro
 * Se uno dei due è EasyCastContractState.Canceled, non c'è precedenza
 *
 * @param status1
 * @param status2
 * @return true se status1 segue status2
 */
export function statusIsAfter(status1: EasyCastContractState, status2: EasyCastContractState) {
    if (status2 === EasyCastContractState.Canceled) {
        return false;
    }
    if (status1 === status2) {
        return false;
    }
    const index1 = contractStatesOrder.indexOf(status1);
    const index2 = contractStatesOrder.indexOf(status2);
    return index1 > index2;
}

/**
 * Restituisce true se uno stato è successivo o uguale ad un altro
 * Se uno dei due è EasyCastContractState.Canceled, non c'è precedenza
 *
 * @param status1
 * @param status2
 * @return true se status1 segue o è uguale a status2
 */
export function statusIsAfterOrEqual(status1: EasyCastContractState, status2: EasyCastContractState) {
    return statusIsAfter(status1, status2) || status1 === status2;
}

export class EasyCastGmailAccountConfiguration {
    gmailAccount: string;
    gmailPassword: string;
}
