import { Component, OnInit } from '@angular/core';
import {RestApiService} from '../../../../services/api';
import {DialogService, DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {NotificationMessage} from '../../../../models/notification-message';

@Component({
  selector: 'app-calendar-notification-dialog',
  templateUrl: './calendar-notification-dialog.component.html',
  styleUrls: ['./calendar-notification-dialog.component.scss']
})
export class CalendarNotificationDialogComponent implements OnInit {

    notification: NotificationMessage;
    isLoading = false;
    postponeDate?: Date;

    constructor(private api: RestApiService,
                private dynamicDialogRef: DynamicDialogRef, private config: DynamicDialogConfig) {
        const configData = config.data as CalendarNotificationDialogConfiguration;
        this.notification = configData.notification;
    }

    static openDialog(dialogService: DialogService, notification: NotificationMessage): DynamicDialogRef {
        return dialogService.open(CalendarNotificationDialogComponent, {
            width: '60%',
            closable: true,
            closeOnEscape: true,
            header: 'Reminder appuntamento',
            modal: true,
            showHeader: true,
            data: {
                notification: notification
            } as CalendarNotificationDialogConfiguration
        });
    }

    ngOnInit(): void {
    }


    close() {
        this.dynamicDialogRef.close(null);
    }

    setRead() {
        this.isLoading = true;
        this.api.notifications.readNotification(this.notification.notificationId, {data: true})
            .then(() => this.dynamicDialogRef.close())
            .finally(() => this.isLoading = false);
    }

    postpone() {
        this.isLoading = true;
        this.api.notifications.postponeNotification(this.notification.notificationId, {data: this.postponeDate})
            .then(() => this.dynamicDialogRef.close())
            .finally(() => this.isLoading = false);
    }

}

interface CalendarNotificationDialogConfiguration {
    notification: NotificationMessage;
}
