import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class MftWebApiService {

    constructor() {
    }

    post(url: string, data?: Record<any, any>, target: string = '_self') {
        const form = document.createElement('form');
        form.method = 'POST';
        form.setAttribute('style', 'display: none');
        form.action = url;
        form.target = target;

        form.append(...Object.entries(data)
            .map(([key, value]) => {
                const element = document.createElement('input');
                element.name = key;
                element.setAttribute('value', value);
                return element;
            }));
        document.body.append(form);
        form.submit();
    }

    mftwebPost(url: string, data?: Record<any, any>, redirect?: string, target: string = '_self') {
        const form = document.createElement('form');
        form.method = 'POST';
        form.setAttribute('style', 'display: none');
        form.action = `${environment.baseMftweb}${url}`;
        form.target = target;

        if (redirect) {
            data['___redirect_to_phoenix'] = redirect;
        }
        form.append(...Object.entries(data)
            .map(([key, value]) => {
               const element = document.createElement('input');
               element.name = key;
               element.setAttribute('value', value);
               return element;
            }));
        document.body.append(form);
        form.submit();
    }
}
