import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import * as SignalR from '@aspnet/signalr';
import {environment} from '../../environments/environment';
import {SessionStorageService} from './session-storage.service';
import {plainToClassFromExist} from 'class-transformer';
import {map} from 'rxjs/operators';
import {NotificationMessage} from '../models/notification-message';

@Injectable({
    providedIn: 'root'
})
export class SignalRService {
    private message$: Subject<NotificationMessage>;
    private connection: SignalR.HubConnection;

    constructor(private session: SessionStorageService) {
        this.message$ = new Subject<NotificationMessage>();
        this.connection = new SignalR.HubConnectionBuilder()
            .withUrl(environment.hubUrl, {
                accessTokenFactory: () => this.session.getToken()
            })
            .build();
        this.connect();
    }

    private connect() {
        this.connection.start().catch(err => console.log(err));
        this.connection.on('Message', (message) => {
            this.message$.next(message);
        });
    }

    public getMessage(): Observable<NotificationMessage> {
        return this.message$.asObservable().pipe(
            map(x => plainToClassFromExist(new NotificationMessage(), x))
        );
    }

    public async sendMessage(message: string) {
        await this.connection.invoke('Message', {
            message: message,
            // i seguenti parametri sono sovrascritti lato server. tuttavia il sistema invia il messaggio strutturato correttamente.
            senderUserId: this.session.userData.userId, // viene sovrascritto da server
            date: new Date() // viene sovrascritto da server
        });
    }

    public disconnect() {
        this.connection.stop();
    }

}
