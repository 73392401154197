import {WeekDay} from '@angular/common';
import {Type} from 'class-transformer';
import {RemoteDevice} from './remote-device';
import {RemoteDeviceCommand} from './remote-device-command';
import {Stringifier} from '../services/stringifier.service';

@Stringifier
export class RemoteDeviceScheduledCommand {
    scheduleId: number;
    @Type(() => Date)
    lastTimeExecuted?: Date;

    remoteDeviceId: number;
    @Type(() => RemoteDevice)
    remoteDevice: RemoteDevice;

    commandId: number;
    command: RemoteDeviceCommand;

    parameters: Map<string, any>;

    hasRunToday: boolean;

    scheduleType: ScheduleType;

    timeOfExecution: string;

    @Type(() => Date)
    dateOfExecution: Date;

    dayOfWeek: WeekDay;
    counter: number;
    startingPoint: ScheduleCounterStartingPoint;

    static getStringifier(level: number) {
        if (level < 0) {
            return null;
        }
        return {
            scheduleId: null,
            lastTimeExecuted: null,
            remoteDeviceId: null,
            remoteDevice: RemoteDevice.getStringifier(level - 1),
            commandId: null,
            command: RemoteDeviceCommand.getStringifier(level - 1),
            parameters: null,
            hasRunToday: null,
            scheduleType: null,
            timeOfExecution: null,
            dateOfExecution: null,
            dayOfWeek: null,
            counter: null,
            startingPoint: null,
        } as RemoteDeviceScheduledCommand;
    }
}

export enum ScheduleType {
    Once = 'Once',
    Daily = 'Daily',
    Weekly = 'Weekly',
    Monthly = 'Monthly'
}

export class RemoteDeviceScheduledCommandDto {
    scheduleId?: number;
    remoteDeviceId: number;
    commandId: number;
    parameters: { [key: string]: any } = {};
    scheduleType = ScheduleType.Once;
    @Type(() => Date)
    dateOfExecution = new Date();
    startingPoint: ScheduleCounterStartingPoint = ScheduleCounterStartingPoint.FromStart;
    /**
     * HH:mm:00
     */
    timeOfExecution = '12:30';
    dayOfWeek: WeekDay | null = WeekDay.Monday;
    counter = 0;

    set monthlyMode(value: ScheduleMonthlyMode) {
        if (value === ScheduleMonthlyMode.SpecificDay) {
            this.dayOfWeek = null;
            this.counter = 1;
        } else {
            if (this.dayOfWeek === null) {
                this.dayOfWeek = WeekDay.Monday;
                this.counter = 0;
            }
        }
    }

    get monthlyMode(): ScheduleMonthlyMode {
        return this.dayOfWeek ? ScheduleMonthlyMode.SpecificWeekday : ScheduleMonthlyMode.SpecificDay;
    }
}

export enum ScheduleCounterStartingPoint {
    Unknown,
    FromStart = 'FromStart',
    FromEnd = 'FromEnd'
}

export enum ScheduleMonthlyMode {
    Unknown,
    SpecificDay,
    SpecificWeekday,
}
