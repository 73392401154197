import {Type} from 'class-transformer';
import {Stringifier} from '../../services/stringifier.service';
import {DeviceTemplateNetworkInterfaceTemplate, IDeviceTemplateNetworkInterfaceTemplate} from './DeviceTemplateNetworkInterfaceTemplate';
import {INetworkInterfaceTemplateCustomFieldType, NetworkInterfaceTemplateCustomFieldType} from './NetworkInterfaceTemplateCustomFieldType';

// tslint:disable-next-line:no-empty-interface
export interface INetworkInterfaceTemplate extends NetworkInterfaceTemplate {
}

@Stringifier
export class NetworkInterfaceTemplate {
    networkInterfaceTemplateId: number;
    name: string;
    internalType: InternalTypeEnum = InternalTypeEnum.UserDefined;


    @Type(() => DeviceTemplateNetworkInterfaceTemplate)
    deviceTemplateNetworkInterfaceTemplates: IDeviceTemplateNetworkInterfaceTemplate[];
    @Type(() => NetworkInterfaceTemplateCustomFieldType)
    networkInterfaceTemplateCustomFieldTypes: INetworkInterfaceTemplateCustomFieldType[];

    get isUserDefined() {
        return this.internalType === InternalTypeEnum.UserDefined;
    }

    get isSelfConnection() {
        return this.internalType === InternalTypeEnum.Self;
    }

    // static isUserDefined(networkInterfaceTemplate: NetworkInterfaceTemplate) {
    //     return networkInterfaceTemplate.internalType === InternalTypeEnum.UserDefined;
    // }

    // static isSelfConnection(networkInterfaceTemplate: NetworkInterfaceTemplate) {
    //     return networkInterfaceTemplate.internalType === InternalTypeEnum.Self;
    // }
    static getStringifier(level: number) {
        if (level < 0) {
            return null;
        }
        return {
            networkInterfaceTemplateId: null,
            name: null,
            internalType: null,
            deviceTemplateNetworkInterfaceTemplates: null,
            networkInterfaceTemplateCustomFieldTypes: null,
        } as NetworkInterfaceTemplate;
    }
}

// public enum InternalTypeEnum
// {
//     Unknown,
//     UserDefined,
//     Self,
// }

export enum InternalTypeEnum {
    Unknown,
    UserDefined,
    Self,
}
