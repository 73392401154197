import {BaseFilterRequest} from './base-filter-request';
import {FString} from '../../services/api/filters/string-filter';
import {FDate} from '../../services/api/filters/date-filter';
import {FilterContractRequest} from './filter-contract-request';
import {Stringifier} from '../../services/stringifier.service';

@Stringifier
export class FilterDynamicIpRequest extends BaseFilterRequest {
    hostname: FString;
    ip: FString;
    timestamp: FDate;
    contract: FilterContractRequest;

    static getStringifier(level: number) {
        if (level < 0) {
            return null;
        }
        return {
            hostname: null,
            ip: null,
            timestamp: null,
            contract: FilterContractRequest.getStringifier(level - 1),
        } as FilterDynamicIpRequest;
    }
}
