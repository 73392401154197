import {Stringifier} from '../../services/stringifier.service';
import {Type} from 'class-transformer';

@Stringifier
export class TicketChat {
    id: number;
    idGroup: number;
    user: string;
    msg: string;
    @Type(() => Date)
    ts: Date;
    notif?: number;
    quietBy: string;
    quietTs: string;

    static getStringifier(level: number) {
        if (level < 0) {
            return null;
        }
        return {
            id: null,
            idGroup: null,
            user: null,
            msg: null,
            ts: null,
            notif: null,
            quietBy: null,
            quietTs: null,
        } as TicketChat;
    }
}
