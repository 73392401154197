import {Component, OnInit} from '@angular/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {SignalRService} from '../../../../services/signalr.service';
import {ToastService, ToastSeverity} from '../../../../services/toast.service';
import {RestApiService} from '../../../../services/api';
import {NotificationMessage, NotificationSeverity, NotificationType} from '../../../../models/notification-message';
import {DialogService} from 'primeng/dynamicdialog';
import {
    CalendarNotificationDialogComponent
} from '../../../../components/notifications/calendar-notification/calendar-dialog/calendar-notification-dialog.component';

@Component({
    selector: 'app-notifications-button',
    templateUrl: './notifications-button.component.html',
    styleUrls: ['./notifications-button.component.scss']
})
@UntilDestroy()
export class NotificationsButtonComponent implements OnInit {

    isLoading = false;
    unreadNotifications: NotificationMessage[] = [];

    constructor(private signalRService: SignalRService,
                private api: RestApiService,
                private dialog: DialogService,
                private toastService: ToastService) {
        this.signalRService.getMessage()
            .pipe(untilDestroyed(this))
            .subscribe(message => {
                this.onMessageReceived(message);
                this.loadNotifications();
            });
    }

    ngOnInit(): void {
        this.loadNotifications();
    }

    loadNotifications() {
        this.isLoading = true;
        this.api.notifications.getNotifications()
            .then(response => {
                this.unreadNotifications = response.data;
                this.isLoading = false;
            });
    }

    onMessageReceived(message: NotificationMessage) {
        const severity = this.mapSeverity(message.severity);

        console.log(message);

        switch (message.notificationType) {
            case NotificationType.BeactiveSms:
                this.toastService.message('SMS per BeActive', message.message, severity, 'top-right');
                this.loadNotifications();
                break;
            case NotificationType.GenericYeastarSms:
                this.toastService.message(message.title, message.message, severity, 'top-right');
                this.loadNotifications();
                break;
            case NotificationType.SendMassiveInvoiceEmails:
                this.toastService.message(message.title, message.message, severity, 'top-right');
                this.loadNotifications();
                break;
            case NotificationType.CalendarEvent:
                CalendarNotificationDialogComponent.openDialog(this.dialog, message);
                // this.toastService.message(message.title, message.message, severity, 'top-right');
                break;
            case NotificationType.EasyCastUpdate:
                this.toastService.message(message.title, message.message, severity, 'top-right');
                this.loadNotifications();
                break;
            case NotificationType.Update:
                // nel caso di update non mostro notifiche ma ricarico la lista delle notifiche
                this.loadNotifications();
                break;
            case NotificationType.Unknown:
            default:
                this.toastService.warning(message.notificationType?.toString() ?? 'tipo notifica non identificata', message.message, 'top-right');
                // TODO: tracciare tipo di notifica non identificata
                throw new Error('tipo di notifica signalr non identificata: ' + message.notificationType);
        }
    }

    mapSeverity(severity: NotificationSeverity): ToastSeverity {
        switch (severity) {
            case NotificationSeverity.Info: return 'info';
            case NotificationSeverity.Warning: return 'warn';
            case NotificationSeverity.Error: return 'error';
            default: return 'info';
        }
    }



}
