import {Component, OnInit, QueryList, ViewChildren} from '@angular/core';
import { Table } from 'primeng/table';
import {FastDialNumber} from '../../../models/fast-dial-number';
import {RestApiService} from '../../../services/api';
import {ToastService} from '../../../services/toast.service';
import {InputComponent} from '../../../components/form/input/input.component';

@Component({
  selector: 'app-fast-dial-numbers-list',
  templateUrl: './fast-dial-numbers-list.component.html',
  styleUrls: ['./fast-dial-numbers-list.component.scss']
})
export class FastDialNumbersListComponent implements OnInit {

    numbers: FastDialNumber[] = [];
    loading = false;

    @ViewChildren(InputComponent)
    inputs: QueryList<InputComponent>;

    obj(item: FastDialNumber) {
        return item;
    }

    constructor(private api: RestApiService,
                private toast: ToastService) {
    }

    ngOnInit(): void {
        this.loadFastDialNumbers();
    }

    async loadFastDialNumbers() {
        this.loading = true;
        try {
            this.numbers = (await this.api.fastDialNumbers.getAll()).data;
        } catch (e) {
            this.toast.error('Numeri rapidi', 'Errore durante il caricamento dei numeri fornitori');
        } finally {
            this.loading = false;
        }
    }

    async save(number: FastDialNumber, index: number) {
        try {
            this.numbers[index] = (await this.api.fastDialNumbers.save(number)).data;
            this.toast.success('Numeri rapidi', 'Numero salvato');
        } catch (e) {
            this.toast.error('Numeri rapidi', 'Non è stato possibile salvare le modifiche');
        }
    }

    async delete(number: FastDialNumber, index: number) {
        try {
            if (number.fastDialId) {
                await this.api.fastDialNumbers.delete(number.fastDialId);
                this.toast.success('Numeri rapidi', 'Numero cancellato');
            }
            this.numbers = [...this.numbers.slice(0, index), ...this.numbers.slice(index + 1)];
        } catch (e) {
            this.toast.error('Numeri rapidi', 'Non è stato possibile eliminare il numero di telefono');
        }
    }

    add() {
        this.numbers.push({
            name: '',
            telephone: ''
        } as FastDialNumber);
        // TODO: una cosa un po' sporca ma non son riuscito a far funzionare l'autofocus del campo
        setTimeout(() => this.inputs.get(this.inputs.length - 2)?.setFocus(), 100);
    }

    validateNumber(number: FastDialNumber) {
        return number.telephone.length > 0 && number.name.length > 0;
    }
}
