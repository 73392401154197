import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-dropdown',
    templateUrl: './dropdown.component.html',
    styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent implements OnInit, OnDestroy {

    private static inputElementCounter = 1;

    private readonly _inputElementId: number;

    get inputElementId(): string {
        return 'dropdownElement' + this._inputElementId;
    }

    @Input()
    showLength = true;

    @Input()
    model: any;

    @Output()
    modelChange: EventEmitter<any> = new EventEmitter<any>();

    @Input()
    label = '';

    @Input()
    valueClass = '';

    @Input()
    dropdownItems: any[];

    @Input()
    optionLabel = 'label';

    @Input()
    optionValue: string = null;

    @Input()
    optionIcon = 'pi pi-chevron-down';

    @Input()
    showClear = false;

    @Input()
    showFilter = false;

    @Input()
    multiple = false;

    @Input()
    disabled = false;

    @Input()
    editable = false;

    @Input()
    maxlength: number = null;

    get hasLabel(): boolean {
        return this.label !== '';
    }

    @Input()
    labelWidth = '200px';

    @Input()
    inputWidth = '200px';

    @Input()
    maxSelectableElements = 3;

    @Input()
    placeholder = 'Nessuna selezione';

    @Input()
    optionGroupLabel = 'label';

    @Input()
    optionGroupChildren = 'items';

    @Input()
    group = false;

    get selectedLabels(): string {
        if (Array.isArray(this.model)) {
            let resume = '';
            for (const item of this.model) {
                resume += item[this.optionLabel] + '<br>';
            }
            return resume;
        }
        return this.model;
    }

    get isTooltipEnabled(): boolean {
        if (Array.isArray(this.model)) {
            return this.model.length <= this.maxSelectableElements;
        }
        return false;
    }

    get ellipsesText(): string {
        if (this.model && this.model.length > 0) {
            const firstItems = this.model.slice(0, this.maxSelectableElements);
            const firstItemsLabels = firstItems.map((item) => item[this.optionLabel]);
            return firstItemsLabels.join(', ') + ' ...';
        }
        return '';
    }

    constructor() {
    }

    ngOnDestroy(): void {
    }

    ngOnInit(): void {
    }

}
