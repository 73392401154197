import {PartialRouting} from '../partial-routing';
import {getMftwebUrl} from '../../../views/mftweb/mftweb/mftweb.component';

export class CoverageDetails extends PartialRouting {
    constructor(base: string = '') {
        super(base);
    }

    byLoginName(loginName: string) {
        // da modificare quando implementeremo la videata in phoenix
        return getMftwebUrl(`/Ticket/Ticket.php?msgLogin=${loginName.trim()}`);
    }
}
