import {BaseService, GET, Headers, QueryMap, Response} from 'ts-retrofit';
import {ACCEPT_JSON} from '../config';
import {ListedResponse} from '../../../models/hydratator/listed-response';
import {FilterResellerRequest} from '../../../models/requests/filter-reseller-request';
import {Reseller} from '../../../models/reseller';

export class ResellerService extends BaseService {

    @GET('/resellers')
    @Headers({...ACCEPT_JSON})
    async filter(@QueryMap request: FilterResellerRequest): Promise<Response<ListedResponse<Reseller>>> {
        return <Response> {};
    }

}
