import {Component} from '@angular/core';
import {RestApiService} from '../../services/api';
import {HttpStatusCode} from '@angular/common/http';
import {MftWebApiService} from '../../services/mft-web-api.service';
import {SessionStorageService} from '../../services/session-storage.service';
import {ToastService} from '../../services/toast.service';
import {RestApiError} from '../../services/api/rest-api-error';
import {AclPermission} from '../../models/acl/acl-group';
import {Router} from '@angular/router';
import {Routing} from '../../models/routing/routing';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent {

    username = '';
    password = '';

    formSubmitted = false;

    constructor(
        private router: Router,
        private api: RestApiService,
        private toastService: ToastService,
        private mftWebApi: MftWebApiService,
        private storage: SessionStorageService) {
    }

    async login() {
        try {
            this.formSubmitted = true;

            this.storage.reset();

            // tenta il login sul nuovo WS
            const response = await this.api.authentication
                .login(this.username, this.password);
            this.storage.userData = response.data;

            if (this.storage.hasPermission(AclPermission.UserLogged)) {
                // se la login va a buon fine redirige alla login su MFTWEB
                this.mftWebApi.mftwebPost('/index.php', {
                    userName: this.username,
                    pw: this.password,
                    token_angular: response.data.token
                }, Routing.dashboard.getBasePath());
            } else if (this.storage.hasPermission(AclPermission.TwoFactorsAuthentication)) {
                if (this.storage.userData.allowExtern) {
                    this.mftWebApi.mftwebPost('/index.php', {
                        userName: this.username,
                        pw: this.password,
                        token_angular: response.data.token
                    }, Routing.twoFactorsAuthentication.getBasePath());
                } else {
                    this.router.navigate([Routing.twoFactorsAuthentication.getBasePath()]);
                }
            } else {
                throw new RestApiError('', HttpStatusCode.Unauthorized);
            }
            this.formSubmitted = false;
        } catch (error) {
            this.formSubmitted = false;
            // La login può restituire un errore 401 se l'utente non è autorizzato.
            // Errori di diverso tipo sono lasciati in gestione al chiamante o all'handler generico
            if (error instanceof RestApiError && error.code === HttpStatusCode.Unauthorized) {
                this.toastService.error('Errore login', 'Username o password errati.');
            } else {
                throw error;
            }
        }
    }
}
