import {BaseService, Body, DELETE, GET, Headers, Path, POST, Query, QueryMap, Response} from 'ts-retrofit';
import {ACCEPT_JSON} from '../config';
import {FilterAgentRequest} from '../../../models/requests/filter-agent-request';
import {ListedResponse} from '../../../models/hydratator/listed-response';
import {Agent} from '../../../models/agent';
import {AclGroup, AclPermissionDescription} from '../../../models/acl/acl-group';
import {FilterAclGroupsRequest} from '../../../models/requests/acl/filter-acl-groups-request';
import {User} from '../../../models/user';

const base = 'acl';

export class AclApiService extends BaseService {

    @GET(`/${base}/permissions`)
    @Headers({...ACCEPT_JSON})
    async getPermissions(): Promise<Response<AclPermissionDescription[]>> {
        return <Response>{};
    }

    @GET(`/${base}/groups/{groupId}`)
    @Headers({...ACCEPT_JSON})
    async getGroup(@Path('groupId') groupId: number): Promise<Response<AclGroup>> {
        return <Response>{};
    }

    @POST(`/${base}/groups/filter`)
    @Headers({...ACCEPT_JSON})
    async getGroups(@Body request: FilterAclGroupsRequest): Promise<Response<ListedResponse<AclGroup>>> {
        return <Response>{};
    }

    @POST(`/${base}/groups`)
    @Headers({...ACCEPT_JSON})
    async saveGroup(@Body group: AclGroup): Promise<Response<AclGroup>> {
        return <Response>{};
    }

    @DELETE(`/${base}/{aclGroupId}`)
    @Headers({...ACCEPT_JSON})
    async deleteById(@Path('aclGroupId') aclId: number): Promise<Response<boolean>> {
        return <Response>{};
    }

}
