import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {MenuService} from '../../../services/menu.service';
import {MenuItem} from 'primeng/api';
import {ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, NavigationStart, Router} from '@angular/router';
import {filter, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {SessionStorageService} from '../../../services/session-storage.service';
import {RestApiService} from '../../../services/api';
import {AclPermission} from '../../../models/acl/acl-group';
import {RouteData} from '../../../guards/route-data';
import {Routing} from '../../../models/routing/routing';
import {ResizeService} from '../../../services/resize.service';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {UserSettingsComponent} from '../user-settings/user-settings.component';
import {DialogService} from 'primeng/dynamicdialog';
import {Title} from '@angular/platform-browser';
import {getMftwebUrl, openMftwebUrl} from '../../mftweb/mftweb/mftweb.component';

@Component({
    selector: 'app-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss']
})
@UntilDestroy()
export class LayoutComponent implements OnInit, OnDestroy {

    destroySubscription = new Subject<boolean>();
    displaySidenavMenu = false;
    mainMenu: MenuItem[] = [];
    bottomMenu: MenuItem[] = [];
    mftWebOldPage = '';
    AclPermission = AclPermission;

    constructor(private menuService: MenuService,
                private router: Router,
                private route: ActivatedRoute,
                private sessionStorage: SessionStorageService,
                private api: RestApiService,
                private resizeService: ResizeService,
                private dialogService: DialogService,
                private titleService: Title) {
    }

    ngOnInit() {
        this.closeSidebarOnRoute();
        this.populateSidebarMenu();
        this.populateBottomMenu();
        if (this.sessionStorage.hasPermission(AclPermission.ListAllUsers)) {
            this.api.usersCached.getAll();
        } // TODO: spostare in un servizio a parte
        this.api.userGroupsCached.getAll(); // TODO: spostare in un servizio a parte

        this.createOldVersionLink();
        this.router.events.pipe(
            untilDestroyed(this),
            filter(event => event instanceof NavigationEnd)
        ).subscribe(_ => this.createOldVersionLink());
    }

    ngOnDestroy() {
        this.destroySubscription.next(true);
        this.destroySubscription.unsubscribe();
    }

    openMftWebOldPage() {
        window.open(this.mftWebOldPage);
    }

    private closeSidebarOnRoute() {
        this.router.events
            .pipe(
                takeUntil(this.destroySubscription),
                filter(event => event instanceof NavigationStart)
            ).subscribe(_ => this.displaySidenavMenu = false);
    }

    protected populateSidebarMenu() {
        this.mainMenu = [
            {
                label: 'Home',
                routerLink: Routing.dashboard.getBasePath(),
                icon: 'fa fa-home',
            },
            {
                label: 'Ordini', url: getMftwebUrl('/Ordini/Ordini.php'),
                target: '_blank',
                visible: this.sessionStorage.hasPermission(AclPermission.ViewOrdersList)
            },
            {
                label: 'Linee',
                url: Routing.clients.listContracts(),
                icon: 'fa fa-file-contract',
                // url: getMftwebUrl('/Login/Login.php'),
                target: '_blank',
                visible: this.sessionStorage.hasPermission(AclPermission.ViewContractsList),
            },
            {
                label: 'Clienti', url: Routing.clients.getBasePath(), icon: 'fa fa-users',
                target: '_blank',
                visible: this.sessionStorage.hasPermission(AclPermission.ViewClientsList),
            },
            {
                label: 'I miei clienti', routerLink: Routing.resellerAgents.agentClients(),
                icon: 'fa fa-address-card',
                visible: this.sessionStorage.hasPermission(AclPermission.ViewAssociatedClientsList
                    || AclPermission.ViewAllAssociatedClientsList)
            },
            {
                label: 'Flags clienti per agenti', routerLink: Routing.resellerAgents.clientFlagsList(),
                icon: 'pi pi-palette',
                visible: this.sessionStorage.hasPermission(AclPermission.EditClientsFlags)
            },
            {
                label: 'Ticket', url: Routing.tickets.list(), icon: 'fa fa-envelope',
                target: '_blank',
                visible: this.sessionStorage.hasPermission(AclPermission.ViewTicketsList)
            },
            {
                label: 'Dispositivi SMS', routerLink: Routing.remoteDevices.list(), icon: 'fa fa-plug',
                visible: this.sessionStorage.hasPermission(AclPermission.ViewRemoteDevices)
            },
            {
                label: 'Mappa dispositivi', url: Routing.devicesMap.devicesList(), icon: 'fa fa-diagram-project',
                target: '_blank',
                visible: this.sessionStorage.hasPermission(AclPermission.ViewDevicesMap)
            },
            {
                label: 'CDC', url: getMftwebUrl('/cdc/cdc.php'),
                target: '_blank',
                visible: this.sessionStorage.hasPermission(AclPermission.SendCreditCardLink)
            },
            {
                label: 'Fatture',
                url: getMftwebUrl('/Fatture/Fatture.php'),
                // routerLink: Routing.invoices.list(), // TODO
                target: '_blank',
                visible: this.sessionStorage.hasPermission(AclPermission.ViewActiveInvoices)
            },
            {
                label: 'Fatture pass.', url: getMftwebUrl('/Acquisti/Acquisti.php'),
                target: '_blank',
                visible: this.sessionStorage.hasPermission(AclPermission.ViewPassiveInvoices)
            },
            {
                label: 'Insoluti', url: getMftwebUrl('/Insoluti/Insoluti.php'),
                target: '_blank',
                visible: this.sessionStorage.hasPermission(AclPermission.UnpaidManagement)
            },
            {
                label: 'Impostazioni', url: getMftwebUrl('/Props/Props.php'),
                target: '_blank',
                visible: this.sessionStorage.hasPermission(AclPermission.OldSettings)
            },
            {
                label: 'Gestione permessi (ACL)',
                routerLink: Routing.acl.list(),
                icon: 'fa fa-folder',
                visible: this.sessionStorage.hasPermission(AclPermission.AclManagement)
            },
            {
                label: 'Queue Jobs',
                routerLink: Routing.queueJobs.getBasePath(),
                icon: 'fa fa-folder',
                visible: this.sessionStorage.hasPermission(AclPermission.QueueList)
            },
            {
                label: 'Playground',
                routerLink: Routing.playground.getBasePath(),
                icon: 'fa-solid fa-cat',
                visible: this.sessionStorage.hasPermission(AclPermission.AclPlayground)
            },
            {
                label: 'DDNS - Storico',
                routerLink: Routing.dynamicIpHistory.list(),
                icon: 'fa-solid fa-house-signal',
                visible: this.sessionStorage.hasPermission(AclPermission.ReadDynamicDns)
            },
            {
                label: 'Diario', url: getMftwebUrl('/Diary/Diary.php'),
                target: '_blank',
                visible: this.sessionStorage.hasPermission(AclPermission.Diary)
            },
            {
                label: 'TODO', url: getMftwebUrl('/Todo/Todo.php'),
                target: '_blank',
                visible: this.sessionStorage.hasPermission(AclPermission.Todo)
            },
            {
                label: 'easyCAST',
                target: '_blank',
                visible: true, // TODO: permessi
                icon: 'fa fa-rocket',
                routerLink: Routing.easyCastContracts.list()
            },
            {
                label: 'Log', url: getMftwebUrl(`/Storico/Log.php`),
                target: '_blank',
                visible: this.sessionStorage.hasPermission(AclPermission.Logs)
            }
        ] as MenuItem[];
    }

    protected populateBottomMenu() {
        this.bottomMenu = [
            {
                label: 'Impostazioni utente',
                visible: this.sessionStorage.hasPermission(AclPermission.UserLogged),
                // routerLink: Routing.userSettings.getBasePath(),
                command: () => {
                    UserSettingsComponent.openDialog(this.dialogService);
                },
                icon: 'fas fa-user'
            },
            {
                label: 'Logout', command: () => {
                    this.sessionStorage.reset();
                    openMftwebUrl('/Include/Login/Logout.php?___redirect_to_phoenix=login');
                },
                icon: 'fa-solid fa-right-from-bracket'
            }
        ] as MenuItem[];
    }


    protected createOldVersionLink() {
        let activeRoute: ActivatedRouteSnapshot = this.route.snapshot;
        // Dalla route che mappa il layout component raggiungo il discendente
        while (activeRoute.firstChild) {
            activeRoute = activeRoute.firstChild;
        }

        // recupera i dati del discendente
        const data = activeRoute.data as RouteData;
        let pageUrl;
        if (data.mftWebPage !== undefined) {
            if (typeof data.mftWebPage === 'string') {
                pageUrl = getMftwebUrl(data.mftWebPage);
            } else {
                pageUrl = getMftwebUrl(data.mftWebPage(activeRoute));
            }
        }

        if (data.title !== undefined) {
            this.titleService.setTitle(data.title);
        }

        if (pageUrl !== undefined) {
            // modifica l'url in modo da mappare i singoli parametri
            const mapParams = ([key, val]: [string, any]) => pageUrl = pageUrl.replace(`:${key}`, val);
            Object.entries(activeRoute.queryParams).forEach(mapParams);
            Object.entries(activeRoute.params).forEach(mapParams);
        }
        this.mftWebOldPage = pageUrl;
    }


    @HostListener('window:resize', ['$event'])
    onWindowResize() {
        this.resizeService.resize();
    }

}
