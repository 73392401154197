<div class="inline-block mr-3" *appAcl="permissions.SwitchUser">
    <app-dropdown label="" [(model)]="selectedUser" [dropdownItems]="users" optionLabel="fullName"
                  [disabled]="canRestore"
                  inputWidth="auto"
                  [showFilter]="true"
                  [multiple]="false"></app-dropdown>
    <button class="p-button p-button-sm" [disabled]="canRestore" (click)="switch()">Cambia<br>Utente</button>
</div>
<div class="inline-block pr-3" *ngIf="canRestore">
    <button
        pButton
        pTooltip="Ripristina l'utente originale"
        icon="pi pi-sign-out"
        tooltipPosition="left"
        class="p-button-danger p-button-outlined"
        (click)="restore()"></button>
</div>
<button
    *appAcl="permissions.SwitchUser"
    pButton
    pTooltip="Refresh del proptio token"
    icon="pi pi-refresh"
    tooltipPosition="left"
    class="p-button-help p-button-outlined mr-3"
    (click)="refresh()"></button>
