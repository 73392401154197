import {Injectable, Injector} from '@angular/core';

// import translationDefinitionIta from '../../lang/structured/it';


export function GenericClass<Props>(): new () => Props {
    return class {
    } as any;
}

function concatIfExistsPath(path: string, suffix: string): string {
    return path ? `${path}.${suffix}` : suffix;
}

function transformObjectToPath<T extends object | string>(
    suffix: string,
    objectToTransformOrEndOfPath: T,
    path = ''
): T {
    // console.log({objectToTransformOrEndOfPath});
    // console.log({'type': typeof objectToTransformOrEndOfPath});
    return typeof objectToTransformOrEndOfPath === 'object' && objectToTransformOrEndOfPath != null
        ? Object.entries(objectToTransformOrEndOfPath).reduce(
            (objectToTransform: any, [key, value]) => {
                // console.log(`reducing [${key}] [${value}]`);
                objectToTransform[key] = transformObjectToPath(
                    key,
                    value,
                    concatIfExistsPath(path, suffix)
                );

                return objectToTransform;
            },
            {} as T
        )
        : (concatIfExistsPath(path, suffix) as T);
}

@Injectable()
export class StringifierService {
    constructor() {
    }

    getStringifier<T>(type: T|any, level: number = 5): T {
        return transformObjectToPath('', type.getStringifier(level));
    }

    getStringifierFromInstance<T extends object>(instance: T): T {
        return transformObjectToPath('', instance);
    }

}

// TODO: teoricamente sarebbe sensato condividerla con il prj dello schematic..pero` faccio prima a fare cosi`
// @ts-ignore
export function Stringifier(constructor: Function) {
}
