import {Type} from 'class-transformer';

export class QueueJobStatus {
    queueId: string;
    category: string;
    running: boolean;
    jobType: StorableQueueJobType;

    jobTypeClassFallback: string;

    @Type(() => Date)
    createdAt: Date;

    @Type(() => Date)
    startedAt: Date;

    @Type(() => Date)
    completedAt: Date;

    jsonInputData: {
        [key: string]: any;
        queueId: string;
        queueCategory: string;
        storeEnable: boolean;
    };

    hasError: boolean;
    errorMessage: string;
    errorStackTrace: string;

    getJobTypeClass(): string {
        const regex = /\.(?<title>\w+)QueueJob/;
        if (this.jobTypeClassFallback && regex.exec(this.jobTypeClassFallback)) {
            return regex.exec(this.jobTypeClassFallback)?.groups['title'];
        }
        return StorableQueueJobType[this.jobType];
    }
}

enum StorableQueueJobType {
    NotStorable = 0,
    Test = 1,
    MailSender = 2
}
