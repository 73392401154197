import {PartialRouting} from '../partial-routing';
import {RoutingConstants as rc, RoutingConstants} from '../routing-constants';
const {params} = RoutingConstants;

export class RoutingClients extends PartialRouting {
    constructor(base: string = '') {
        super(base);
    }

    list() {
        return this.base;
    }

    byClientId(clientId: number | string) {
        return `${this.base}${clientId}`;
    }

    // TODO: in futuro da rimuovere e rimpiazzare con byContractId
    byContractLoginName(clientId: number | string, loginName: string) {
        const queryPath = `${params.tickets.contractLoginName}=${loginName}`;
        return `${this.base}${clientId}?${queryPath}`;
    }

    byContractId(clientId: number | string, contractId: number) {
        return `${this.base}${clientId}?contractId=${contractId}`;
    }

    listContracts() {
        return `${this.base}` + rc.contracts;
    }

    /**
     * Pagina per modificare dati configurazione dell' IP e DDNS
     * @param contractId
     * @param loginName TODO: da rimuovere quando verrà dismesso MFTWEB. Usato solo per retrocompatibilità
     */
    contractRouterIpDdnsParameters(contractId: number | string, loginName?: string) {
        const baseUrl = `${this.base}` + rc.contractParameters + `/${contractId}`;
        const queryString = loginName ? `?${rc.params.loginName}=${loginName}` : ``;
        return baseUrl + queryString;
    }
}
