import {PartialRouting} from '../partial-routing';
import {RoutingConstants} from '../routing-constants';
const {params} = RoutingConstants;

export class RoutingTickets extends PartialRouting {
    constructor(base: string = '') {
        super(base);
    }

    list() {
        return this.getBasePath();
    }

    listByFiscalName(fiscalName: string) {
        const encodedParams = `${params.tickets.clientRagioneSociale}=${encodeURIComponent(fiscalName)}`;
        return `${this.base}?${encodedParams}`;
    }

    new(clientId?: number, loginName?: string) {
        let urlParams = '';
        if (clientId) {
            urlParams += `${params.tickets.clientId}=${clientId}`;
        }
        if (loginName) {
            urlParams += `${params.tickets.contractLoginName}=${loginName}`;
        }
        return (!urlParams) ? `${this.base}new` : `${this.base}new?${urlParams}`;
    }

    byIdGroup(idGroup: string | number) {
        return `${this.base}${idGroup}`;
    }

    contentSearch(searchText?: string) {
        let urlParams = '';
        if (searchText) {
            urlParams += `${params.tickets.searchText}=${searchText}`;
        }
        return (!urlParams) ? `${this.base}contentSearch` : `${this.base}contentSearch?${urlParams}`;
    }

    printByIdGroup(idGroup: string | number) {
        return `${this.base}${idGroup}/print`;
    }
}
