import {Type} from 'class-transformer';
import {Stringifier} from '../../services/stringifier.service';
import {EasyCastCreditPack} from './easy-cast-credit-pack';

// tslint:disable-next-line:no-empty-interface
export interface IPlaybackDevice extends PlaybackDevice {
}

@Stringifier
export class PlaybackDevice {
    playbackDeviceId: number;
    name: string;
    active: boolean;
    alive: boolean;

    // public DateTime? PurchaseDate { get; set; }
    // public string Supplier { get; set; } = string.Empty;
    // public bool PowerTest { get; set; }
    // public bool UsbTest { get; set; }
    // public bool TestResult { get; set; }
    // public bool Tester { get; set; }

    @Type(() => Date)
    purchaseDate: Date;
    supplier: string;
    powerTest: boolean;
    usbTest: boolean;
    testResult: boolean;
    tester: boolean;
    macAddress: string;
    ipAddress: string;
    lastTimeOnline: Date;
    reflectorDeviceId: number;
    playbackDeviceGroupId: number;

    @Type(() => EasyCastCreditPack)
    creditPacks: EasyCastCreditPack[];

    get remainingCredit(): number {
        return this.creditPacks.reduce((acc, creditPack) => acc + (creditPack.credits - creditPack.usagesCount), 0);
    }

    get creditExpiryDate(): Date {
        // Torno la scadenza più vicina anche se, in teoria, dovrebbero essere tutte uguali
        return this.creditPacks.reduce((acc: Date | null, creditPack) => {
            if (!acc || creditPack.expiresAt < acc) {
                return creditPack.expiresAt;
            }
            return acc;
        }, null);
    }

    static getStringifier(level: number) {
        if (level < 0) {
            return null;
        }
        return {
            playbackDeviceId: null,
            name: null,
            active: null,
            alive: null,
            purchaseDate: null,
            supplier: null,
            powerTest: null,
            usbTest: null,
            testResult: null,
            tester: null,
            macAddress: null,
            ipAddress: null,
            lastTimeOnline: null,
            reflectorDeviceId: null,
            playbackDeviceGroupId: null,
            remainingCredit: null,
            creditExpiryDate: null,
        } as PlaybackDevice;
    }
}

interface PlaybackDeviceCreationStatus {
    label: string;
    icon: `fa-${string}`;
    severity: 'info' | 'success' | 'warning' | 'error';
}

export const textSeverityColor: Record<PlaybackDeviceCreationStatus['severity'], string> = {
    error: 'text-red-500',
    info: 'text-cyan-600',
    success: 'text-green-500',
    warning: 'text-orange-500'
} as const;

export const playbackDeviceCreationStatus: Record<string, PlaybackDeviceCreationStatus> = {
    new: {label: 'New', severity: 'info', icon: 'fa-circle-plus'},
    existing: {label: 'Existing', severity: 'success', icon: 'fa-circle-check'},
    invalid: {label: 'Invalid', severity: 'error', icon: 'fa-circle-xmark'},
} as const;
