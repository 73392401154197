import {Type} from 'class-transformer';

export class TicketCheckClosure {

    id?: number;
    idGroup: number;

    @Type(() => Date)
    dataCheckChiusura: Date;
    inviaMessaggioCliente: boolean;
    annullato: boolean;
    eseguito: boolean;

}
