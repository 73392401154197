<div class="flex flex-column">
    <div class="col-12">

        <p-panel header="Code presenti a sistema">
            <ng-template pTemplate="icons">
                <button pButton class="p-button" (click)="loadData()">Aggiorna stato code</button>
                <button pButton class="p-button-warning" (click)="runTestJob()">Avvia Test Job</button>
            </ng-template>
            <ng-template pTemplate="body">
                <p-blockUI [target]="content" [blocked]="loading"></p-blockUI>
                <app-blockable-div #content>
                    <p-accordion [multiple]="true">
                        <p-accordionTab *ngFor="let category of categories" [header]="category">
                            <p-table
                                styleClass="p-datatable p-datatable-striped p-datatable-sm p-datatable-gridlines"
                                [value]="getRows(category)"
                            >
                                <ng-template pTemplate="header">

                                    <tr>
                                        <th>Tipo</th>
                                        <th>Job</th>
                                        <th>Categoria</th>
                                        <th>Creato</th>
                                        <th>Avviato</th>
                                        <th>Terminato</th>
                                        <th>Stato</th>
                                        <th>Errore</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-item>
                                    <tr>
                                        <td>{{obj(item).jobType == 0 ? 'Volatile' : 'Persistente'}}</td>
                                        <td>{{obj(item).getJobTypeClass()}}</td>
                                        <td>{{obj(item).category}}</td>
                                        <td>{{obj(item).createdAt | date: 'dd/MM/yyyy HH:mm:ss'}}</td>
                                        <td>{{obj(item).startedAt | date: 'dd/MM/yyyy HH:mm:ss'}}</td>
                                        <td>{{obj(item).completedAt | date: 'dd/MM/yyyy HH:mm:ss'}}</td>
                                        <td>
                                            <i class="fa fa-clock" *ngIf="!obj(item).startedAt"></i>
                                            <i class="fas fa-person-running" *ngIf="obj(item).running"></i>
                                            <i class="fas fa-check"
                                               *ngIf="obj(item).completedAt && !obj(item).hasError"></i>
                                            <i class="fa fa-times" *ngIf="obj(item).hasError"></i>
                                        </td>
                                        <td>
                                            {{obj(item).errorMessage}}<br>
                                            <button pButton class="p-button-text" *ngIf="obj(item).errorStackTrace"
                                                    (click)="showStackTrace(item)">
                                                <i class="pr-2 fa fa-eye"></i>Mostra stack trace
                                            </button>
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </p-accordionTab>
                    </p-accordion>
                    <div *ngIf="categories.length == 0">
                        Nessuna coda ancora generata a sistema
                    </div>
                </app-blockable-div>
            </ng-template>
        </p-panel>

    </div>
</div>
