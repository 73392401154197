import {FString} from '../../services/api/filters/string-filter';
import {Stringifier} from '../../services/stringifier.service';
import {BaseFilterRequest} from './base-filter-request';

@Stringifier
export class FilterNetworkInterfaceTemplateRequest extends BaseFilterRequest {
    name?: FString;

    sequentialRequestId?: number;

    static getStringifier(level: number) {
        if (level < 0) {
            return null;
        }
        return {
            name: null,
            sequentialRequestId: null,
        } as FilterNetworkInterfaceTemplateRequest;
    }
}
