import {Component, OnInit} from '@angular/core';
import {RestApiService} from '../../../services/api';
import {AclPermission} from '../../../models/acl/acl-group';
import {Message} from 'primeng/api';
import {SessionStorageService} from '../../../services/session-storage.service';
import {Router} from '@angular/router';
import {Routing} from '../../../models/routing/routing';

@Component({
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

    AclPermission = AclPermission;

    messages: Message[] = [];

    constructor(private api: RestApiService, private router: Router, private storage: SessionStorageService) {
        if (storage.hasPermission(AclPermission.ViewTicketsList)) {
            this.router.navigate([Routing.tickets.list()]);
        } else if (storage.hasPermission(AclPermission.ViewAssociatedClientsList)
            || storage.hasPermission(AclPermission.ViewAllAssociatedClientsList)) {
            this.router.navigate([Routing.resellerAgents.agentClients()]);
        }
    }

    async ngOnInit() {
        // await this.router.navigateByUrl(Routing.tickets.list());
        // const request = getFilterRequest<FilterTicketRequest>({
        //     rows: 0,
        // } as LazyLoadEvent);
        //
        // request.unread = {
        //     values: [0],
        //     filterType: IntFilters.GreaterThan
        // };
        //
        // const userData = getUserData();
        // if (userData) {
        //     request.owners = {
        //         ruleOperation: RuleOperation.And,
        //         filters: [{
        //             values: [userData.codice, ...getUserData().userGroups.map(ug => ug.groupLabel)],
        //             filterType: StringFilters.Contains
        //         }]
        //     };
        // }
        //
        // const response = await this.api.tickets.tickets(request);
        // this.messages = [response.data.count > 0
        //     ? {
        //         severity: 'warn',
        //         detail: `Hai ${response.data.count} ticket con dei messaggi da leggere`
        //     }
        //     : {
        //         severity: 'success',
        //         detail: 'Nessun ticket con messaggi da leggere'
        //     }
        // ];
    }

}
