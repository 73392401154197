import {BaseService, Body, DELETE, GET, Headers, Path, POST, Query, Response} from 'ts-retrofit';
import {ACCEPT_JSON} from '../config';
import {FilterClientRequest} from '../../../models/requests/filter-client-request';
import {ListedResponse} from '../../../models/hydratator/listed-response';
import {ToData, ToListedResponse} from '../rest-api-hydratator-decorators';
import {ClientFlag} from '../../../models/clients/client-flag';
import {FilterClientFlagRequest} from '../../../models/requests/filter-client-flag-request';
import {ClientFlagAssociation} from '../../../models/clients/client-flag-association';

const base = 'clientFlags';

export class ClientFlagsService extends BaseService {

    @POST(`/${base}/filter`)
    @Headers({...ACCEPT_JSON})
    @ToListedResponse(ClientFlag)
    async filter(@Body request: FilterClientFlagRequest): Promise<Response<ListedResponse<ClientFlag>>> {
        return <Response>{};
    }

    @GET(`/${base}`)
    @Headers({...ACCEPT_JSON})
    @ToData(ClientFlag)
    async getAll(@Query('onlyActive') onlyActive: boolean = false): Promise<Response<ClientFlag[]>> {
        return <Response>{};
    }

    @GET(`/${base}/{flagId}`)
    @Headers({...ACCEPT_JSON})
    @ToData(ClientFlag)
    async getById(@Path('flagId') flagId: number): Promise<Response<ClientFlag>> {
        return <Response>{};
    }

    @POST(`/${base}`)
    @Headers({...ACCEPT_JSON})
    @ToData(ClientFlag)
    async save(@Body flag: ClientFlag): Promise<Response<ClientFlag>> {
        return <Response>{};
    }

    @POST('/clients/{clientId}/flags')
    @Headers({...ACCEPT_JSON})
    @ToData(ClientFlagAssociation)
    async saveFlagToClient(@Path('clientId') clientId: number, @Body flag: ClientFlagAssociation)
        : Promise<Response<ClientFlagAssociation>> {
        return <Response>{};
    }

    @DELETE('/clients/{clientId}/flags/{associationId}')
    @Headers({...ACCEPT_JSON})
    @ToData(ClientFlagAssociation)
    async deleteFlagFromClient(@Path('clientId') clientId: number, @Path('associationId') associationId: number)
        : Promise<Response<ClientFlagAssociation>> {
        return <Response>{};
    }


}
