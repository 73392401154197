import {Component, OnInit} from '@angular/core';

import packageJson from '../../../../package.json';


@Component({
    selector: 'app-version',
    templateUrl: './version.component.html',
    styleUrls: ['./version.component.scss']
})
export class VersionComponent implements OnInit {
    version: string = packageJson.version;

    constructor() {
    }

    ngOnInit(): void {
    }

}
