import {RouterModule} from '@angular/router';
import {DashboardComponent} from './views/intranet/dashboard/dashboard.component';
import {FlagTemplatesListComponent} from './views/intranet/flags/flag-templates-list/flag-templates-list.component';
import {LoginComponent} from './views/login/login.component';
import {AclPolicy} from './guards/authentication.guard';
import {LayoutComponent} from './views/intranet/layout/layout.component';
import {RoutesWithPolicy} from './guards/route-data';
import {UnauthViewComponent} from './views/intranet/layout/unauth-view/unauth-view.component';
import {PlaygroundComponent} from './views/intranet/playground/playground.component';
import {AclPermission} from './models/acl/acl-group';
import {Routing} from './models/routing/routing';
import {UserSettingsComponent} from './views/intranet/user-settings/user-settings.component';
import {TwoFactorsAuthenticationComponent} from './views/two-factor-authentication/two-factors-authentication.component';
import {QueueJobsListComponent} from './views/intranet/queue-jobs/queue-jobs-list/queue-jobs-list.component';
import {MftwebComponent} from './views/mftweb/mftweb/mftweb.component';
import {FastDialNumbersListComponent} from './views/intranet/fast-dial-numbers-list/fast-dial-numbers-list.component';

export const routes: RoutesWithPolicy[] = [
    {path: 'login', component: LoginComponent},
    {path: Routing.twoFactorsAuthentication.getBasePath(), component: TwoFactorsAuthenticationComponent},
    {
        path: '', redirectTo: '/dashboard', pathMatch: 'full'
    },
    {
        path: '', component: LayoutComponent,
        children: [
            {
                path: Routing.dashboard.getBasePath(), component: DashboardComponent,
                data: {mftWebPage: '/index.php', acl: [AclPermission.UserLogged]},
                canActivate: [AclPolicy],
            },
            {
                path: Routing.notAuthorized.getBasePath(), component: UnauthViewComponent,
                data: {title: 'Non autorizzato - Phoenix'}
            },
            {
                path: Routing.playground.getBasePath(), component: PlaygroundComponent,
                canActivate: [AclPolicy],
                data: {acl: [AclPermission.AclPlayground], title: 'Playground - Phoenix'}
            },
            {
                path: Routing.flagTemplates.getBasePath(), component: FlagTemplatesListComponent,
                data: {title: 'Gestione flag - Phoenix'},
            },
            {
                path: Routing.dynamicIpHistory.getBasePath(),
                loadChildren: () => import('./views/intranet/dynamic-ip/dynamic-ip.module').then(m => m.DynamicIpModule),
            },
            {
                path: Routing.userSettings.getBasePath(),
                component: UserSettingsComponent,
                data: {title: 'Impostazioni utente - Phoenix'}
                // canActivate: [AclPolicy],
                // data: {acl: [AclPermission.AclPlayground]}
            },
            {
                path: Routing.queueJobs.getBasePath(),
                component: QueueJobsListComponent,
                canActivate: [AclPolicy],
                data: {
                    title: 'Queue jobs list - Phoenix',
                    acl: [AclPermission.QueueList]
                },
            },
            {
                path: Routing.remoteDevices.getBasePath(),
                loadChildren: () => import('./views/intranet/remote-devices/remote-devices.module').then(m => m.RemoteDevicesModule),
            },
            {
                path: Routing.fastDialNumbers.getBasePath(),
                component: FastDialNumbersListComponent,
                canActivate: [AclPolicy],
                data: {
                    title: 'Lista numeri di chiamata rapida - Phoenix',
                    acl: [AclPermission.EditFastDialNumbers]
                },
            },
            {
                path: Routing.tickets.getBasePath(),
                loadChildren: () => import('./views/intranet/tickets/tickets.module').then(m => m.TicketsModule),
            },
            {
                path: Routing.invoices.getBasePath(),
                loadChildren: () => import('./views/intranet/invoices/invoices.module').then(m => m.InvoicesModule),
            },
            {
                path: Routing.resellerAgents.getBasePath(),
                loadChildren: () => import('./views/intranet/reseller-agents/reseller-agents.module').then(m => m.ResellerAgentsModule),
            },
            {
                path: Routing.acl.getBasePath(),
                loadChildren: () => import('./views/intranet/acl/acl.module').then(m => m.AclModule),
            },
            {
                path: Routing.easyCast.getBasePath(),
                loadChildren: () => import('./views/intranet/easycast/easycast.module').then(m => m.EasycastModule),
            },
            {
                path: Routing.clients.getBasePath(),
                loadChildren: () => import('./views/intranet/clients-contracts/clients-contracts.module')
                    .then(m => m.ClientsContractsModule),
            },
            {
                path: Routing.devicesMap.getBasePath(),
                loadChildren: () => import('./views/intranet/devices-map/devices-map.module').then(m => m.DevicesMapModule),
            }
        ]
    },
    {path: Routing.mftweb.getBasePath(), component: MftwebComponent},
    {path: '**', redirectTo: Routing.dashboard.getBasePath()}
];

export const AppRoutes = RouterModule.forRoot(routes, { enableTracing: false });
