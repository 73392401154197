import {BaseService, Body, DELETE, Headers, Path, POST, Response} from 'ts-retrofit';
import {ACCEPT_JSON} from '../../config';
import {TicketGroupRdp} from '../../../../models/tickets/ticket-group-rdp';
import {ticketsForm, rdps} from './ticket-form-service-constants';

export class TicketRdpService extends BaseService {

    @POST(`/${ticketsForm}/{idGroup}/${rdps}`)
    @Headers({...ACCEPT_JSON})
    async saveRDP(@Path('idGroup') idGroup: number, @Body rdp: TicketGroupRdp): Promise<Response<boolean>> {
        return <Response>{};
    }

    @DELETE(`/${ticketsForm}/{idGroup}/${rdps}`)
    @Headers({...ACCEPT_JSON})
    async deleteRdp(@Path('idGroup') idGroup: number): Promise<Response> {
        return <Response>{};
    }

}
