import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AclRequiredDirective} from './acl-required.directive';
import {CopyableDirective} from './copyable.directive';
import {AclMissingDirective} from './acl-missing.directive';
import {ResponsiveDirective} from './responsive.directive';
import {ContractStateColorDirective} from './contract-state-color.directive';
import {TemplateDrivenFormFocusInvalidDirective} from './template-driven-form-focus-invalid.directive';

@NgModule({
    declarations: [
        AclRequiredDirective,
        AclMissingDirective,
        CopyableDirective,
        ResponsiveDirective,
        ContractStateColorDirective,
        TemplateDrivenFormFocusInvalidDirective,
    ],
    exports: [
        AclRequiredDirective,
        AclMissingDirective,
        CopyableDirective,
        ResponsiveDirective,
        ContractStateColorDirective,
        TemplateDrivenFormFocusInvalidDirective
    ],
    imports: [
        CommonModule
    ]
})
export class DirectivesModule {
}
