import {Component, OnInit} from '@angular/core';
import {PrimeNGConfig} from 'primeng/api';
import {UserSettingsService} from './services/user-settings.service';
import italianTranslation from './translations/it.json';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {

    constructor(private primengConfig: PrimeNGConfig, private userSettings: UserSettingsService) {
    }

    ngOnInit(): void {
        this.primengConfig.ripple = true;
        const theme = this.userSettings.getTheme();
        this.userSettings.applyTheme(theme);

        this.primengConfig.setTranslation(italianTranslation.it);

    }

}
