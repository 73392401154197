<div class="notification-message flex justify-content-between">
    <div class="mr-3 flex-grow-1" [ngSwitch]="notification.notificationType">
        <app-calendar-notification *ngSwitchCase="notificationTypes.CalendarEvent"
                                   [notification]="notification"></app-calendar-notification>
        <app-base-notification *ngSwitchDefault [notification]="notification"></app-base-notification>
    </div>
    <div>
        <button pButton type="button" icon="pi pi-times"
                class="p-button-text"
                pTooltip="Segna come letta"
                [loading]="isSettingRead"
                (click)="setRead()"
        ></button>
    </div>
</div>
