import {BaseService, Body, DELETE, Headers, PATCH, Path, POST, Response} from 'ts-retrofit';
import {ACCEPT_JSON} from '../config';
import {ToData} from '../rest-api-hydratator-decorators';
import {User} from '../../../models/user';
import {CreateTicketChatMessageRequest} from '../../../models/requests/ticket-form/create-ticket-chat-message-request';
import {TicketChat} from '../../../models/tickets/ticket-chat';
import {ticketsForm, alarms, tickets} from './ticket-form/ticket-form-service-constants';

const base = 'ticketChat';

export class ChatMessagesApiService extends BaseService {

    /**
     * @deprecated
     * @param messageId
     */
    @DELETE(`/${base}/{chatMessageId}`)
    @Headers({...ACCEPT_JSON})
    async deleteById(@Path('chatMessageId') messageId: number): Promise<Response<boolean>> {
        return <Response>{};
    }

    /**
     * Crea nuova ticket chat
     * @param request
     */
    @POST(`/${base}`)
    @Headers({...ACCEPT_JSON})
    @ToData(TicketChat)
    async createMessageChat(@Body request: CreateTicketChatMessageRequest): Promise<Response<TicketChat>> {
        return <Response>{};
    }

    /**
     * Demarca una specifica ticket chat come letta
     * @param messageId
     */
    @PATCH(`/${base}/{messageId}/quiet`)
    @Headers({...ACCEPT_JSON})
    async quietTicketChatNotification(@Path('chatMessageId') messageId: number): Promise<Response<boolean>> {
        return <Response>{};
    }

    /**
     * Demarca tutte le ticket chat come lette
     * @param ticketgroupId
     */
    @PATCH(`/${tickets}/{ticketGroupId}/chats/quiet`)
    @Headers({...ACCEPT_JSON})
    async quietAllTicketChatNotification(@Path('ticketGroupId') ticketGroupId: number): Promise<Response<boolean>> {
        return <Response>{};
    }

}

