import {BaseService, Body, GET, Headers, PATCH, Path, Response} from 'ts-retrofit';
import {ACCEPT_JSON} from '../config';
import {BodyRequest} from '../../../models/body-request';
import {InvoiceXmlContentResponse} from '../../../models/invoices/invoice-xml-content-response';
import {ToData} from '../rest-api-hydratator-decorators';

const base = 'invoices';

export class InvoicesApiService extends BaseService {

    @GET(`/${base}/{invoiceId}/xml`)
    @Headers({...ACCEPT_JSON})
    @ToData(InvoiceXmlContentResponse)
    async getInvoiceXml(@Path('invoiceId') invoiceId: number): Promise<Response<InvoiceXmlContentResponse>> {
        return <Response>{};
    }

    @PATCH(`/${base}/{invoiceId}/xml`)
    @Headers({...ACCEPT_JSON})
    async updateInvoiceXml(@Path('invoiceId') invoiceId: number, @Body content: BodyRequest<string>): Promise<Response<boolean>> {
        return <Response>{};
    }
}
