import {Type} from 'class-transformer';
import {B, IB} from './b';

// tslint:disable-next-line:no-empty-interface
export interface IA extends A {}
export class A {
    @Type(() => B)
    b: IB;

    name: string;

    @Type(() => Date)
    data: Date;

    getName() {
        return 'Nome of A: ' + this.name;
    }
}
