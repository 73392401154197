import {PartialRouting} from '../partial-routing';

export class RoutingDevicesMap extends PartialRouting {
    constructor(base: string = '') {
        super(base);
    }

    devicesList() {
        return this.base;
    }

    deviceTemplatesList() {
        return this.base + 'templates';
    }

    networkInterfaceTemplatesList() {
        return this.base + 'network-interface-templates';
    }

    testMap() {
        return this.base + 'test-map';
    }

    map(mapId: string | number) {
        return this.base + 'map/' + mapId;
    }

    clientMap(clientId: string | number) {
        return this.base + 'map/client/' + clientId;
    }
}
