export interface ThemeDescription {
    id: number;
    name: string;
    cssFilename: string;
    representativeColor: string;
    darkTheme: boolean;
}

export const defaultThemeDescriptions: ThemeDescription[] = [
    {
        id: 1,
        name: 'AZZURRO',
        cssFilename: 'dark-blue',
        representativeColor: '#64B5F6',
        darkTheme: true
    }, {
        id: 2,
        name: 'VERDE',
        cssFilename: 'dark-green',
        representativeColor: '#81C784',
        darkTheme: true
    }, {
        id: 3,
        name: 'ARANCIO',
        cssFilename: 'dark-orange',
        representativeColor: '#FFD54F',
        darkTheme: true
    }, {
        id: 4,
        name: 'VIOLA',
        cssFilename: 'dark-purple',
        representativeColor: '#b052c0',
        darkTheme: true
    }, {
        id: 5,
        name: 'AZZURRO',
        cssFilename: 'light-blue',
        representativeColor: '#0b7ad1',
        darkTheme: false
    }, {
        id: 6,
        name: 'VERDE',
        cssFilename: 'light-green',
        representativeColor: '#3d8c40',
        darkTheme: false
    }, {
        id: 7,

        name: 'ARANCIO',
        cssFilename: 'light-orange',
        representativeColor: '#d29d00',
        darkTheme: false
    }, {
        id: 8,
        name: 'VIOLA',
        cssFilename: 'light-purple',
        representativeColor: '#8c239e',
        darkTheme: false
    },
];
