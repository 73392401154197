import {PartialRouting} from '../partial-routing';
import {RoutingConstants} from '../routing-constants';

const {params} = RoutingConstants;

export class RoutingEasyCastUsers extends PartialRouting {
    constructor(base: string = '') {
        super(base);
    }

    list() {
        return this.getBasePath();
    }

    byUserId(id: string | number) {
        return `${this.base}${id}`;
    }

    new(parentUserId: number | null) {
        if (parentUserId === null) {
            return `${this.base}${params.newValue}`;

        }
        return `${this.base}${params.newValue}?${params.parentId}=${parentUserId}`;
    }

}
