import {BaseService, Body, GET, Headers, Path, POST, QueryMap, Response} from 'ts-retrofit';
import {ACCEPT_JSON} from '../config';
import {ListedResponse} from '../../../models/hydratator/listed-response';
import {FilterClientRequest} from '../../../models/requests/filter-client-request';
import {Client} from '../../../models/clients/client';
import {ToListedResponse} from '../rest-api-hydratator-decorators';

const base = 'agents';

export class AgentsService extends BaseService {

    @POST(`/${base}/clients`)
    @Headers({...ACCEPT_JSON})
    @ToListedResponse(Client)
    async clients(@Body request: FilterClientRequest): Promise<Response<ListedResponse<Client>>> {
        return <Response> {};
    }

    @GET(`/${base}/clients/count`)
    @Headers({...ACCEPT_JSON})
    @ToListedResponse(Client)
    async countClients(): Promise<Response<number>> {
        return <Response> {};
    }
}
