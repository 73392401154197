import {Type} from 'class-transformer';
import {Stringifier} from 'src/app/services/stringifier.service';
import {CustomFieldType, ICustomFieldType} from './CustomFieldType';
import {Device, IDevice} from './Device';

// tslint:disable-next-line:no-empty-interface
export interface IDeviceCustomFieldType extends DeviceCustomFieldType {
}

@Stringifier
export class DeviceCustomFieldType {
    deviceCustomFieldTypeId: number;
    value: string;

    deviceId: number;
    @Type(() => Device)
    device?: IDevice;

    customFieldTypeId: number;
    @Type(() => CustomFieldType)
    customFieldType?: ICustomFieldType;

    static getStringifier(level: number) {
        if (level < 0) {
            return null;
        }
        return {
            deviceCustomFieldTypeId: null,
            value: null,
            deviceId: null,
            device: Device.getStringifier(level - 1),
            customFieldTypeId: null,
            customFieldType: CustomFieldType.getStringifier(level - 1),
        } as DeviceCustomFieldType;
    }
}
