import {Stringifier} from '../../services/stringifier.service';
import {BaseFilterRequest} from './base-filter-request';
import {FilterClientRequest} from './filter-client-request';
import {FString} from '../../services/api/filters/string-filter';
import {FilterContractRequest} from './filter-contract-request';

@Stringifier
export class FilterLoginClientRequest extends BaseFilterRequest {
    contract?: FilterContractRequest;
    client?: FilterClientRequest;
    loginName?: FString;

    static getStringifier(level: number) {
        if (level < 0) {
            return null;
        }
        return {
            contract: FilterContractRequest.getStringifier(level - 1),
            client: FilterClientRequest.getStringifier(level - 1),
            loginName: null,
        } as FilterLoginClientRequest;
    }
}
