import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NotificationMessage, NotificationSeverity} from '../../../../models/notification-message';
import {ToastSeverity} from '../../../../services/toast.service';
import {RestApiService} from '../../../../services/api';

@Component({
    selector: 'app-notifications-list',
    templateUrl: './notifications-list.component.html',
    styleUrls: ['./notifications-list.component.scss']
})
export class NotificationsListComponent implements OnInit {

    @Input() notifications: NotificationMessage[] = [];

    @Output() readAll = new EventEmitter();

    isReadingAll = false;

    constructor(private api: RestApiService) {
    }

    ngOnInit(): void {
    }

    mapSeverity(severity: NotificationSeverity): ToastSeverity {
        switch (severity) {
            case NotificationSeverity.Info:
                return 'info';
            case NotificationSeverity.Warning:
                return 'warn';
            case NotificationSeverity.Error:
                return 'error';
            default:
                return 'info';
        }
    }

    readNotification({notificationId}: {notificationId: number}) {
        const index = this.notifications.findIndex(n => n.notificationId === notificationId);
        if (index >= 0) {
            this.notifications.splice(index, 1);
        }
    }

    async readAllNotifications() {
        this.isReadingAll = true;
        try {
            await this.api.notifications.readAllNotification();
        } finally {
            this.readAll.emit();
            this.isReadingAll = false;
        }
    }
}
