import {Component, EventEmitter, Input, Output} from '@angular/core';
import {NotificationMessage, NotificationType} from '../../../models/notification-message';
import {RestApiService} from '../../../services/api';

@Component({
    selector: 'app-notification',
    templateUrl: './notification.component.html',
    styleUrls: ['./notification.component.scss']
})
export class NotificationComponent {

    @Input() notification: NotificationMessage;

    notificationTypes = NotificationType;

    @Output()
    read = new EventEmitter<{notificationId: number}>();

    isSettingRead = false;

    constructor(private api: RestApiService) {
    }

    setRead() {
        this.isSettingRead = true;
        this.api.notifications
            .readNotification(this.notification.notificationId, {data: true})
            .then(response => this.read.next({notificationId: this.notification.notificationId}))
            .finally(() => this.isSettingRead = false);
    }
}
