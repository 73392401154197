import {Type} from 'class-transformer';

export class TicketClosure {
    id: number;

    idTicket: number;

    @Type(() => Date)
    chiusura: Date;
    user: string;
    tipo: TicketClosureType;
    aprireValutazioneComm: boolean;
    annullata: boolean;
    eseguita: boolean;

    @Type(() => Date)
    ts: Date;
}

export enum TicketClosureType {
    Technical = 'TEC',
    Administrative = 'COM'
}
