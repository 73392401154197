import {BaseService, Body, DELETE, GET, Headers, Path, POST, Response} from 'ts-retrofit';
import {ACCEPT_JSON} from '../config';
import {ToListedResponse} from '../rest-api-hydratator-decorators';
import {DynamicIp} from '../../../models/dynamic-ip/dynamic-ip';
import {FilterDynamicIpRequest} from '../../../models/requests/filter-dynamic-ip.request';
import {ListedResponse} from '../../../models/hydratator/listed-response';

const base = 'dynamicDns';

export class DynamicIpApiService extends BaseService {

    @POST(`/${base}/filter`)
    @Headers({...ACCEPT_JSON})
    @ToListedResponse(DynamicIp)
    async filter(@Body request: FilterDynamicIpRequest): Promise<Response<ListedResponse<DynamicIp>>> {
        return <Response>{};
    }

    @GET(`/${base}/dnsTable`)
    @Headers({...ACCEPT_JSON})
    @ToListedResponse(DynamicIp)
    async getDnsTable(): Promise<Response<ListedResponse<DynamicIp>>> {
        return <Response>{};
    }

    @DELETE(`/${base}/dnsTable/{hostname}`)
    @Headers({...ACCEPT_JSON})
    @ToListedResponse(DynamicIp)
    async removeDnsEntry(@Path('hostname') hostname: string): Promise<Response<ListedResponse<DynamicIp>>> {
        return <Response>{};
    }

    @GET(`/${base}/unique`)
    @Headers({...ACCEPT_JSON})
    async getUnique(): Promise<Response<string>> {
        return <Response>{};
    }
}
