<div class="container flex fluid">
    <div class="background col hidden md:flex md:col-8 xl:col-10">
        <img src="assets/images/logo-negative.png" alt="MFT Italia" class="logo">
    </div>
    <div class="login col content-section">
        <form #form='ngForm' class="login-form mb-3 fluid flex-column text-center" (ngSubmit)="login()">
            <h3>LOGIN</h3>
            <div class="field">
                <input pInputText name="username" type="text" placeholder="Username" required
                       class="inputfield w-full"
                       [(ngModel)]="username">
            </div>
            <div class="field">
                <input pInputText name="password" type="password" placeholder="Password" required
                       class="inputfield w-full"
                       [(ngModel)]="password">
            </div>
            <p-button type="submit" class="" [disabled]="form.invalid || formSubmitted"
                      [label]="!formSubmitted ? 'Accedi' : 'Accesso in corso...'"
            ></p-button>
        </form>
        <app-theme-chooser></app-theme-chooser>
    </div>
</div>
