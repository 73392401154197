import {Type} from 'class-transformer';
import {ClientTicketMessage} from './client-ticket-message';


export class ClientTicketMessageReadStatus {
    idMsg: number;

    idTicket: number;
    ip: string;
    nome: string;

    @Type(() => Date)
    ts: Date;
}
