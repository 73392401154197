<div class="p-2 flex flex-column align-items-stretch">
    <h2 class="pl-3">Gestione modelli flag</h2>
    <p-panel header="Lista modelli flag">
        <ng-template pTemplate="header" class="test">
            <app-action-button type="add" label="Crea template" (click)="openCreateDialog()"
                               class="ml-auto"></app-action-button>
        </ng-template>
        <p-table [value]="flagTemplatesList"
                 [totalRecords]="flagTemplatesList.length"
                 [rowHover]="true"
                 [loading]="loading"
                 dataKey="id"
                 editMode="row"
        >
            <ng-template pTemplate="header">
                <tr>
                    <th>Colore</th>
                    <th>Simbolo</th>
                    <th>Testo</th>
                    <th style="width: 1px">Azioni</th>
                </tr>
                <tr>
                    <th>-</th>
                    <th>-</th>
                    <th>
                        <app-table-string-filter [field]="ticketFlagTemplateStringifier.descr"></app-table-string-filter>
                    </th>
                    <th>-</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-flagTemplate let-editing="editing" let-ri="rowIndex">
                <tr [pEditableRow]="flagTemplate">
                    <td>
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <div style="display: flex">
                                    <input pInputText type="text" [(ngModel)]="flagTemplate.color" [maxLength]="6"
                                           [style.color]="'#'+flagTemplate.color"
                                           style="width:75px">
                                    <input pInputText type="color" [ngModel]="parseColor(flagTemplate.color)" required
                                           pTooltip="Seleziona un colore"
                                           (ngModelChange)="formatColor(flagTemplate, $event)">
                                </div>
                            </ng-template>
                            <ng-template pTemplate="output">
                                <span [style.color]="'#'+flagTemplate.color">{{flagTemplate.color}}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>
                    <td>
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <app-dropdown
                                    class="inline-block mr-3"
                                    [(model)]="flagTemplate.simbolo"
                                    [dropdownItems]="symbolsList"
                                    optionLabel="label"
                                    optionValue="value"
                                    [showFilter]="false"
                                    [editable]="true"
                                    [maxlength]="5"
                                    inputWidth="75px"
                                    [style.color]="'#'+flagTemplate.color"
                                ></app-dropdown>
                            </ng-template>
                            <ng-template pTemplate="output">
                                <span [style.color]="'#'+flagTemplate.color">{{flagTemplate.simbolo}}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>
                    <td>
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <input pInputText type="text" [(ngModel)]="flagTemplate.descr" required
                                       [style.color]="'#'+flagTemplate.color" style="width: 100%">
                            </ng-template>
                            <ng-template pTemplate="output">
                                <span [style.color]="'#'+flagTemplate.color">{{flagTemplate.descr}}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>
                    <td>
                        <div *ngIf="editing" class="action-buttons">
                            <p-button pSaveEditableRow icon="pi pi-check" label="Salva"
                                      (click)="onRowEditSave(flagTemplate)"
                                      styleClass="p-button-sm p-button-success"></p-button>
                            <p-button pCancelEditableRow icon="pi pi-times" label="Annulla"
                                      (click)="onRowEditCancel(flagTemplate, ri)"
                                      styleClass="p-button-sm ml-2"></p-button>
                        </div>
                        <div *ngIf="!editing" class="action-buttons">
                            <p-button pInitEditableRow icon="pi pi-pencil" label="Modifica"
                                      (click)="onRowEditInit(flagTemplate)"
                                      styleClass="p-button-sm"></p-button>
                            <p-button icon="pi pi-trash" label="Elimina"
                                      (click)="deleteFlagTemplate(flagTemplate.id, flagTemplate.descr)"
                                      styleClass="p-button-sm p-button-danger ml-2"></p-button>
                        </div>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </p-panel>
</div>
