export class ContractRouterParameters {
    acs2: string;
    ddns: string;
    ddnsPassword: string;
    ddnsTtlInSeconds: number;
    ddnsUsername: string;
    ipConfiguration: string;
    isEolo: boolean;
    loginName: string;
    managementPort: string;
    useHttps: boolean;
}
