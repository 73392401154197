import {Stringifier} from '../../services/stringifier.service';

@Stringifier
export class TicketFlagTemplate {
    id?: number;
    ordine?: number;
    simbolo: string;
    descr: string;
    color: string;

    static getStringifier(level: number) {
        if (level < 0) {
            return null;
        }
        return {
            id: null,
            ordine: null,
            simbolo: null,
            descr: null,
            color: null,
        } as TicketFlagTemplate;
    }
}
