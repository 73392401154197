import {RoutingConstants} from '../routing-constants';
import {PartialRouting} from '../partial-routing';

const {params} = RoutingConstants;

export class RoutingInvoices extends PartialRouting {
    constructor(base: string = '') {
        super(base);
    }

    list() {
        return this.getBasePath();
    }

    editXml(id: number | string) {
        return `${this.base}${id}/editor-xml`;
    }
}
