import {RoutingDevicesMap} from './implementations/routing-devices-map';
import {RoutingConstants as rc} from './routing-constants';
import {RoutingTickets} from './implementations/routing-tickets';
import {RoutingAcl} from './implementations/routing-acl';
import {RoutingDashboard} from './implementations/routing-dashboard';
import {RoutingResellerAgents} from './implementations/routing-reseller-agents';
import {PartialRouting} from './partial-routing';
import {RoutingRemoteDevices} from './implementations/routing-remote-devices';
import {RoutingInvoices} from './implementations/routing-invoices';
import {CoverageDetails} from './implementations/routing-coverage-details';
import {RoutingDynamicDns} from './implementations/routing-dynamic-dns';
import {RoutingClients} from './implementations/routing-clients';
import {RoutingEasyCastUsers} from './implementations/routing-easy-cast-users';
import {RoutingEasyCastLicenses} from './implementations/routing-easy-cast-licenses';
import {RoutingEasyCastContracts} from './implementations/routing-easy-cast-contracts';
import {RoutingEasyCastPlaybackDevices} from './implementations/routing-easy-cast-playback-devices';


export class Routing {
    static notAuthorized = new PartialRouting(rc.notAuthorized);
    static playground = new PartialRouting(rc.playground);
    static flagTemplates = new PartialRouting(rc.flagTemplates);
    static userSettings = new PartialRouting(rc.userSettings);
    static queueJobs = new PartialRouting(rc.queueJobs);

    static twoFactorsAuthentication = new PartialRouting(rc.twoFactorsAuthentication);
    static fastDialNumbers = new PartialRouting(rc.fastDialNumbers);
    static dashboard = new RoutingDashboard(rc.dashboard);
    static resellerAgents = new RoutingResellerAgents(rc.resellerAgents);
    static tickets = new RoutingTickets(rc.tickets);
    static invoices = new RoutingInvoices(rc.invoices);
    static remoteDevices = new RoutingRemoteDevices(rc.remoteDevices);
    static acl = new RoutingAcl(rc.acl);
    static dynamicIpHistory = new RoutingDynamicDns(rc.dynamicIpHistory);
    static mftweb = new RoutingAcl(rc.mftweb);
    static coverageDetails = new CoverageDetails(rc.coverageDetails);
    static clients = new RoutingClients(rc.clients);
    static devicesMap = new RoutingDevicesMap(rc.devicesMap);

    static easyCast = new PartialRouting(rc.easyCast);
    static easyCastUsers = new RoutingEasyCastUsers(`${rc.easyCast}/${rc.easyCastUsers}`);
    static easyCastLicenses = new RoutingEasyCastLicenses(`${rc.easyCast}/${rc.easyCastLicenses}`);
    static easyCastContracts = new RoutingEasyCastContracts(`${rc.easyCast}/${rc.easyCastContracts}`);
    static easyCastPlaybackDevices = new RoutingEasyCastPlaybackDevices(`${rc.easyCast}/${rc.easyCastPlaybackDevices}`);
}

