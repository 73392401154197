import {BrowserModule} from '@angular/platform-browser';
import {DEFAULT_CURRENCY_CODE, ErrorHandler, LOCALE_ID, NgModule} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AppComponent} from './app.component';
import {AppRoutes} from './app.routes';
import {DashboardComponent} from './views/intranet/dashboard/dashboard.component';
import {ScrollPanelModule} from 'primeng/scrollpanel';
import {ButtonModule} from 'primeng/button';
import {LoginComponent} from './views/login/login.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ExceptionHandler} from './handlers/exception.handler';
import {HttpClientModule} from '@angular/common/http';
import {LayoutComponent} from './views/intranet/layout/layout.component';
import {InputTextModule} from 'primeng/inputtext';
import {RippleModule} from 'primeng/ripple';
import {PasswordModule} from 'primeng/password';
import {ConfirmationService, MessageService} from 'primeng/api';
import {DialogModule} from 'primeng/dialog';
import {PanelModule} from 'primeng/panel';
import {TooltipModule} from 'primeng/tooltip';
import {ToastModule} from 'primeng/toast';
import {ToolbarModule} from 'primeng/toolbar';
import {TabMenuModule} from 'primeng/tabmenu';
import {SidebarModule} from 'primeng/sidebar';
import {PanelMenuModule} from 'primeng/panelmenu';
import {CardModule} from 'primeng/card';
import {TableModule} from 'primeng/table';
import {BadgeModule} from 'primeng/badge';
import {MenuModule} from 'primeng/menu';
import {MenubarModule} from 'primeng/menubar';
import {ToolbarComponent} from './views/intranet/layout/toolbar/toolbar.component';
import {TabViewModule} from 'primeng/tabview';
import {ComponentsModule} from './components/components.module';
import {PipesModule} from './pipes/pipes.module';
import {DialogService} from 'primeng/dynamicdialog';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {MenuDirective} from './views/intranet/layout/menu.directive';
import {DirectivesModule} from './directives/directives.module';
import {UnauthViewComponent} from './views/intranet/layout/unauth-view/unauth-view.component';
import {MessageModule} from 'primeng/message';
import {PlaygroundComponent} from './views/intranet/playground/playground.component';
import {registerLocaleData} from '@angular/common';
import localeIt from '@angular/common/locales/it';
import {MessagesModule} from 'primeng/messages';
import {SwitchUserComponent} from './views/intranet/layout/switch-user/switch-user.component';
import {DropdownModule} from 'primeng/dropdown';
import {StringifierService} from './services/stringifier.service';
import {StyleClassModule} from 'primeng/styleclass';
import {NotificationsListComponent} from './views/intranet/notifications/notifications-list/notifications-list.component';
import {NotificationsButtonComponent} from './views/intranet/notifications/notifications-button/notifications-button.component';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {VirtualScrollerModule} from 'primeng/virtualscroller';
import {QRCodeModule} from 'angular2-qrcode';
import {UserSettingsComponent} from './views/intranet/user-settings/user-settings.component';
import {TwoFactorsAuthenticationComponent} from './views/two-factor-authentication/two-factors-authentication.component';
import {AppDatePipe} from './pipes/app-date.pipe';
import {AppDurationPipe} from './pipes/app-duration.pipe';
import {QueueJobsListComponent} from './views/intranet/queue-jobs/queue-jobs-list/queue-jobs-list.component';
import {AccordionModule} from 'primeng/accordion';
import {BlockUIModule} from 'primeng/blockui';
import {MftwebComponent} from './views/mftweb/mftweb/mftweb.component';
import {MassiveTicketSenderComponent} from './views/intranet/playground/massive-ticket-sender/massive-ticket-sender.component';
import {FlagTemplatesListComponent} from './views/intranet/flags/flag-templates-list/flag-templates-list.component';
import 'chartjs-adapter-date-fns';
import {FlagTemplatesFormComponent} from './views/intranet/flags/flag-templates-form/flag-templates-form.component';
import {FastDialNumbersListComponent} from './views/intranet/fast-dial-numbers-list/fast-dial-numbers-list.component';

registerLocaleData(localeIt);

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutes,
        ReactiveFormsModule,
        ScrollPanelModule,
        ButtonModule,
        RippleModule,
        FormsModule,
        HttpClientModule,
        InputTextModule,
        PasswordModule,
        PanelModule,
        TooltipModule,
        DialogModule,
        ToastModule,
        ToolbarModule,
        TabMenuModule,
        SidebarModule,
        PanelMenuModule,
        CardModule,
        ToastModule,
        TableModule,
        BadgeModule,
        MenuModule,
        MenubarModule,
        TabViewModule,
        ComponentsModule,
        PipesModule,
        ConfirmDialogModule,
        DirectivesModule,
        MessageModule,
        MessagesModule,
        DropdownModule,
        StyleClassModule,
        OverlayPanelModule,
        VirtualScrollerModule,
        QRCodeModule,
        AccordionModule,
        BlockUIModule
    ],
    declarations: [
        AppComponent,
        DashboardComponent,
        LayoutComponent,
        ToolbarComponent,
        LoginComponent,
        MenuDirective,
        UnauthViewComponent,
        PlaygroundComponent,
        SwitchUserComponent,
        NotificationsListComponent,
        NotificationsButtonComponent,
        UserSettingsComponent,
        TwoFactorsAuthenticationComponent,
        QueueJobsListComponent,
        MftwebComponent,
        MassiveTicketSenderComponent,
        FlagTemplatesListComponent,
        FlagTemplatesFormComponent,
        FastDialNumbersListComponent,
    ],
    providers: [
        MessageService,
        DialogService,
        ConfirmationService,
        StringifierService,
        AppDatePipe,
        AppDurationPipe,
        {provide: LOCALE_ID, useValue: 'it-IT'},
        {provide: DEFAULT_CURRENCY_CODE, useValue: '€'},
        {provide: ErrorHandler, useClass: ExceptionHandler},
    ],
    exports: [
        LoginComponent
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
