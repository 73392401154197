import {BaseService, GET, Headers, Response} from 'ts-retrofit';
import {ACCEPT_JSON, goodResponseState, serviceFactory} from '../config';
import {LineColor} from '../../../models/line-color';
import {Mutex} from 'async-mutex';
import {TicketSearchOption} from '../../../models/tickets/provider/provider-ticket';


// TODO: per il momento recupero solo delle preferenze dei ticket
// e gli endpoin che avevo creato erano sotto /ticket
// Sarebbe necessario fare degli endpoint specifici

export class CachedPreferencesApiService {
    private api: PreferencesApiService = serviceFactory(PreferencesApiService);
    private allLineColors: Record<string, LineColor> = {};
    private allSearchOptions: TicketSearchOption[] = [];
    private mutex = new Mutex();

    async getAllTicketListLinesColors(): Promise<Response<LineColor[]>> {
        await this.reloadLineColors();
        return goodResponseState(Object.values(this.allLineColors));
    }

    async getAllTicketListSearchOptions(): Promise<Response<TicketSearchOption[]>> {
        await this.reloadSearchOptions();
        return goodResponseState(this.allSearchOptions);
    }

    async getLineColorByName(name: string): Promise<Response<LineColor>> {
        await this.reloadLineColors();
        return goodResponseState(this.allLineColors[name]);
    }

    clearCache() {
        this.allSearchOptions = [];
        this.allLineColors = {};
    }

    notifyDirtyCache() {
        // TODO: deve svuotare la cache anche di altri browser tramite SignalR
    }

    private async reloadSearchOptions(forceReload = false) {
        await this.mutex.runExclusive(async () => {
            if (forceReload || this.allSearchOptions.length === 0) {
                const response = await this.api.getAllTicketListSearchOptions();
                this.allSearchOptions = response.data;
            }
        });
    }

    private async reloadLineColors(forceReload = false) {
        await this.mutex.runExclusive(async () => {
            if (forceReload || Object.keys(this.allLineColors).length === 0) {
                const response = await this.api.getAllTicketListLinesColors();
                this.allLineColors = Object.fromEntries(response.data.map(l => [l.nome, {
                    ...l,
                    colTesto: l.colTesto ? `#${l.colTesto}` : null,
                    colSfondo: l.colSfondo ? `#${l.colSfondo}` : null,
                }]));
            }
        });
    }
}

class PreferencesApiService extends BaseService {

    @GET('/tickets/searchOptions')
    @Headers({...ACCEPT_JSON})
    async getAllTicketListSearchOptions(): Promise<Response<TicketSearchOption[]>> {
        return <Response> {};
    }

    @GET('/tickets/linesColors')
    @Headers({...ACCEPT_JSON})
    async getAllTicketListLinesColors(): Promise<Response<LineColor[]>> {
        return <Response> {};
    }

}
