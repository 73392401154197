import {TicketReplyMessageRequest} from './ticket-reply-message-request';

export class CreateTicketRequest extends TicketReplyMessageRequest {
    ticketGroupId: number | null;
    title: string;
    category: TicketCategory;
    typeId: number | null;
    contractId: number | null;
    clientId: number | null;
    /**
     * Se il ticket è un ticket interno a MFT
     */
    internalProvider: boolean;
    extraInfo: object | null;
}

export class MassiveCreateTicketsRequest {
    logins: string[];
    title: string;
    message: string;
}

export enum TicketCategory {
    Unknown = 0,
    Technical = 1,
    Administrative = 2,
    Commercial = 3,
}
