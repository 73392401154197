import {Component, Input} from '@angular/core';
import {FilterMatchMode, SelectItem} from 'primeng/api';

@Component({
    selector: 'app-table-string-filter',
    templateUrl: './string-filter.component.html',
    styleUrls: ['./string-filter.component.scss']
})
export class StringFilterComponent {

    @Input() field: string;

    @Input() placeholder = '';

    defaultMatch = FilterMatchMode.CONTAINS;

    @Input()
    customMatchModeOptions: SelectItem[];

    @Input() inputWidth = '200px';
}
