import {Stringifier} from '../services/stringifier.service';

@Stringifier
export class LoginRouter {
    loginName: string;
    loginPassword: string;
    routerAdminUsername: string;
    routerAdminPassword: string;
    ssidPassword: string;

    ssidSuffixName: string;
    ssid: string;
    voipCli: string;
    voipCliPassword: string;
    voipPortability: string;
    voipSipdomain: string;
    macAddress: string;

    dynamicDomain: string;
    ddnsUsername: string;
    ddnsPassword: string;

    // Per router Microtik
    isMicrotik: boolean;
    microtikSerial: string;

    static getStringifier(level: number) {
        if (level < 0) {
            return null;
        }
        return {
            loginName: null,
            loginPassword: null,
            routerAdminUsername: null,
            routerAdminPassword: null,
            ssidPassword: null,
            ssidSuffixName: null,
            ssid: null,
            voipCli: null,
            voipCliPassword: null,
            voipPortability: null,
            voipSipdomain: null,
            macAddress: null,
            dynamicDomain: null,
            ddnsUsername: null,
            ddnsPassword: null,
            isMicrotik: null,
            microtikSerial: null,
        } as LoginRouter;
    }
}
