import {Component, OnInit} from '@angular/core';
import {RestApiService} from '../../../services/api';
import {TypedTest} from '../../../models/test/typed-test';
import {ListedResponse} from '../../../models/hydratator/listed-response';
import {SignalRService} from '../../../services/signalr.service';
import {ToastService} from '../../../services/toast.service';
import {SessionStorageService} from '../../../services/session-storage.service';
import {StringifierService} from '../../../services/stringifier.service';
import {Contract} from '../../../models/contract';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {NotificationMessage} from '../../../models/notification-message';

@UntilDestroy({checkProperties: true})
@Component({
    selector: 'app-playground',
    templateUrl: './playground.component.html',
    styleUrls: ['./playground.component.scss']
})
export class PlaygroundComponent implements OnInit {

    typedTest: TypedTest = null;
    typedTestList: ListedResponse<TypedTest> = null;

    contractStrigifier: Contract;

    constructor(private api: RestApiService,
                private signalRService: SignalRService,
                private session: SessionStorageService,
                private stringifier: StringifierService,
                private toastService: ToastService) {
        signalRService.getMessage()
            .pipe(untilDestroyed(this))
            .subscribe(message => this.testSignalRReciveMessage(message));
        this.contractStrigifier = this.stringifier.getStringifier(Contract);
    }

    ngOnInit(): void {
    }

    showAlert() {
    }

    async loadTypedTest() {
        const response = await this.api.test.getTypedTest();
        this.typedTest = response.data;
        console.log({typedTest: this.typedTest});
    }

    async loadTypedTestList() {
        const response = await this.api.test.getTypedTestList();
        this.typedTestList = response.data;
        console.log({typedTest: this.typedTestList});
    }

    async testSignalRSendMessage() {
        // invio il messaggio tramite SignalR...
        this.signalRService.sendMessage('Invio alle ' + Date.now());

        // ma volendo potremmo inviarlo anche tramite classiche REST API
        // POST /api/Notifications --> vedere controller NotificationsController
    }

    async testSignalRReciveMessage(message: NotificationMessage) {
        // ricostruisco l'orario in questo modo per verificare se il sistema ricostruisce in automatico
        // i tipi durante la deserializzazione
        const orario = message.createdAt.getHours() + ':' + message.createdAt.getMinutes();
        this.toastService.info('Messaggio ricevuto da ' + message.senderUserId + ' alle ' + orario,
            message.message, 'center');
    }
}
