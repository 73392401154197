import {BaseService, GET, Headers, QueryMap, Response, ResponseTransformer} from 'ts-retrofit';
import {ACCEPT_JSON} from '../config';
import {ListedResponse} from '../../../models/hydratator/listed-response';
import {FilterDocumentRequest} from '../../../models/requests/filter-document-request';
import {Document} from '../../../models/document';

type DocumentBody = Exclude<Document, 'uploadDate'> & {uploadDate: string};

export class DocumentsService extends BaseService {

    @GET('/documents')
    @Headers({...ACCEPT_JSON})
    @ResponseTransformer((data: string) => {
        const documentBody = JSON.parse(data) as ListedResponse<DocumentBody>;
        return {
            count: documentBody.count,
            dataList: documentBody.dataList?.map((d: DocumentBody) => ({...d, uploadDate: new Date(d.uploadDate)} as Document)) ?? []
        } as ListedResponse<Document>;
    })
    async filter(@QueryMap request: FilterDocumentRequest): Promise<Response<ListedResponse<Document>>> {
        return <Response> {};
    }

}
