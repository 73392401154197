import {Component, Input, OnInit} from '@angular/core';
import {NotificationMessage} from '../../../models/notification-message';
import {DialogService} from 'primeng/dynamicdialog';
import {CalendarNotificationDialogComponent} from './calendar-dialog/calendar-notification-dialog.component';

@Component({
  selector: 'app-calendar-notification',
  templateUrl: './calendar-notification.component.html',
  styleUrls: ['./calendar-notification.component.scss']
})
export class CalendarNotificationComponent implements OnInit {

    @Input() notification: NotificationMessage;

  constructor(private dialog: DialogService) { }

  ngOnInit(): void {
  }

  openDetails() {
      CalendarNotificationDialogComponent.openDialog(
          this.dialog,
          this.notification
      );
  }

}
