import {Type} from 'class-transformer';
import {EasyCastReflectorDevice, IEasyCastReflectorDevice} from './easy-cast-reflector-device';

// tslint:disable-next-line:no-empty-interface
export interface IEasyCastLocation extends EasyCastLocation {
}

export class EasyCastLocation {
    locationId: number;
    name: string;

    @Type(() => EasyCastReflectorDevice)
    reflectorDevices: IEasyCastReflectorDevice[];
}
