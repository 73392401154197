import {ToastService} from '../toast.service';

declare global {
    interface Error {
        name: string;
        message: string;
        stack?: string;
    }
}

export interface ErrorMessage {
    message: string;
}

export class RestApiError extends Error {
    public code: number;
    public response: any;

    constructor(message: string, code: number = 0, response: any = null) {
        super(message);
        this.name = 'RestApiError';
        this.message = message;
        this.code = code;
        this.response = response;
    }

}

/**
 * Fa comparire un toast di errore con il messaggio restituito dalle API nel caso in cui l'errore sia una istanza
 * di RestApiError.
 * @param toastService
 * @param title
 * @param error
 * @param code
 * @return true se l'errore è stato gestito, false altrimenti
 */
export function toastRestApiError(toastService: ToastService, title: string, error: any, code?: number): boolean {
    if (error instanceof RestApiError) {
        if (!code || code === error.code) {
            toastService.error(title, error.message);
            return true;
        }
    }
    return false;
}
